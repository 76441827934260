<div [hidden]="inProgress">
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="externalLinks && externalLinks.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let externalUri of externalLinks" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId, 'externallink', externalUri.id]" [ngClass]="externalUri.id == buildingExternalLinkId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{externalUri.name}}">{{externalUri.name}}</a>
                </li>
              </ul>           
            </div>
              <div
                  *ngIf="externalLinks && externalLinks.length === 0"
                  class="potree_container">
                  <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                    'External Link' is available.</p>
                </div> 
          </div>
        </div>
        </div>
    </div>
    <!-- subheader ends  -->
  
  <div class="viewer-inner ml-0" *ngIf="!inProgress">
    <div *ngIf="!shouldOpenInNewTab">
      <div *ngIf="viewerUri">
        <iframe [src]="viewerUri" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
      </div>
    </div>
    <div *ngIf="shouldOpenInNewTab">
      <p class="font-weight-bolder mt-150 text-center text-white-50">This external link has been opened in other tab.&nbsp;<a href="{{rawUri}}" class="text-white" target="_blank">Click here to open it again.</a></p>
    </div>
  </div>
  </div>