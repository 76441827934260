import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'scriptjs';
import { BuildingPointCloudViewerDto } from '../../../shared/models/pointcloud/building-point-cloud-viewer-dto';

declare var SiteJS: any;

@Component({
  selector: 'app-pointcloud-viewer',
  templateUrl: './pointcloud-viewer.component.html',
  styleUrls: ['./pointcloud-viewer.component.scss', '../../../assets/libs/potree/potree.css'],
  encapsulation: ViewEncapsulation.None
})

export class PointcloudViewerComponent implements OnInit {
  private parameters: BuildingPointCloudViewerDto;

  constructor(private activatedRoute: ActivatedRoute) { 
    const parameters: BuildingPointCloudViewerDto = <BuildingPointCloudViewerDto>JSON.parse(atob(activatedRoute.snapshot.params['parameters']));

    this.parameters = parameters;
  }

  public ngOnInit() {
    get("./assets/libs/potree/potree.js", () => {
      SiteJS.loadOutDoorView(this.parameters.potreeEntryPointUri);
    });
  }
}