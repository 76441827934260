import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { ValidationService } from '../../service/validation.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../utils/LocalStroageService';
import { Router } from '@angular/router';
//import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ValidationService]
})

export class LoginComponent implements OnInit{
  lForm: UntypedFormGroup;
  registerLink: string;
  showMsg = false;
  errMsg = '';
  userLogin = true;
  loginInProgress = false;
  deviceInfo = null;
  showMsgBlock = false;
  forgotpasswordurl = window.location.origin + '/forgotPassword';
  blockusermsg = "The user account has been locked out. Please try again.";

  constructor(private authService: AuthService, 
    private loginService: LoginService,
    private validationService: ValidationService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private localStorageService:LocalStorageService) {
      this.lForm = this.fb.group({
        'username': [null, [<any>Validators.required],],
        'password': [null, [<any>Validators.required],],
      });
    }
 
  togglePassword(idEye : any, idPasswordField : any){
      var eye = document.querySelector(`#${idEye}`) as HTMLInputElement | null;
      if(eye){
        eye.classList.toggle('slash');
      }
  
      var password = document.querySelector(`#${idPasswordField}`) as HTMLInputElement | null;
      if(password){
        if(password.getAttribute('type') === 'password'){
          password.setAttribute('type', 'text')
        } else {
          password.setAttribute('type', 'password')
        }
      }
    }

  ngOnInit() {
    this.registerLink = environment.admin_url + '/account/register';
    this.authService.removeToken();
    this.resetFrom();
  }

  socialLogin(app : any) {
    const redirectUri: string = window.location.origin + '/assets/soicalmedia-callback.html';
    const url: string = environment.appBaseUrl + '/api/TokenAuth/ExternalAuthenticateProvider?provider=' + app
                        + '&returnUrl=' + redirectUri;
    this.openAuthPopup(url,() => {
      window['glogin'] = this;
    });
  }

  openAuthPopup(url : any,callback : any) {
    const y_axis = window.outerHeight / 2 + window.screenY - (600 / 2);
    const x_axis = window.outerWidth / 2 + window.screenX - (800 / 2);
    const router = this.router;
    let googleWin =  window.open(url, '_blank', 'location=0,status=0,width=600,height=600,top=' + y_axis + 'left' + x_axis);
    callback();
    let timer = setInterval(function() {
      if(googleWin!.closed) {
          clearInterval(timer);
          let user = window.localStorage.getItem('token');
          if (user) {
            router.navigate(['home']);
          }          
      }  
  }, 200); 
  }

  saveGoogleLogin(value : any){
    if(value){
      this.localStorageService.setItem('token',JSON.stringify(value));
    }
  }

  login(): void {
    this.loginInProgress = true;
    if (!this.lForm.valid) {
      this.validationService.validateAllFormFields(this.lForm);
    }
    if (this.lForm.valid) {
      const value = {
        'userNameOrEmailAddress': this.lForm.value.username,
        'password': this.lForm.value.password,
        'rememberClient': true,
      };
      this.loginService.loginUser(value).subscribe( {
        next: (response) => {
          this.authService.saveItemLocalStorage(response); 
          this.router.navigate(['home']);
        },
        error: (error) => {
          console.log(error);
          this.loginInProgress = false;
          if (error['status'] === 500) {
            if(error.error.error.details === this.blockusermsg){
              this.showMsgBlock = true;
            } else {
              if(error.error.error.details != null){
                this.errMsg = error.error.error.details;
              } else {
                this.errMsg = error.error.error.message;
              }
              this.showMsg = true;
            }
            setTimeout(() => {
              this.showMsg = false;
              this.showMsgBlock = false;
            }, 5000);
          }
        },
        complete: () => {
        }
      });
    }
  }

  resetFrom() {
    this.lForm.reset();
    this.showMsg = false;
  }
}
