import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProxyClientService } from "../proxies/proxy-client.service";
import { N } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CreateCalendarEventDto } from "../model/create-calendar-event-dto";
import { CreateCalendarEventGuestDto } from "../model/create-calendar-event-guest-dto";
import { CalendarEventList } from "../model/calendar-event-list";

@Injectable({
    providedIn: 'root'
})
export class CalendarEventService {
    
    private baseURL : string = '';
    private getOptions_ : any = {};
    private postOptions_ : any = {};

    constructor(
        private _httpClient: HttpClient, 
        private proxyClientService: ProxyClientService ) {

            this.postOptions_ = this.proxyClientService.postOptions_;
            this.getOptions_ = this.proxyClientService.getOptions_;
            this.baseURL = environment.appBaseUrl;
        }
    
        addCalendarEvent(createEventDto: CreateCalendarEventDto): Observable<any> {
            return this._httpClient.post(this.baseURL+"/api/services/app/CalendarEvent/Create", createEventDto, this.postOptions_);
        }

        addCalendarEventGuests(createCalendarEventGuestDto: CreateCalendarEventGuestDto): Observable<any> {
            return this._httpClient.post(this.baseURL+"/api/services/app/CalendarEventGuest/Create", createCalendarEventGuestDto, this.postOptions_);
        }

        getAllEvents(): Observable<HttpEvent<CalendarEventList>> {
            return this._httpClient.get<CalendarEventList>(this.baseURL+"/api/services/app/CalendarEvent/GetAll", this.getOptions_);
        }
}