import { AutodeskForgeViewerStatusDto } from "./autodesk-forge-viewer-status-dto";

export class BuildingForgeFileViewerDto {
    public fileName: string;
	public forgeStatus: AutodeskForgeViewerStatusDto;
	public forgeUrn: string;
	public forgeAccessToken: string;

	init(data?: any) {
        if (data) {
            this.fileName = data["fileName"];
            this.forgeStatus = data["forgeStatus"];
            this.forgeUrn = data["forgeUrn"];
			this.forgeAccessToken = data["forgeAccessToken"];
        }
    }

    fromJS(data: any): BuildingForgeFileViewerDto {
        data = typeof data === 'object' ? data : {};
        data = data.result;
        let result = new BuildingForgeFileViewerDto();
        result.init(data);
        return result;
    }
}