const colors = {
  white: '#FFF',
  blazeOrange: '#FF414D',
  mantis: '#69C779',
  alto: '#D8D8D8',
  dustyGray: '#979797',
  lightSkyBlue: '#87CEFA',
  black: '#040405'
};

export default colors;
