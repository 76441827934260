<div [hidden]="inProgress">
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
              <div class="subheader-instance" *ngIf="pointClouds && pointClouds.length > 0">
                <ul class="sub-drops list-style-none m-0 p-0">
                  <li *ngFor="let file of pointClouds" class="mr-3"><a
                      [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'outdoor-view', file.id]" [ngClass]="file.id == selectedPointCloudId ? 'viewer-active' : '' "
                      class="font-weight-500 slider-link text-ellipsis-subheader" title="{{file.fileName}}">{{file.fileName}}</a>
                  </li>
                </ul>           
              </div>
              <div
                  *ngIf="pointClouds && pointClouds.length === 0"
                  class="potree_container">
                  <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp; No instance of
                    'Point Cloud' is available.
                </div> 
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!inProgress" class="viewer-inner expended">
      <iframe [src]="viewerUri" *ngIf="viewerUri" class="w-100 h-100">
  
      </iframe>
    </div>
  </div>