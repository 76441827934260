import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'scriptjs';
import { AutodeskForgeViewerStatusDto } from '../../../shared/models/base/autodesk-forge-viewer-status-dto';
import { BuildingForgeFileViewerDto } from '../../../shared/models/base/building-forge-file-viewer-dto';

declare var SiteJS: any;

@Component({
  selector: 'app-forge-viewer',
  templateUrl: './forge-viewer.component.html',
  styleUrls: ['./forge-viewer.component.scss']
})

export class ForgeViewerComponent implements OnInit {

  public parameters: BuildingForgeFileViewerDto;

  pages: any = [];
  currentindex = 0;

  constructor(private activatedRoute: ActivatedRoute,
    private changeDetectorRefService: ChangeDetectorRef) { 
    const parameters: BuildingForgeFileViewerDto = <BuildingForgeFileViewerDto>JSON.parse(atob(activatedRoute.snapshot.params['parameters']));

    this.parameters = parameters;
  }

  public ngOnInit() {
    window['forge'] = this;

    get("https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js", () => {

      if(this.parameters && this.parameters.forgeStatus === AutodeskForgeViewerStatusDto.success) {
        if(window["Autodesk"]) {
          SiteJS.loadBIM360View(this.parameters.forgeAccessToken, this.parameters.forgeUrn, 'forge-renderer', this.arrayUpdate);
        }
      }
    });
  }

  public changePage(buttonIndex : any, index : any){
    if(buttonIndex == 0){
      this.currentindex --;
    }else if(buttonIndex == 1) {
      this.currentindex ++;
    }
  
    SiteJS.documentNextPage(this.currentindex);
  
  }

  private arrayUpdate(){
    if(window["geomertyItemsArray"] && window["geomertyItemsArray"].length > 1) {
      this.pages.length = window["geomertyItemsArray"].length;
      this.changeDetectorRefService.detectChanges();
    }
  }

}
