import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../service/common-data.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare var SiteJS: any;
declare var MainJS: any;

@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss']
})

export class LeftSideMenuComponent implements OnInit {

  modalRef: BsModalRef;
  isCollapsed = false;
  buildingActive = false;
  url: String;
  poiList = [];
  showPoiOption= false;
  showPoiName = false;
  disablepoi = true;
 
  constructor(
    public commonDataService: CommonDataService,
    ) { }

  ngOnInit() {
    // let buildingId = window.location.pathname.split('/');
    // this.commonDataService.getBuildingId(buildingId[1]);
    this.showPoiOption = window.location.pathname.includes("building-view");
    if(window.location.pathname.includes("building-view")){
      this.getListOfAvailablePopup();
    }

    window['poibutton'] = this;
    
  }

  


  getListOfAvailablePopup() {
    MainJS.closeDrawer();
    let value = {
      MaxResultCount: 100,
      InstanceFileId: null
    };  
    // if(this.commonDataService.toogleInstance){
      if(this.commonDataService.selectedFileInfo){
        value.InstanceFileId = this.commonDataService.selectedFileInfo['id'];
          this.poiList = SiteJS.getAllPois();
        this.showPoiName = true;
      }
    // }else {
    //   this.showPoiName = false;
    // }
  }

  viewInGoogleMap() {
    let lat = this.commonDataService.selectedBuildingInfo['lat'];
    let long = this.commonDataService.selectedBuildingInfo['long'];
    let url = "https://www.google.com/maps/@?api=1&map_action=map&center=" + lat +"," + long+ "&zoom=19&basemap=satellite&layer=transit" 
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  showAvailablePoiListPopup() {
    this.commonDataService.showPoiList = true;
  }
  
  movetopoi(poi : any) {
    SiteJS.movetopoi(poi.id);
    this.commonDataService.showMorePopup= true;
    SiteJS.openShowMore();
  }


  getCurrentUrl(){
    MainJS.closeDrawer();
    this.url = window.location.href;
  }
}
