import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../../service/login.service';
import { ValidationService } from '../../service/validation.service';

@Component({
  selector: 'app-sendingmailtoverify',
  templateUrl: './sendingmailtoverify.component.html',
  styleUrls: ['./sendingmailtoverify.component.scss'],
  providers: [ValidationService]
})

export class SendingmailtoverifyComponent implements OnInit {
  emailForm: UntypedFormGroup;
  disableSend = false;
  baseurl = window.location.origin + '/resetlink';
  message : any;
  showMessage = false;

  constructor(private loginService: LoginService, private fb: UntypedFormBuilder, private validationService: ValidationService) {
    this.emailForm = this.fb.group({
      'username': [null, [<any>Validators.required],]
    })
  }

  ngOnInit() { }

  submit() {
    this.disableSend = true;
    if (!this.emailForm.valid) {
      this.validationService.validateAllFormFields(this.emailForm);
      this.disableSend = false;
    }
    if (this.emailForm.valid) {
      const value = {
        username: this.emailForm.value.username,
        redirectUrl: this.baseurl
      }
      // this.disableSend = true;
      this.loginService.sendEmailConfirm(value).subscribe((response) => {
        response = response['body'];
        if (response['result']['result'] == true) {
          this.showMessage = true;
          this.disableSend = false;
          // this.disableSend = false;
          this.message = "The verification link has been sent to the registered email address";
          setTimeout(() => {
            this.showMessage = false;
          }
            , 4500);

        } else {
          this.showMessage = true;
          this.disableSend = false;
          // this.disableSend = false;
          this.message = "The verification link has been sent to the registered email address";
          setTimeout(() => {
            this.showMessage = false;
          }
            , 4500);
        }
      })
    }
    // this.disableSend = false;
  }
}
