import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../service/common-data.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../utils/LocalStroageService';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';

@Component({
  selector: 'app-asset-viewer',
  templateUrl: './asset-viewer.component.html',
  styleUrls: ['./asset-viewer.component.scss']
})

export class AssetViewerComponent implements OnInit {
  
  bsModalRef: BsModalRef;

  constructor(
    private activateRoute: ActivatedRoute,
    public commonDataService: CommonDataService,
    private modalService: BsModalService,
    private localStorageService:LocalStorageService,
    private router:Router,
    public _ViewerPermissionCheckerService:ViewerPermissionCheckerService,) { }

  ngOnInit() { }

}
