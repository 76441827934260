import { Injectable } from '@angular/core';
import { ProxyClientService } from './proxy-client.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class FloorPlanProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listBuildingFloorPlan(buildingId: number) {
      const path = `${this.proxyClientService.baseURL}/api/web/floorplan/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingFloorPlanPolygons(buildingFloorPlanId: number) {
    //var localAPIURL = 'http://localhost:5000';
    const path = `${this.proxyClientService.baseURL}/api/admin/floorplanpolygon/getPolygonsByFloorPlanId/${buildingFloorPlanId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingFloorPlanViewer(buildingFloorPlanId: number) {
    const path = `${this.proxyClientService.baseURL}/api/web/floorplan/viewer/${buildingFloorPlanId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
}