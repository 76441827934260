<ul class="sub-drops list-style-none m-0 p-0">
    <li *ngFor="let file of commonDataService.selectedViewerFiles | slice:0:commonDataService.totalSplicelength" class="mr-3"><a
        [routerLink]="['',commonDataService.selectedBuildingId,'viewer',viewerUrl,file['id']]" [ngClass]="file['id'] == fileIdfromUrl ? 'viewer-active' : '' "
        class="font-weight-500 slider-link text-ellipsis-subheader" (click)="refreshPage(file['id'])" title="{{file['name']}}">{{file['name']}}</a>
    </li>
  </ul>
  
  <div class="moreItemsDropdown"
  [hidden]="commonDataService.selectedViewerFiles && !(commonDataService.selectedViewerFiles.length > commonDataService.totalSplicelength)">
  <button (click)="openDropdown($event)" class="sub-btn"><i class="fa fa-caret-right"
      aria-hidden="true"></i></button>
  <ul class="list-style-none m-0 moreItemsList t2 scrollbar" id="poiscroll">
      <li [ngClass]="file['id'] == fileIdfromUrl ? 'viewer-active' : '' "
  *ngFor="let file of commonDataService.selectedViewerFiles | slice:commonDataService.totalSplicelength:commonDataService.selectedViewerFiles.length">
  <a [routerLink]="['',commonDataService.selectedBuildingId,'viewer',viewerUrl,file.id]"
    class="d-block font-weight-500 text-ellipsis-subheader" (click)="refreshPage(file['id'])" title="{{file['name']}}">{{file['name']}}</a></li>
  </ul>
  </div>  