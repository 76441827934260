import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'carbonchange-bos-app';
  env = environment;
  deviceInfo = null;

  constructor(private router:Router){ }

  ngOnInit(){ }

}
