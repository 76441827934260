import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../service/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatterportProxyService } from '../../proxies/matterport-proxy.service';
import { BuildingExternalUriListableDto } from '../../../shared/models/base/externalurl/building-external-url-listable-dto';

declare var MatterportJs : any;
declare var MainJS: any;

@Component({
  selector: 'app-matterport',
  templateUrl: './matterport.component.html',
  styleUrls: ['./matterport.component.scss']
})

export class MatterportComponent implements OnInit {
  public matterports: BuildingExternalUriListableDto[];
  public buildingMatterportId: number;
  public inProgress: boolean = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    public commonDataService: CommonDataService,
    private matterportProxyService: MatterportProxyService,
    private toastrService: ToastrService
  ) { }

  public ngOnInit() {
    this.inProgress = true;

    this.activateRoute.params.subscribe(routeParams => {

      this.inProgress = true

        this.matterportProxyService.listBuildingMatterport(Number.parseInt(this.commonDataService.selectedBuildingId!))
        .subscribe((response) => {
          response = response['body']['result'];
          this.matterports = response['items'];

          if(routeParams['file_id'])
          {
            this.loadViewer(+routeParams['file_id']);
          }
          else
          {
            if(response['items'].length > 0) {
              const first: BuildingExternalUriListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/matterport/" + first.id]);
            }
              this.inProgress = false;
          }
          
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId]);
        })
      // }
    });
  }

  private loadViewer(buildingMatterportId: number){

    this.buildingMatterportId = buildingMatterportId;

    this.matterportProxyService.getBuildingMatterportViewer(buildingMatterportId).subscribe((response) => {
        response = response['body']['result'];
        MatterportJs.getIframe();

        MatterportJs.showCaseLoader(response['externalUri'], null, response['modelId'], '3.2');

        this.inProgress = false;
      },
      error => {
        this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/matterport'
          ]);
        }
      }
    );
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }
}
