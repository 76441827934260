<div class="w-100 h-100" *ngIf="parameters && parameters.forgeStatus === 0">
    <div class="forge-file-error forge-file-status">
        <span>Forge failed to process the file.</span>
    </div>
</div>
<div class="w-100 h-100" *ngIf="parameters && parameters.forgeStatus === 1">
    <div class="forge-file-processing forge-file-status">
        <span>Forge is processing the file, please check back later.</span>
    </div>
</div>
<div class="w-100 h-100" id="forge-renderer" *ngIf="parameters && parameters.forgeStatus == 2">
</div>