import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProxyClientService } from "../proxies/proxy-client.service";
import { N } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BuildingService {
    
    constructor(
        private _HttpClient: HttpClient, 
        private proxyClientService: ProxyClientService ) { }


    
    getBuildingUsers(buildingId: number | null | undefined): Observable<any> {

        let path_ = "api/user/projectuser?";
        path_ += "Keyword=&";
        path_ += "ProjectId=" + encodeURIComponent("" + buildingId) + "&";
        path_ += "SkipCount=0&";
        path_ += "MaxResultCount=100&";
            
        path_ = path_.replace(/[?&]$/, "");


        return this._HttpClient.get(`${this.proxyClientService.baseURL}/${path_}`,
                                           this.proxyClientService.getOptions_);
    }
        
}