import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BuildingStatusProxy } from '../../proxies/building-status-proxy.service';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';

@Component({
  selector: 'app-generic-iframe',
  templateUrl: './generic-iframe.component.html',
  styleUrls: ['./generic-iframe.component.scss']
})

export class GenericIframeComponent implements OnInit {
  public uri: SafeResourceUrl;

  constructor(private activatedRoute: ActivatedRoute,
    private viewerPermissionService: ViewerPermissionCheckerService,
    private domSanitizer: DomSanitizer,
    private buildingStatusProxy: BuildingStatusProxy) { 
    activatedRoute.data.subscribe(result => {
      this.refreshUri();
    });
    viewerPermissionService.updateCallback.subscribe((context) => {
      this.refreshUri();
    });
  }

  private refreshUri() {
    const routeData = this.activatedRoute.snapshot.data;

    const categoryName: string = routeData['categoryName'];

    this.buildingStatusProxy.getCategory(categoryName).subscribe((result) => {
      if(result['body']['result']){
        result = result['body']['result'];
        if(result['placeholderLink']) {
          this.uri = this.domSanitizer.bypassSecurityTrustResourceUrl(result['placeholderLink']);
        }
      }
    });
  }

  ngOnInit(): void {
    this.refreshUri();
  }
}
