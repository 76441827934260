import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import AgoraAppBuilder from "@appbuilder/web";
import { AgoraAPICallService } from 'src/app/service/agora.apicall.service';

export interface DialogData {
  meetingId: number;
}

@Component({
  selector: 'app-dialog-join-meeting-room',
  templateUrl: './dialog-join-meeting-room.component.html',
  styleUrls: ['./dialog-join-meeting-room.component.scss']
})
export class DialogJoinMeetingRoomComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogJoinMeetingRoomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private agoraService: AgoraAPICallService, 
  ) {}


  ngOnInit(): void {

    this.agoraService.CreateJWTToken().subscribe(response => {
        if (response['status'] == 200) {
          AgoraAppBuilder.login(response['body'].token).then(() => {
      
            console.info("App Builder meeting joined");
            AgoraAppBuilder.joinRoom(
             this.data.meetingId,
              "User A"
           ).then(() => {
                console.info("App Builder meeting joined");
           })
         });
        }
    });

    
  }

}
