import { Component, OnInit } from "@angular/core";
import { CommonDataService } from "../../service/common-data.service";
import { ViewerPermissionCheckerService } from "../../service/viewer-permission-checker.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit{
  getVersion: string;

  constructor(
    public _ViewerPermissionCheckerService:ViewerPermissionCheckerService,
    public commonDataService: CommonDataService
  ) {}

  ngOnInit(){}

  refreshPage(){
    window.location.reload();
  }
}
