import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Injectable()
export class ValidationService {

  constructor() { }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  emailValid(control: UntypedFormControl): Promise<any> | Observable<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (!control.value.match(new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,15})$/))) {
        resolve({'emailValid': true});
      } else {
        resolve(null);
      }
    });
    return promise;
  }

  usernameValid(control: UntypedFormControl): Promise<any> | Observable<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (!control.value.match(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
        resolve({'usernameValid': true});
      } else {
        resolve(null);
      }
    });
    return promise;
  }

  firstNameValid(control: UntypedFormControl): Promise<any> | Observable<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (!control.value.match(new RegExp(/^[a-zA-Z|\d]{1,30}$/))) {
        resolve({'firstnameValid': true});
      } else {
        resolve(null);
      }
    });
    return promise;
  }


  phonenumberValid(control: UntypedFormControl): Promise<any> | Observable<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (control.value && !control.value.match(new RegExp(/^\d{7,15}$/))) {
        resolve({'phonenumberValid': true});
      } else {
        resolve(null);
      }
    });
    return promise;
  }

  passwordValid(control: UntypedFormControl): Promise<any> | Observable<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (!control.value.match(new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{5,20}$/))) {
        resolve({'passwordValid': true});
      } else {
        resolve(null);
      }
    });
    return promise;
  }
}
