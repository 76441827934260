<div>
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="generalViewers && generalViewers.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let externalUri of generalViewers" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'building-view', externalUri.id]" [ngClass]="externalUri.id == buildingGeneralViewerId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{externalUri.name}}">{{externalUri.name}}</a>
                </li>
              </ul>           
            </div>
              <div *ngIf="generalViewers && generalViewers.length === 0"
                class="potree_container">
                <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp; No instance of
                  'General Viewer' is available.</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="viewerUrl">
      <iframe id="navvis-iframe" [src]="viewerUrl" height="100%" width="100%" target="_parent" style="position: absolute;
        top: 117px;
        height: calc(100vh - 117px);
        left: 50px; z-index: 1; padding-right: 45px;"></iframe>
    </div>
  </div>