<div class="gray-container">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 bg-white mt-110 p-4 pt-5 pb-5">
          <form [formGroup]="passwordForm" (ngSubmit)="submit()">
            <div class="form-group  label-floated">
              <input type="password" formControlName="password" class="form-control app-form-control rounded-0">
              <label>New Password</label>
              <small class="d-block mt-1 text-danger text-left" *ngIf="passwordForm.controls['password'].hasError('required') && passwordForm.controls['password'].touched">
                Password is required
              </small>
            </div>
            <div class="form-group  label-floated">
  
              <input type="password" formControlName="confirmPassword" class="form-control app-form-control rounded-0">
              <label>New Confirm Password</label>
              <small class="d-block mt-1 text-danger text-left" *ngIf="passwordForm.controls['confirmPassword'].hasError('required') && passwordForm.controls['confirmPassword'].touched">
                Confirm Password is required
              </small>
              <small class="d-block mt-1 text-danger text-left" *ngIf="(passwordForm.value.confirmPassword != passwordForm.value.password) && passwordForm.controls['confirmPassword'].touched && passwordForm.controls['password'].touched">
                Password and Confirm Password Should be Same
              </small>
            </div>
            <button class="btn btn-primary btn-block btn-lg" [disabled]="!passwordForm.valid || (passwordForm.value.confirmPassword != passwordForm.value.password)"
              type="submit">Save</button>
          </form>
          <div class="alert alert-info d-block text-small w-100 mt-4 text-danger" *ngIf="showMessage">
            {{this.message}}
          </div>
        </div>
      </div>
    </div>
  </div>