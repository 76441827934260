import { NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TabsModule } from "ngx-bootstrap/tabs";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { FullCalendarModule } from '@fullcalendar/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginComponent } from './layout/login/login.component';
//import { LocalStorageModule } from "angular-2-local-storage";
import { AuthService, AuthInterceptor } from "./service/auth.service";
import { RouterModule } from '@angular/router';
// import { MaterialModule } from './utils/material.module';
import { TrimmerDirective } from "./directives/trimmer.directive";
import { AlertModule } from 'ngx-bootstrap/alert';
import { HeaderComponent } from './layout/header/header.component';
import { MomentPipe } from './pipes/moment/moment.pipe';
import { HumanFileSizePipe } from './pipes/human-filesize/human-filesize.pipe';
import { MainLayoutComponent } from './main-layout.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { ErrorPageComponent } from './layout/error-page/error-page.component';
import { SubHeaderComponent } from './layout/sub-header/sub-header.component';
import { ViewerComponent } from './layout/viewer/viewer.component';
import { View2dComponent } from './layout/view2d/view2d.component';
import { UploadModalComponent } from './layout/upload-modal/upload-modal.component';
import { TrackingComponent } from './layout/tracking/tracking.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SendingmailtoverifyComponent } from './layout/sendingmailtoverify/sendingmailtoverify.component';
import { PopupViewerComponent } from './layout/popup-viewer/popup-viewer.component';
import { PointcloudViewerComponent } from './layout/pointcloud-viewer/pointcloud-viewer.component';
import { OutdoorViewComponent } from './layout/outdoor-view/outdoor-view.component';
import { MatterportComponent } from './layout/matterport/matterport.component';
import { MailnotifyComponent } from './layout/mailnotify/mailnotify.component';
import { LeftSideMenuComponent } from './layout/left-side-menu/left-side-menu.component';
import { GenericIframeComponent } from './layout/generic-iframe/generic-iframe.component';
import { ForgeViewerComponent } from './layout/forge-viewer/forge-viewer.component';
import { ExternallinksComponent } from './layout/externallinks/externallinks.component';
import { DroneComponent } from './layout/drone/drone.component';
import { DocumentsComponent } from './layout/documents/documents.component';
import { BuildingViewComponent } from './layout/building-view/building-view.component';
import { Bim360ViewComponent } from './layout/bim360-view/bim360-view.component';
import { AssetViewerComponent } from './layout/asset-viewer/asset-viewer.component';
import { SensorIntegrationGraphComponent } from './layout/sensor-integration-graph/sensor-integration-graph.component';
import { sort } from './pipes/sort.pipe';
//import { GaugesModule } from '@biacsics/ng-canvas-gauges';
import { AreaChartComponent } from './layout/indoor-air-analysis/area-chart/area-chart.component';
import { ChartTabbingComponent } from './layout/indoor-air-analysis/area-chart-tabbing/area-chart-tab.component';
import { GaugeComponent } from './layout/indoor-air-analysis/gauge/gauge.component';
import { APICallService } from './service/apicall.service';
import { ProxyClientService } from './proxies/proxy-client.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RoomSpecificationsComponent } from './layout/room-specifications/room-specifications.component';
import { AlarmsComponent } from './layout/alarms/alarms..component';
import { CalendarComponent } from './layout/calendar/calendar.component';
import { VideoCallComponent } from './layout/video-call/video-call.component';
import { DialogCreateEventDialogComponent } from './layout/calendar/dialog-create-event-dialog/dialog-create-event-dialog.component';
import { MaterialModule } from './material-module';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgFor, AsyncPipe} from '@angular/common';
import { DialogJoinMeetingRoomComponent } from './layout/calendar/dialog-join-meeting-room/dialog-join-meeting-room.component';
// const lsConfig : any = {
//   prefix: "",
//   storageType: "localStorage"
// };



@NgModule({
  declarations: [
    sort,
    AppComponent,
    HeaderComponent,
    LoginComponent,
    TrimmerDirective,
    MomentPipe,
    HumanFileSizePipe,
    MainLayoutComponent,
    DashboardComponent,
    ErrorPageComponent,
    SubHeaderComponent,
    ViewerComponent,
    View2dComponent,
    UploadModalComponent,
    TrackingComponent,
    SidebarComponent,
    SendingmailtoverifyComponent,
    PopupViewerComponent,
    PointcloudViewerComponent,
    OutdoorViewComponent,
    MatterportComponent,
    MailnotifyComponent,
    LeftSideMenuComponent,
    GenericIframeComponent,
    ForgeViewerComponent,
    ExternallinksComponent,
    DroneComponent,
    DocumentsComponent,
    BuildingViewComponent,
    Bim360ViewComponent,
    AssetViewerComponent,
    SensorIntegrationGraphComponent,
    AreaChartComponent,
    ChartTabbingComponent,
    GaugeComponent,
    RoomSpecificationsComponent,
    AlarmsComponent,
    CalendarComponent,
    VideoCallComponent,
    DialogCreateEventDialogComponent,
    DialogJoinMeetingRoomComponent,
    
  ],
  imports: [
    BrowserModule,
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass :'toast-bottom-right'
    }),
    FormsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MaterialModule,
    //GaugesModule,
    NgChartsModule,
    NgxChartsModule,
    FullCalendarModule,
    NgxMatTimepickerModule,
    NgFor,
    AsyncPipe,
    //LocalStorageModule.forRoot(lsConfig)
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true
    // },
    HttpClientModule,
    AuthService,
    APICallService,
    ProxyClientService,
    BsModalService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule { }
