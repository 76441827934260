import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonDataService } from '../../service/common-data.service';
import { APICallService } from '../../service/apicall.service';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { ToastrService } from 'ngx-toastr';

declare var MainJS : any;

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [SubHeaderComponent]
})

export class TrackingComponent implements OnInit {
  file_id : any;
  url: SafeResourceUrl;
  fileName: string;
  filePreview: string;
  trackingSrc : any;
  fileContentType : any
  viewerLink : any;
  downloadLink : any;
  inProgress = false;
  category = 'Tracking';
  
  constructor(
    private router: Router,
    private domSanitizer: DomSanitizer,
    private activateRoute: ActivatedRoute,
    public commonDataService: CommonDataService,
    private apicallService: APICallService,
    private subheaderService: SubHeaderComponent,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.inProgress = true;
    this.trackingSrc = null;
    this.commonDataService.selectedViewerFiles = [];
    this.commonDataService.downloadLink = null;
    this.activateRoute.params.subscribe(routeParams => {
      this.file_id = this.activateRoute.snapshot.paramMap.get('file_id');
      // this.commonDataService.selectedBuildingId = this.activateRoute.snapshot.paramMap.get('building_id');
        this.apicallService.getfilesByCategory(this.commonDataService.selectedBuildingId,'Tracking').subscribe(
          (response) => {
          response = response['body'];
          this.commonDataService.selectedViewerFiles = response['result']['items'];
          if(this.commonDataService.selectedViewerFiles && this.commonDataService.selectedViewerFiles.length > 0){
            this.commonDataService.totalSplicelength = this.subheaderService.setSliceForFiles();
          }
          if(routeParams['file_id'])
          {
            this.loadExternallinkFile(routeParams['file_id']);
          }
          else
          {   
            if(this.commonDataService.selectedViewerFiles && this.commonDataService.selectedViewerFiles.length > 0)
              this.router.navigate([this.commonDataService.selectedBuildingId + "/tracking/" + this.commonDataService.selectedViewerFiles[0]['id']]);
          }
          this.inProgress = false;
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId])
        }
        );
    });
  }

  loadExternallinkFile(fileId : any){
    this.inProgress = true;
    this.apicallService.getFileInfo(fileId).subscribe((response) => { 
          response = response['body'];
          this.fileContentType  = response['result']["contentType"]
          this.commonDataService.selectedFileInfo= response['result'];
          this.commonDataService.selectedBuildingInfo = null;
          this.viewerLink  = response['result']["viewerUrl"]
          this.trackingSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.viewerLink);
        this.inProgress = false;
        MainJS.trackingErrorhandling(this.viewerLink);
    },(error)=>{
      this.inProgress = false;
    });
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }
}
