<div id="calendar-container" *ngIf="allEvents.length > 0">
    <full-calendar  [options]="calendarOptions"></full-calendar>   
</div>
<div id="glass"></div>
<div class="modal" id="event-modal">
    <div class="modal-title" id="modal-titlebar">
        <div class="modal-title-left" id="modal-titlebar-heading">
            {{ selectedEventTitle}}
            <div class="modal-subtitle">{{ selectedEventStartTime}} - {{selectedEventEndTime}}</div>
        </div>
        <div class="modal-title-right">
            <button class="modal-toolbar"><i class="fa-regular fa-pen-to-square"></i></button>
            <button class="modal-toolbar"><i class="fa-regular fa-trash-can"></i></button>
            <button class="modal-toolbar"><i class="fa-solid fa-ellipsis-vertical"></i></button>
        </div>
    </div>
    <div class="event-meet">
        <div class="event-meet-left">
            <button class="bos-meet-button" (click)="openJoinMeetingRoomDialog()" id="meet-btn">Join with BOS Meet</button>
        </div>
        <div class="event-meet-right">
            <button class="modal-toolbar"><img src="../../../assets/images/ar-icon.png" class="ar-icon"></button>
            <button class="modal-toolbar"><i class="fa-solid fa-vr-cardboard"></i></button>
            <button class="modal-toolbar"><i class="fa-regular fa-copy"></i></button>
        </div>
    </div>
    <div class="event-guests">
        <div class="event-guests-left">
            <button class="modal-toolbar"><i class="fa-solid fa-user-group"></i></button>
            <span class="guests-total">4 Guests</span>
            <span class="guests-attending">2 Attending</span>
            <span class="guests-waiting">2 Waiting</span>
        </div>
        <div class="event-guests-right">
            <button class="modal-toolbar"><i class="fa-regular fa-message"></i></button>
            <button class="modal-toolbar"><i class="fa-regular fa-envelope"></i></button>
        </div>
    </div>
    <div class="event-guests-profiles">
        <div class="guest-profile" *ngFor="let user of selectedEventUsers">
            <button class="modal-toolbar guest-profile-img" style="background-color: {{user.profileColor}}"><i class="fa-solid fa-user"></i></button>
            <button class="guest-status" *ngIf="user.isOrganizer"><i class="fa-solid fa-check"></i></button>
            <span class="guests-total organizer">{{user.name}}</span>
            <span class="guests-attending" *ngIf="user.isOrganizer">Organizer</span>
        </div>
    </div>
    <div class="event-notification">
        <div class="event-guests-left">
            <button class="modal-toolbar"><i class="fa-regular fa-bell"></i></button>
            <span class="guests-total">10 minutes before</span>
        </div>
        <div class="event-guests-left" *ngIf="currentCalendarOwner">
            <button class="modal-toolbar"><i class="fa-regular fa-calendar"></i></button>
            <span class="guests-total">{{currentCalendarOwner.name}}</span>
        </div>
    </div>
    <div class="event-bottom-navigation">
        <span class="guests-total">Going?</span>
        <button class="meeting-status-button">Yes</button>
        <button class="meeting-status-button neutral">No</button>
        <button class="meeting-status-button neutral">Maybe</button>
    </div>
   <span (click)="closeEventModal()" class="modal-close">&times;</span>
</div>