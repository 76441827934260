import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Optional,
  Renderer2
} from "@angular/core";
import {
  COMPOSITION_BUFFER_MODE,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

@Directive({
  selector: "input[trim], textarea[trim]",
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TrimmerDirective, multi: true }]
})
export class TrimmerDirective implements ControlValueAccessor {

  @Input()
  set type(value: string) {
    this._type = value || "";
  }
  // Get a value of the trim attribute if it was set.
  @Input() trim: string;

  /**
   * Keep the type of input element in a cache.
   *
   * @type {string}
   * @private
   */
  private _type: string = "";

  /**
   * Keep the value of input element in a cache.
   *
   * @type {string}
   * @private
   */
  private _value: string;

  // Source services to modify elements.
  private _sourceRenderer: Renderer2;
  private _sourceElementRef: ElementRef;
  
  /**
   * Updates the value on the blur event.
   */
  @HostListener("blur", ["$event.type", "$event.target.value"])
  onBlur(event: string, value: string): void {
    this.updateValue(event, value.trim());
    this.onTouched();
  }

  /**
   * Updates the value on the input event.
   */
  @HostListener("input", ["$event.type", "$event.target.value"])
  onInput(event: string, value: string): void {
    this.updateValue(event, value);
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(
    @Inject(Renderer2) renderer: Renderer2,
    @Inject(ElementRef) elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) compositionMode: boolean
  ) {
    this._sourceRenderer = renderer;
    this._sourceElementRef = elementRef;
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }

  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  /**
   * Writes a new value to the element based on the type of input element.
   *
   * @param {any} value - new value
   */
  public writeValue(value: any): void {
    //
    this._value = value === "" ? "" : value || null;

    this._sourceRenderer.setProperty(this._sourceElementRef.nativeElement, "value", this._value);

    if (this._type !== "text") {
      this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, "value", this._value);
    }
  }
  
  /**
   * Writes the cursor position in safari
   *
   * @param cursorPosition - the cursor current position
   * @param hasTypedSymbol
   */
  private setCursorPointer(cursorPosition: any, hasTypedSymbol: boolean): void {
    // move the cursor to the stored position (Safari usually moves the cursor to the end)
    if (hasTypedSymbol) {
      if (["text", "search", "url", "tel", "password"].indexOf(this._type) >= 0) {
        this._sourceElementRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  }

  /**
   * Trims an input value, and sets it to the model and element.
   *
   * @param {string} value - input value
   * @param {string} event - input event
   */
  private updateValue(event: string, value: string): void {
    value = this.trim !== "" && event !== this.trim ? value : value.trim();
    const previous = this._value;
  
    // store the cursor position
    const cursorPosition = this._sourceElementRef.nativeElement.selectionStart;
    
    // write value to the element.
    this.writeValue(value);
    
    if ((this._value || previous) && this._value.trim() !== previous) {
      this.onChange(this._value);
    }
    // check that non-null value is being changed
    var hasTypedSymbol;
    if(value && previous && value !== previous){
      hasTypedSymbol = true;
    }else{
      hasTypedSymbol = false;
    }
     
    
    // write the cursor position
    this.setCursorPointer(cursorPosition, hasTypedSymbol);
  }
}