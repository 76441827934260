import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})

export class BuildingStatusProxy {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public getBuildingStatus(buildingId: number) {
    return this.client.get(`${this.proxyClientService.baseURL}/api/web/status/building/${buildingId}`, this.proxyClientService.getOptions_);
  }

  public getCategory(categoryName: string) {
    return this.client.get(`${this.proxyClientService.baseURL}/api/web/status/category/${categoryName}`, this.proxyClientService.getOptions_);
  }
  
}