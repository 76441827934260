import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonDataService } from "../../service/common-data.service";
import { ToastrService } from "ngx-toastr";
import { PointCloudProxyService } from "../../proxies/pointcloud-proxy.service";
import { BuildingSingleFileWebListableDto } from "../../../shared/models/base/building-single-file-web-listable-dto";
import { DomSanitizer } from '@angular/platform-browser';

declare var MainJS: any;

@Component({
  selector: 'app-outdoor-view',
  templateUrl: './outdoor-view.component.html',
  styleUrls: ['./outdoor-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OutdoorViewComponent implements OnInit {

  public pointClouds: BuildingSingleFileWebListableDto[];
  public selectedPointCloudId: number;
  public inProgress: boolean = true;
  public viewerUri: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pointCloudProxyService: PointCloudProxyService,
    public commonDataService: CommonDataService,
    private domSanitizer: DomSanitizer,
    private toastrService: ToastrService
  ) {
  }

  public ngOnInit() {
    this.inProgress = true;

      this.activatedRoute.params.subscribe(routeParams => {

          this.pointCloudProxyService.listBuildingPointCloud(Number.parseInt(this.commonDataService.selectedBuildingId!))
          .subscribe((response) => {
            response = response['body']['result'];
            this.pointClouds = response['items'];
  
            if(routeParams['file_id'])
            {
              const buildingPointCloudId: number = +routeParams['file_id'];
  
              this.loadOutDoorView(buildingPointCloudId);
            }
            else
            {   
              if(response['items'].length > 0) {
                const first: BuildingSingleFileWebListableDto = response['items'][0];
                this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/outdoor-view/" + first.id]);
              }
            }
            this.inProgress = false;
          },
          (error)=>{
            this.inProgress = false;
            this.toastrService.error(error.error.error.message);
            // redirecting to default page in case any exception like (permission denied or invalid access) occurred
            this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId]);
          })
        // }
      });
  }
  
  private loadOutDoorView(buildingPointCloudId: number): void {
    this.selectedPointCloudId = buildingPointCloudId;
    this.pointCloudProxyService.getBuildingPointCloudViewer(buildingPointCloudId).subscribe((response) => {
        response = response['body']['result'];
        const paramaters: string = btoa(JSON.stringify(response));

        const uri: string = `./pointcloud-viewer/${paramaters}`;

        this.viewerUri = null;
        this.viewerUri = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
      },
      error => {
        this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/outdoor-view"]);
      }
    );
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }
}
