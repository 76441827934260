<h2 mat-dialog-title class="dialog-title">
  Create Event
  <button class="dialog-close-icon" mat-icon-button (click)="close()">
    <mat-icon mat-list-icon>close</mat-icon>
</button> 
</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="input-field-full-width">
    <mat-label>Event title</mat-label>
    <input matInput [(ngModel)]="createEventDto.title">
  </mat-form-field>

  <mat-form-field class="input-field-half-width">
    <mat-label>Choose a start date</mat-label>
    <input matInput datePickerFormat="YYYY-MM-DD" [(ngModel)]="start_date" [matDatepicker]="startpicker">
    <mat-hint>YYYY-MM-DD</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="startpicker"></mat-datepicker-toggle>
    <mat-datepicker #startpicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="input-field-half-width">
    <input matInput
          [(ngModel)]="start_time"
          [ngxMatTimepicker]="starttimepicker"
          placeholder="4:00 PM"
          [format]="24" />
  </mat-form-field>
  <ngx-mat-timepicker #starttimepicker dottedMinutesInGap enableKeyboardInput></ngx-mat-timepicker>

  <mat-form-field class="input-field-half-width">
    <mat-label>Choose a end date</mat-label>
    <input matInput datePickerFormat="YYYY-MM-DD" [matDatepicker]="endpicker"  [(ngModel)]="end_date">
    <mat-hint>YYYY-MM-DD</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="endpicker"></mat-datepicker-toggle>
    <mat-datepicker #endpicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="input-field-half-width">
    <input matInput
           [(ngModel)]="end_time"
           [ngxMatTimepicker]="endtimepicker"
           placeholder="4:00 PM"
           [format]="24" />
  </mat-form-field>
  <ngx-mat-timepicker #endtimepicker dottedMinutesInGap enableKeyboardInput></ngx-mat-timepicker>

  <form>
    <mat-form-field class="example-chip-list">
      <mat-label>Add Guests</mat-label>
      <mat-chip-grid #chipGrid aria-label="Guest selection">
        <mat-chip-row *ngFor="let guest of guests" (removed)="remove(guest)">
          {{guest}}
          <button matChipRemove [attr.aria-label]="'remove ' + guest">
            <mat-icon mat-list-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="New Guest..." #guestInput [formControl]="guestCtrl"
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let guest of filteredGuests | async" [value]="guest">
          {{guest}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  
  <mat-form-field class="example-full-width">
    <textarea matInput [(ngModel)]="createEventDto.description" placeholder="Leave a description"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="createEvent()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
  