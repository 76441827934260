import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDialog } from '@angular/material/dialog';
import { DialogCreateEventDialogComponent } from './dialog-create-event-dialog/dialog-create-event-dialog.component';
import { CommonDataService } from 'src/app/service/common-data.service';
import { CalendarEventService } from 'src/app/service/calendarevent-service';
import { HttpEventType } from '@angular/common/http';
import { CalendarEventList } from 'src/app/model/calendar-event-list';
import { CalendarEventDto } from 'src/app/model/calendar-event-dto';
import { CalendarEvent } from 'src/app/model/calendar-events';
import * as moment from 'moment';
import { CalendarEventGuest } from 'src/app/model/calendar-event-guest';
import { DialogJoinMeetingRoomComponent } from './dialog-join-meeting-room/dialog-join-meeting-room.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  allEvents: any[] = []; 

  selectedEventTitle: string;
  selectedEventStartTime: string;
  selectedEventEndTime: string;
  selectedMeetingId: string;
  selectedEventUsers: CalendarEventGuest[] = [];
  currentCalendarOwner: any = null;

  constructor(public dialog: MatDialog, 
    public commonDataService: CommonDataService,
    private calendarEventService: CalendarEventService) {}

  public openCreateEventDialog(): void {
    const dialogRef = this.dialog.open(DialogCreateEventDialogComponent, {
      
      data: {buildingId: this.commonDataService.selectedBuildingId},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reload();
    });
  }

  public openJoinMeetingRoomDialog(): void {
    this.closeEventModal();
    const dialogRef = this.dialog.open(DialogJoinMeetingRoomComponent, {
      height: '800px',
      width: '900px',
      data: {meetingId: this.selectedMeetingId},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.info("Closed meeting room dialog");
    });
  }

  private reload() {
      this.calendarEventService.getAllEvents().subscribe({
        next: (response) => {
          const result = response['body'];
          const events: CalendarEventList = result['result'] as CalendarEventList;
          events.items.forEach((item: CalendarEventDto) => {
            const calendarEvent: CalendarEvent = {
              title: item.title, 
              start: moment(item.startDate).format("YYYY-MM-DD"), 
              end: item.endDate,
              color: this.eventColors.find(x => x.type === 'full_day')?.colorCode, 
              textColor: this.eventColors.find(x => x.type === 'full_day')?.textColor,
              id: item.id,
              meetingId: item.meetLink,
              eventUsers: item.guests
            };
            
            this.allEvents.push(calendarEvent);
          });
          this.loadCalender();
        },
        error: (error) => {
        },
        complete: () => { }
      });
  }

  public setEventElements(){
    var eventMain = document.getElementsByClassName('fc-event-main');
    for(let i=0; i<eventMain.length; i++){
      var temp1 = eventMain[i] as HTMLElement;
      var color = temp1.style.color;
      var eventMeter = document.createElement('span') as HTMLElement;
      eventMeter.classList.add('event-meter');
      eventMeter.style.backgroundColor = color;
      temp1.appendChild(eventMeter);
    }
  }

  eventColors = [
    {type: 'creative', colorCode: 'rgba(234, 58, 61, 0.2)', textColor: '#EA3A3D'},
    {type: 'full_day', colorCode: 'rgba(219, 90, 238, 0.2)', textColor: '#DB5AEE'},
    {type: 'design', colorCode: 'rgba(26, 213, 152, 0.2)', textColor: '#1AD598'},
    {type: 'biz', colorCode: 'rgba(58, 54, 219, 0.2)', textColor: '#726FF5'},
    {type: 'comm', colorCode: 'rgba(249, 185, 89, 0.2)', textColor: '#F9B959'},
    {type: 'meet', colorCode: 'rgba(0, 144, 255, 0.2)', textColor: '#0090FF'}
  ];

  users = [
    {profileColor: '#fdd447', id: 1, name: 'Juan Massey', status: true, isOrganizer: true},
    {profileColor: '#bd89ad', id: 2, name: 'Timothy Tran', status: true, isOrganizer: false},
    {profileColor: '#9bd7ad', id: 3, name: 'Vicky Luna', status: false, isOrganizer: false},
    {profileColor: '#c5b1a3', id: 4, name: 'Ralph Jones', status: false, isOrganizer: false}
  ];

  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
/*
  allEvents = [
    { 
        title: 'All Day Event', 
        start: '2024-03-01', 
        color: this.eventColors.find(x => x.type === 'full_day')?.colorCode, 
        textColor: this.eventColors.find(x => x.type === 'full_day')?.textColor,
        id: '1',
        eventUsers: [1, 2]
    },
    { 
      title: 'Design Meeting', 
      start: '2024-03-02', 
      color: this.eventColors.find(x => x.type === 'design')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'design')?.textColor,
      id: '2',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Communication Workshop', 
      start: '2024-03-04', 
      color: this.eventColors.find(x => x.type === 'comm')?.colorCode, 
      textColor: this.eventColors.find(x => x.type === 'comm')?.textColor,
      id: '3',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Business Event', 
      start: '2024-03-01', 
      color: this.eventColors.find(x => x.type === 'biz')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'biz')?.textColor,
      id: '4',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Marketing Agenda', 
      start: '2024-03-10', 
      color: this.eventColors.find(x => x.type === 'design')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'design')?.textColor,
      id: '5',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Marketing Speech', 
      start: '2024-03-13', 
      color: this.eventColors.find(x => x.type === 'meet')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'meet')?.textColor,
      id: '6',
      eventUsers: [1, 2, 3, 4] 
    },
    { 
      title: 'Creative Lesson', 
      start: '2024-03-16', 
      color: this.eventColors.find(x => x.type === 'creative')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'creative')?.textColor,
      id: '7',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Business Calls', 
      start: '2024-03-23', 
      color: this.eventColors.find(x => x.type === 'biz')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'biz')?.textColor,
      id: '8',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Creativity Workshop', 
      start: '2024-03-22', 
      color: this.eventColors.find(x => x.type === 'comm')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'comm')?.textColor,
      id: '9',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'Morning Routine', 
      start: '2024-03-25', 
      color: this.eventColors.find(x => x.type === 'full_day')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'full_day')?.textColor,
      id: '10',
      eventUsers: [1, 2, 3, 4]
    },
    { 
      title: 'BOS Meeting', 
      start: '2024-03-25', 
      color: this.eventColors.find(x => x.type === 'meet')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'meet')?.textColor,
      id: '11',
      eventUsers: [1, 2, 3, 4] 
    },
    { 
      title: 'Dev Event', 
      start: '2024-03-27', 
      color: this.eventColors.find(x => x.type === 'full_day')?.colorCode,
      textColor: this.eventColors.find(x => x.type === 'full_day')?.textColor,
      id: '12',
      eventUsers: [1, 2, 3, 4] 
    }
  ];
*/
  calendarOptions: CalendarOptions;

  datesSetHandler(dateInfo : any) {
    // var view = dateInfo.view;
    // var viewType = view.type;
    setTimeout(this.setEventElements, 200);
  }

  public ngOnInit() {
    this.reload();  
  }

  public getCustomTime(date: Date){
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var min = minutes < 10 ? '0'+minutes : minutes;
    var strTimeStart = hours + ':' + min + ' ' + ampm;
    return strTimeStart;
  }

  public closeEventModal(){
    var modelEl = document.getElementById('event-modal') as HTMLElement;
    var glassEl = document.getElementById('glass') as HTMLElement;
    modelEl.style.display = 'none';
    glassEl.style.display = 'none';
    this.selectedEventUsers = [];
  }

  public openEventModal(bgClr: any, textColor: any){
    var modelEl = document.getElementById('event-modal') as HTMLElement;
    var glassEl = document.getElementById('glass') as HTMLElement;
    modelEl.style.display = 'flex';
    glassEl.style.display = 'block';
    var modalTitleBar = document.getElementById('modal-titlebar') as HTMLElement;
    modalTitleBar.style.backgroundColor = bgClr;
    var modalTitleBarHeading = document.getElementById('modal-titlebar-heading') as HTMLElement;
    modalTitleBarHeading.style.color = textColor;
  }

  public eventView(arg: any){
    var bgClr = arg.el.style.backgroundColor;
    
    var usersEvent = arg.event._def.extendedProps.eventUsers;
    for(let i=0; i < usersEvent.length; i++){
      this.selectedEventUsers.push(usersEvent[i]);
    }
    this.currentCalendarOwner = this.selectedEventUsers.find(x => x.isOrganizer == true);

    var id = arg.event._def.publicId;
    var textColor = this.allEvents.find(x => x.id === id)?.textColor;

    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', time: 'hh:mm AM' };

    this.selectedEventTitle = arg.event._def.title;
    this.selectedEventStartTime = arg.event._instance.range.start.toLocaleDateString("en-US", options) + ' ' +this.getCustomTime(arg.event._instance.range.start);
    this.selectedEventEndTime = arg.event._instance.range.end.toLocaleDateString("en-US", options) + ' ' +this.getCustomTime(arg.event._instance.range.end);
    this.selectedMeetingId =  arg.event._def.extendedProps.meetingId;
    this.openEventModal(bgClr, textColor);
  }

  startMeeting(meetingId: string) {
    console.info(meetingId);
  }

  private loadCalender() {

    var context = this;
    this.calendarOptions = {
      customButtons: {
        addEventButton: {
          click: function(arg : any) {
            context.openCreateEventDialog();
          },
          //icon : "fa fa fa-plus",
          text: ' Create an Event'
        }
      },
      headerToolbar: {
        start: 'dayGridMonth,timeGridWeek,timeGridDay,today',
        center: 'addEventButton',
        end: 'prevYear,prev,title,next,nextYear'
      },
      plugins: context.calendarPlugins,
      initialView: 'dayGridMonth',
      weekends: true,
      dayHeaderFormat: {
        weekday: 'long'
      },
      datesSet(dateInfo: any) {
        context.datesSetHandler(dateInfo);
      },
      events: context.allEvents,
      eventClick: context.eventView.bind(context)
    };
  }
}
