import { Injectable } from '@angular/core';
import { LocalStorageService } from '../utils/LocalStroageService';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
import { environment } from '../../environments/environment';

declare var MainJS : any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private localStorageService: LocalStorageService
    ) { }

  saveItemLocalStorage(obj : any) {
    this.localStorageService.setItem('token', JSON.stringify(obj.result.accessToken));
  }

  isLoggedIn(): boolean {
    const token = window.localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  getToken(): any {
    const token = window.localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      return;
    }
  }

  public setToken(token: any) {
    window.localStorage.setItem('token', token);
  }

  removeToken(){
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('bimAuthToken');
    this.localStorageService.removeItem('projectUsersPermission');
  }
}


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor (public auth: AuthService, 
    private localStorageService: LocalStorageService
    ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    MainJS.showSpinner();

    let token: string = this.localStorageService.getItem('token');
    let req = {};
    let isExternalUser = false;
    isExternalUser = request.url.includes('https://storage.googleapis.com');//by passing google apis  
    if (isExternalUser) {
      req = { url: request.url };
    }
    else {
      req = { url: environment.base_url + request.url }
      if (token) {
        token = token.replace(/['"]+/g, '');
        const headers = new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        });
        req = { url: request.url, headers : headers};
      }
    }
    request = request.clone(req);
    return next.handle(request).pipe(
      finalize(() => {
        // console.log('request completed');
        this.decreaseRequests();
      })
    );
  }
  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0 ) {
      MainJS.hideSpinner();
    }
  }
}
