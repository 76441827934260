import { Component, OnInit } from '@angular/core';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../utils/LocalStroageService';
import { CommonDataService } from '../../service/common-data.service';
import { APICallService } from '../../service/apicall.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})

export class ViewerComponent implements OnInit {

  constructor(
    private activateRoute: ActivatedRoute,
    public commonDataService: CommonDataService,
    private apicallService: APICallService,
    private toastrService: ToastrService,
    private localStorageService:LocalStorageService,
    private router:Router,
    public _ViewerPermissionCheckerService:ViewerPermissionCheckerService) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(routeParams => {
      //console.log('routeParams-Viewer-', routeParams)
      if (this.activateRoute.snapshot.paramMap.get('building_id')) 
      {
        //console.log("ngafterviewinit-if-Viewer-", this.activateRoute.snapshot.paramMap.get('building_id'))
        this.getInfoOfBuilding(this.activateRoute.snapshot.paramMap.get('building_id'));
        this.commonDataService.selectedBuildingId = this.activateRoute.snapshot.paramMap.get('building_id');
        //Setting permissions in local storage if not set-starts
        
        const buildingId: number = +this.activateRoute.snapshot.paramMap.get('building_id')!;
        this._ViewerPermissionCheckerService.updateBuildingStatus(buildingId);

        //ends
        //Setting buildings in local storage if not set-starts
        if(!this.localStorageService.getItem('buildings'))
        {
          this.getAllBuildings();
        }
        else
        {
          this.commonDataService.availableBuildings = this.localStorageService.getItem('buildings');
        }
        //ends
      }
    });
  }

  getInfoOfBuilding(buildingId : any){
    //console.log(buildingId);
    this.apicallService.getParticularBuilding(buildingId).subscribe((response) => {
      response = response['body'];
      this.commonDataService.selectedBuildingInfo = response['result'];
      this.commonDataService.selectedProject = response['result'];
      //MainJS.openDrawer();
      }, (error) => {
        console.log(error.error.error.message);
      this.toastrService.error(error.error.error.message);
      this.router.navigate(["/"])
      throw error;
    });
  }

  getAllBuildings() {
    this.apicallService.getBuildingInfo().subscribe((response) => {
      response = response['body'];
      //collect lat and long of all buildings
      this.commonDataService.allBuildingInfo = response['result']['items'];
      this.commonDataService.availableBuildings = response['result']['items'];
      this.localStorageService.setItem('buildings', response['result']['items'])

    });
  }

}
