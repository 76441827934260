import { Component, OnInit } from '@angular/core';
import { CommonDataService } from "../../service/common-data.service";
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GeneralViewerProxyService } from '../../proxies/generalviewer-proxy.service';
import { BuildingExternalUriListableDto } from '../../../shared/models/base/externalurl/building-external-url-listable-dto';

declare var MainJS: any;
declare var SiteJS: any;

@Component({
  selector: 'app-building-view',
  templateUrl: './building-view.component.html',
  styleUrls: ['./building-view.component.scss']
})

export class BuildingViewComponent implements OnInit {

    public generalViewers: BuildingExternalUriListableDto[];
    public buildingGeneralViewerId: number;
    public viewerUrl: SafeResourceUrl;
    public inProgress: boolean = true;

    constructor(
      private router: Router,
      private domSanitizer: DomSanitizer,
      public commonDataService: CommonDataService,
      private activateRoute: ActivatedRoute,
      private generalViewerProxyService: GeneralViewerProxyService,
      private toastrService: ToastrService
    ) {
      this.domSanitizer.bypassSecurityTrustResourceUrl
    }

    public ngOnInit() {
      this.inProgress = true;

      this.activateRoute.params.subscribe(routeParams => {

        this.generalViewerProxyService.listBuildingGeneralViewer(Number.parseInt(this.commonDataService.selectedBuildingId!))
        .subscribe((response) => {
          response = response['body']['result'];
          this.generalViewers = response['items'];
          
          if (routeParams['file_id']) {
            this.loadViewer(+routeParams['file_id']);
          }
          else {
            if (response['items'].length > 0)
            {
              const first: BuildingExternalUriListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/building-view/" + first.id]);
            }
          }

          this.inProgress = false;
        },
          (error) => {
            this.inProgress = false;
            this.toastrService.error(error.error.error.message);
            // redirecting to default page in case any exception like (permission denied or invalid access) occurred
            this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId]);
          })
        // }
      });
    }


  async loadViewer(buildingGeneralViewerId: number){

    this.buildingGeneralViewerId = buildingGeneralViewerId;

    this.generalViewerProxyService.getBuildingGeneralViewerViewer(buildingGeneralViewerId).subscribe((response) => {
        response = response['body']['result'];
        this.viewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(response['externalUri']);

        this.inProgress = false;
      },
      error => {
        this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/building-view'
          ]);
        }
      }
    );
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }

}
