import { Component} from '@angular/core';
import { CommonDataService } from "../../service/common-data.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FloorPlanProxyService } from '../../proxies/floorplan-proxy.service';
import { BuildingSingleFileWebListableDto } from '../../../shared/models/base/building-single-file-web-listable-dto';
import { BuildingForgeFileViewerDto } from '../../../shared/models/base/building-forge-file-viewer-dto';
import { BuildingSingleFileViewerDto } from '../../../shared/models/base/singlefile/building-singlefile-viewer-dto';
import * as d3 from 'd3';
import { RoomProxyService } from 'src/app/proxies/room-proxy.service';
import { RoomDto } from 'src/shared/models/RoomDto';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent {
  public floorPlans: BuildingSingleFileWebListableDto[];
  public buildingFloorPlanId: number;
  public loading: boolean = true
  public inProgress: boolean = true;
  public forgeUri: any;
  public rawViewerUri: any;
  public imageViewerUri: any;
  public googleViewerUrl: any;

  public imageFilePath: string = '';
  public svgWidth = 1500;
  public svgHeight = 700;

  public imageWidth = 1500;
  public imageHeight = 1000;

  public selectedPolyIndex: number = -1;
  public selectedPolygon: any = null;
  public points = {};


  public rooms: RoomDto[];
  public selectedRooms: RoomDto[];
  public currentFloorNumber = -1;
  public floorsAvailable : (number | undefined) [];
  public sensorValues : any;

  public currentlySelectedSensor : String = '';
  public currentlySelectedUnit : String = '';
  public availableSensors : String[];
  public sensorUnits : String[];

  public scaleValues = 10;

  public minTemperatureValue = 15;
  public maxTemperatureValue = 30;

  public minCO2Value = 250;
  public maxCO2Value = 400;

  public selectedRoomId = -1;
  public element : any;
  public alarmInterval = 900;

  onLoad() {
      this.loading = false;
  }

  
  constructor(
    private router: Router,
    public commonDataService: CommonDataService, 
    private domSanitizer: DomSanitizer, 
    private activateRoute: ActivatedRoute,
    private floorPlanProxyService: FloorPlanProxyService,
    private toastrService: ToastrService,
    private roomProxyService: RoomProxyService
    ) { 
        this.availableSensors = ["Temperature","CO₂"];
        this.sensorUnits = ["°C","ppm"]
    }

  public ngOnInit() {
    this.inProgress = true;
    this.activateRoute.params.subscribe(routeParams => {
        this.floorPlanProxyService.listBuildingFloorPlan(Number.parseInt(this.commonDataService.selectedBuildingId!)).subscribe((response) => {
          response = response['body']['result'];
          this.floorPlans = response['items'];
          this.loading = true

          if(routeParams['file_id'])
          {
            this.loadViewer(routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0)
            {
              var first: BuildingSingleFileWebListableDto = response['items'][0];
              
              for(let i=0; i<response['items'].length; i++){
                var selectedItem = response['items'][i];
                if(selectedItem.fileExtension === 'jpg'){
                  first = selectedItem;
                  break;
                }
              }
              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/alarms/" + first.id]);
            }
            this.inProgress = false;
          }
          
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/home/" + this.commonDataService.selectedBuildingId]);
        })
    });
    
  }

  private hideViewers() {
    this.forgeUri = null;
    this.rawViewerUri = null;
    this.imageViewerUri = null;
    this.googleViewerUrl = null;
  }

  private getViewerUri(fileViewer: BuildingSingleFileViewerDto): SafeResourceUrl {
    //return this.domSanitizer.bypassSecurityTrustResourceUrl(`${fileViewer.viewerUri}&time=${new Date().toISOString()}`);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${fileViewer.viewerUri}`);
  }

  private showForgeViewer(forgeViewer: BuildingForgeFileViewerDto) {
    this.hideViewers();

    const paramaters: string = btoa(JSON.stringify(forgeViewer));

    const uri: string = `./forge-viewer/${paramaters}`;
    //console.log(uri);
    this.forgeUri = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
  }

  private showGoogleViewer(file: BuildingSingleFileViewerDto) {
    this.hideViewers();
    var uri = file.viewerUri;
    //const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true&ver=${new Date().toISOString()}`;
    const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true`;
    //console.log(googleUri);
    this.googleViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(googleUri);

  }

  private viewManager(fileViewer: BuildingSingleFileViewerDto){
      if(fileViewer.fileExtension.toLocaleLowerCase() === 'jpg' || fileViewer.fileExtension.toLocaleLowerCase() === 'jpeg'){
        this.hideViewers();
        this.showImageViewer(fileViewer);
        return;
      }
      if(fileViewer.fileExtension.toLocaleLowerCase() === 'pdf'){
        this.showGoogleViewer(fileViewer);
        return;
      }
  }

  private showRawViewer(fileViewer: BuildingSingleFileViewerDto) {
    this.hideViewers();
    this.rawViewerUri = this.getViewerUri(fileViewer);
  }

  private showImageViewer(fileViewer: BuildingSingleFileViewerDto) {
    this.hideViewers();
    this.rawViewerUri = fileViewer.viewerUri;
    this.imageFilePath = this.rawViewerUri;
    
    var context = this;
    var imgInput = document.getElementById('inputImage') as HTMLImageElement;
    imgInput.onload = () => {
      context.imageHeight = imgInput.naturalHeight;
      context.imageWidth = imgInput.naturalWidth;

      d3.select("#image-processing").select("svg").remove();

      var svg = d3.select("#image-processing").append("svg");
      var zoomFn = d3.zoom().on('zoom', function(e) {
       svg.select('g')
        .attr('transform', e.transform);
      }) as any;   
      svg.call(zoomFn);

      svg.attr("id","image-svg")
      .attr("width", context.svgWidth)
      .attr("height", context.svgHeight)                
      .append("g")
      .attr("transform","translate(0,0)");

      var defs = svg.append("defs")

      defs.append("pattern")
          .attr("id","bg-image")
          .attr("height","100%")
          .attr("width","100%")
          .attr("patternContentUnits","objectBoundingBox")
          .append("image")
          .attr("width",1)
          .attr("height",1)
          .attr("preserveAspectRatio","none")
          .attr("xlink:href",context.imageFilePath);

      svg.select('g').attr('id','main').append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', context.imageWidth)
          .attr('height', context.imageHeight)
          .attr('fill', 'url(#bg-image)')
          .attr("opacity", 1)
          .attr("stroke","#000");
    };

  }

  private loadViewer(buildingFloorPlanId: number){

    this.buildingFloorPlanId = buildingFloorPlanId;

    this.floorPlanProxyService.getBuildingFloorPlanViewer(buildingFloorPlanId).subscribe((result) => {
        result = result['body'];
        if(result['result']['fileViewer']) {
          result = result['result'];
          const fileViewer: BuildingSingleFileViewerDto = result['fileViewer'];
          switch(fileViewer.fileExtension.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
              this.viewManager(fileViewer);
            default:
              this.viewManager(fileViewer);
              break;
          }
        } else {
          this.hideViewers();
          var toSend = new BuildingForgeFileViewerDto();
          toSend = toSend.fromJS(result);
          this.showForgeViewer(toSend);
        }

        this.inProgress = false;
      },
      error => {
        this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/alarms'
          ]);
        }
      }
    );

    this.floorPlanProxyService.getBuildingFloorPlanPolygons(this.buildingFloorPlanId).subscribe((result) => {
      if(result['body']['result'] !== null){
      var polygonsData = result['body']['result']['polygons'];
      this.points = polygonsData;
      }else{
        return;
      }
    }, (error) => {
      this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/alarms'
          ]);
        }
    });

    this.roomProxyService.listRooms(Number.parseInt(this.commonDataService.selectedBuildingId!)).subscribe((result) => {
        this.rooms = result['body']['result']['items'];
        
        this.floorsAvailable = [...new Set(this.rooms.map(item => item.floorNumber))];
    }, 
    (error) => { 
      console.log(error.error);
    });

  }

  public processFloorNumber(){
      if(this.currentFloorNumber == -1){
          return;
      }
      this.selectedRooms = [];
      this.selectedRooms = this.rooms.filter(r => r.floorNumber == this.currentFloorNumber);
  }

  public processSensor(){
    if(this.currentlySelectedSensor == ''){
        return;
    }
    if(this.currentlySelectedSensor === 'Temperature'){
      this.currentlySelectedUnit = this.sensorUnits[0];
      this.sensorValues = this.generateRandomTemperatures(Object.keys(this.points).length);
    }else{
      this.currentlySelectedUnit = this.sensorUnits[1];
      this.sensorValues = this.generateRandomCO2Levels(Object.keys(this.points).length);
    }
    this.cleanPolygons();
    
    this.selectedRoomId = this.rooms[Math.floor(Math.random() * this.rooms.length)].id!;

    this.generateContours(this.selectedRoomId);
    //setInterval(this.hidePolygon, 1000);
    //setInterval(this.showPolygon, 500);
    //this.generateLegend();
    this.hidePolygon();
  }

  public getHexagonPoints(radius: any) {
    const halfWidth = radius * Math.sqrt(3) / 2;
    return `
      0,${-radius}
      ${halfWidth},${-radius / 2}
      ${halfWidth},${radius / 2}
      0,${radius}
      ${-halfWidth},${radius / 2}
      ${-halfWidth},${-radius / 2}`;
  };

  public generateLegend(){
    //d3.select('#image-svg').append('g').attr('id', 'legend');
    d3.select('#image-svg').select('defs').append('linearGradient')
      .attr("id", "svgGradient")
      .attr("x1", "0%")
      .attr("x2", "100%")
      .attr("y1", "0%")
      .attr("y2", "0%");
    var temperatureRange = [this.minTemperatureValue, this.maxTemperatureValue];
    var CO2Range = [this.minCO2Value, this.maxCO2Value];
    
    var temperatureScale = [];
    var CO2Scale = [];

    var div1 = (temperatureRange[1] - temperatureRange[0]) / this.scaleValues;
    var div2 = (CO2Range[1] - CO2Range[0]) / this.scaleValues;

    for(let i=0; i<this.scaleValues; i++){
      temperatureScale.push(Math.round(i*div1) + temperatureRange[0]);
      CO2Scale.push(Math.round(i*div2) + CO2Range[0]);
    }

    for(let i=0; i<this.scaleValues; i++){
      if(this.currentlySelectedSensor === 'Temperature'){
        d3.select('#svgGradient').append("stop")
        .attr("offset",`${i*(100/this.scaleValues)}%`)
        .attr('stop-color',this.getTemperatureColor(temperatureScale[i]));

        d3.select('#legend').append('polygon')
        .attr('points', this.getHexagonPoints(20))
        .attr('transform', `translate(${i*(this.svgWidth/this.scaleValues)+20},30)`)
        .style('fill', this.getTemperatureColor(temperatureScale[i]));

        d3.select('#legend').append('text')
        .attr('x',i*(this.svgWidth/this.scaleValues)+5)
        .attr('y',30)
        .attr('font-size',"0.75rem")
        .attr("fill", "white")
        .text(`${temperatureScale[i]}`);
      }

      if(this.currentlySelectedSensor === 'CO₂'){
        d3.select('#svgGradient').append("stop")
        .attr("offset",`${i*(100/this.scaleValues)}%`)
        .attr('stop-color',this.getCO2Color(CO2Scale[i]));

        d3.select('#legend').append('polygon')
        .attr('points', this.getHexagonPoints(20))
        .attr('transform', `translate(${i*(this.svgWidth/this.scaleValues)+15},30)`)
        .style('fill', this.getCO2Color(CO2Scale[i]));

        d3.select('#legend').append('text')
        .attr('x',i*(this.svgWidth/this.scaleValues)+5)
        .attr('y',30)
        .attr('font-size',"0.75rem")
        .attr("fill", "white")
        .text(`${CO2Scale[i]}`);
      }

      d3.select('#legend').append('text')
        .attr('x',i*(this.svgWidth/this.scaleValues)+5)
        .attr('y',40)
        .attr('font-size',"0.75rem")
        .attr("fill", "white")
        .text(`${this.currentlySelectedUnit}`);
      
    }
    d3.select('#legend').append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.svgWidth)
      .attr('height', 10)
      .attr('fill', 'url(#svgGradient)')
      .attr('opacity', 1)
      .attr('display','block');

  }

  public cleanPolygons(){
    d3.selectAll('.polygons').remove();
    d3.selectAll('.text').remove();
    d3.selectAll('.toolTip').remove();
    d3.select("#legend").remove();
    d3.select('#svgGradient').remove();
  }

  public getRandomColor(){
    return "#"+Math.floor(Math.random()*16777215).toString(16);
  }

  public showPolygon(){
    var context = this;
    d3.select(this.element).transition().duration(this.alarmInterval).attr('opacity', 1).on('end', function(){
      // d3.select(context.element).transition().duration(this.alarmInterval).attr('opacity', 0);
      context.hidePolygon();
    });
  }

  public hidePolygon(){
    var context = this;
    d3.select(this.element).transition().duration(this.alarmInterval).attr('opacity', 0).on('end', function(){
      // d3.select(context.element).transition().duration(this.alarmInterval).attr('opacity', 1);
      context.showPolygon();
    });
  }

  public generateContours(roomId : any){
    var context = this;
    var svg = d3.select("#image-svg");
    var polyCenters = [];
  
    for (const [key, _] of Object.entries(this.points)) {
        var polypoints="";
        var sumX = 0;
        var sumY = 0;
        var value = this.points[key]['polygon'];
        for(let i=0; i<value.length; i++){
            polypoints += value[i].x +","+value[i].y;
            sumX += value[i].x;
            sumY += value[i].y;
            if(i < value.length - 1){
                polypoints += " "
            }
        }
        var centerX = sumX / value.length;
        var centerY = sumY / value.length;
        polyCenters.push([centerX, centerY]);
        if(polypoints.length > 0){
              var fill = '#ff0000';
              // if(context.currentlySelectedSensor === 'Temperature'){
              //   fill = context.getTemperatureColor(context.sensorValues[+key]);
              // }
              // if(context.currentlySelectedSensor === 'CO₂'){
              //   fill = context.getCO2Color(context.sensorValues[+key]);
              // }
              if(roomId != this.points[key]['roomId']){
                  continue;
              }
             var element = svg.select('#main').append("polygon")
                .attr("stroke","yellow")
                .attr("stroke-width","3")
                .attr("fill",fill)
                .attr("class","polygons")
                .attr("id",`polygon-${+key+1}`)
                .attr("opacity",0.75)
                .attr("points",polypoints)
                // .on("click", (self) => {
                //   self = self.target;
                //   context.selectedPolygon = self;
                //   context.selectedPolyIndex = +context.selectedPolygon.id.split('-')[1] - 1;
                //   var currentRoom : RoomDto | null = null;
                //   if(context.points[context.selectedPolyIndex]['roomId'] != -1){
                //     currentRoom = context.rooms.filter(r => r.id == context.points[context.selectedPolyIndex]['roomId'])[0];
                //     this.commonDataService.selectedRoom = currentRoom;
                //     this.commonDataService.selectedSensor = context.currentlySelectedSensor;
                //     this.router.navigate([this.commonDataService.selectedBuildingId + '/sensor-integration']);
                //   }else{
                //     return;
                //   }
                                    
                // })
                .on("mouseenter",(self) => {
                  self = self.target;
                  context.selectedPolygon = self;
                  context.selectedPolyIndex = +context.selectedPolygon.id.split('-')[1] - 1;
                  var toolTips = document.getElementsByClassName('toolTip');
                  for(let i=0; i<toolTips.length; i++){
                      d3.select(toolTips[i]).attr('display','none');
                  }
                  d3.select(`#toolTip-${context.selectedPolyIndex}`).attr('display','block');
                  setTimeout(() => {
                    d3.select(`#toolTip-${context.selectedPolyIndex}`).attr('display','none');
                  },2200);
                })
                this.element = document.getElementById(`polygon-${+key+1}`);

                var currentRoom : RoomDto | null = null;
                if(context.points[key]['roomId'] != -1){
                  currentRoom = context.rooms.filter(r => r.id == context.points[key]['roomId'])[0];
                }
                
                var textToShow = '';
                if(currentRoom){
                  textToShow = ""+currentRoom["name"]+" "+currentRoom["width"]!+" × "+currentRoom["length"];
                }

                svg.select('#main').append("text")
                .attr('class','text')
                .attr('x', polyCenters[key][0])
                .attr('y', polyCenters[key][1])
                .attr('text-anchor','middle')
                .attr('fill', "black")
                .attr("stroke", "white")
                .attr("stroke-width", 0.25)
                .attr("font-size", "1rem")
                .attr("font-weight", 700)
                .attr("transform",`rotate(-45,${polyCenters[key][0]},${polyCenters[key][1]})`)
                .text(textToShow);

                var toolTipW = 85;
                var toolTipH = 105;

                polyCenters[key][0] = context.svgWidth - 100;
                polyCenters[key][1] = 200;

                d3.select('#image-svg').append('g').attr('id', 'legend');

                svg.select('#legend').append("g").attr("class","toolTip").attr('id',`toolTip-${+key}`).attr('display','none')
                .append("rect")
                .attr('x', polyCenters[key][0] - toolTipW / 2 + 5)
                .attr('y', polyCenters[key][1] - toolTipH / 2 + 5)
                .attr('width', toolTipW)
                .attr('height', toolTipH)
                .attr('fill', "black")
                .attr('opacity', 0.2)
                .attr('rx', 2);

                svg.select(`#toolTip-${+key}`).append("rect")
                .attr('x', polyCenters[key][0] - toolTipW / 2)
                .attr('y', polyCenters[key][1] - toolTipH / 2)
                .attr('width', toolTipW)
                .attr('height', toolTipH)
                .attr('fill', "white")
                .attr('rx', 2);

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 15)
                  .attr('fill', "black")
                  .attr("style", "font-weight: bold;")
                  .attr("font-size", "0.85rem")
                  .text("Room Name");

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 25)
                  .attr('fill', "black")
                  .attr("style", "font-weight: normal;")
                  .attr("font-size", "0.75rem")
                  .text(currentRoom!.name!);

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 40)
                  .attr('fill', "black")
                  .attr("style", "font-weight: bold;")
                  .attr("font-size", "0.85rem")
                  .text("Width");

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 50)
                  .attr('fill', "black")
                  .attr("style", "font-weight: normal;")
                  .attr("font-size", "0.75rem")
                  .text(currentRoom!.width!);
                
                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 65)
                  .attr('fill', "black")
                  .attr("style", "font-weight: bold;")
                  .attr("font-size", "0.85rem")
                  .text("Length");

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 75)
                  .attr('fill', "black")
                  .attr("style", "font-weight: normal;")
                  .attr("font-size", "0.75rem")
                  .text(currentRoom!.length!);

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 90)
                  .attr('fill', "black")
                  .attr("style", "font-weight: bold;")
                  .attr("font-size", "0.85rem")
                  .text(`${context.currentlySelectedSensor}`);

                var alarmingValue = -1;
                if(context.currentlySelectedSensor === 'Temperature'){
                  alarmingValue = 45;
                }
                if(context.currentlySelectedSensor === 'CO₂'){
                  alarmingValue = 350;
                }

                svg.select(`#toolTip-${+key}`).append("text")
                  .attr('x', polyCenters[key][0] - toolTipW / 2 + 2)
                  .attr('y', polyCenters[key][1] - toolTipH / 2 + 100)
                  .attr('fill', "black")
                  .attr("style", "font-weight: normal;")
                  .attr("font-size", "0.75rem")
                  .text(alarmingValue+` ${this.currentlySelectedUnit}`);
                
        }
    }
  }

  public getCO2Color(co2: any) {
    // // Clamp co2 to the valid range (250-300)
    // co2 = Math.max(250, Math.min(co2, 400));
  
    // // Normalize co2 to a value between 0 and 1
    // var normalizedCO2 = (co2 - 250) / 150;
  
    // // Calculate red, green, and blue components based on normalized temperature
    // var red = Math.round(255 * normalizedCO2);
    // var green = 255 - red;
    // var blue = 255;

    // var redHex = red.toString(16).padStart(2, '0');
    // var greenHex = green.toString(16).padStart(2, '0');
    // var blueHex = blue.toString(16).padStart(2, '0');

    // // Return the color as a hex string in #RRGGBB format
    // return `#${redHex}${greenHex}${blueHex}`;

    var normalizedValue = (co2 - this.minCO2Value) / (this.maxCO2Value - this.minCO2Value);
    var colorIndex = Math.round(normalizedValue * this.scaleValues);
    colorIndex = Math.min(Math.max(colorIndex, 0), this.scaleValues - 1);
    //co2 = Math.round(co2 / 400 * this.scaleValues);
    //console.log(colorIndex);

    var colors = ["#2d7bb6",
                  "#06a6ca",
                  "#06ccbc",
                  "#90eb9d",
                  "#ffff8c",
                  "#f9d057",
                  "#f29e2e",
                  "#e76818",
                  "#d7191c",
                  "#a60003"];

    return colors[colorIndex];

  }

  // Helper functions for converting between hex and RGB formats
  public hexToRgb(hex : any) {
    var match = hex.match(/^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    return match ? match.slice(1, 4).map((x : any) => parseInt(x, 16)) : [];
  }

  public rgbToHex(r : any, g : any, b: any) {
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  public getTemperatureColor(temperature: any) {
    temperature = Math.min(Math.max(temperature, this.minTemperatureValue), this.maxTemperatureValue);

    // Normalize value to a range between 0 and 1
    var normalizedValue = (temperature - this.minTemperatureValue) / (this.maxTemperatureValue - this.minTemperatureValue);

    if (normalizedValue <= 0.5) {
      const color1 = '#01feff';
      const color2 = '#0000ff';
      const [r1, g1, b1] = this.hexToRgb(color1);
      const [r2, g2, b2] = this.hexToRgb(color2);
  
      const r = Math.round(r1 * (1 - 2 * normalizedValue) + r2 * 2 * normalizedValue);
      const g = Math.round(g1 * (1 - 2 * normalizedValue) + g2 * 2 * normalizedValue);
      const b = Math.round(b1 * (1 - 2 * normalizedValue) + b2 * 2 * normalizedValue);
  
      return this.rgbToHex(r, g, b);
    } else {
      const color1 = '#0000ff';
      const color2 = '#ff0000';
      const [r1, g1, b1] = this.hexToRgb(color1);
      const [r2, g2, b2] = this.hexToRgb(color2);
  
      const normalizedValueInSecondSegment = (normalizedValue - 0.5) * 2; // Re-normalize for the second segment
  
      const r = Math.round(r1 * (1 - normalizedValueInSecondSegment) + r2 * normalizedValueInSecondSegment);
      const g = Math.round(g1 * (1 - normalizedValueInSecondSegment) + g2 * normalizedValueInSecondSegment);
      const b = Math.round(b1 * (1 - normalizedValueInSecondSegment) + b2 * normalizedValueInSecondSegment);
  
      return this.rgbToHex(r, g, b);
    }
  }

  public generateRandomTemperatures(n : any) {
    var temperatures = [];
  
    var max = this.maxTemperatureValue;
    var min = this.minTemperatureValue;

    for(let i=0; i<n; i++){
      temperatures.push(Math.floor(Math.random() * (max - min) + min));
    }

    return temperatures;
  }

  public generateRandomCO2Levels(n : any) {
    var co2 = [];
  
    var max = this.maxCO2Value;
    var min = this.minCO2Value;

    for(let i=0; i<n; i++){
      co2.push(Math.floor(Math.random() * (max - min) + min));
    }

    return co2;
  }

}

