<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
    ng::deep .mat-form-field {
        .mat-input-element {
            color: slategray;
        }
        .mat-form-field-label {
            color: slategray;
        }
        .mat-form-field-underline {
            background-color: none;
        }
        .mat-form-field-ripple {
            background-color: none;
        }
        .mat-form-field-required-marker {
            color: slategray;
        }
    }
    .chart-main{
        width: calc(100% - 5rem);
        height: calc(100vh - 165px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
    }
    .chart-left{
        width: 70%;
        height: 100%;
    }
    .chart-right-container{
        margin-top: 2rem;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .guages{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .dataRight{
        text-align: right;
    }
    .dataLeft{
        text-align: left;
        color: white;
        font-size: 1.5rem;
    }
    @keyframes spinner {
        to {transform: rotate(360deg);}
    }
    .spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #007fff;
        border-top-color: #000000;
        animation: spinner .8s linear infinite;
    }
   
    :host ::ng-deep .mat-mdc-form-field{
        height: 40px;
        width: 160px;
    }
    :host ::ng-deep  .mat-mdc-form-field-flex{
        height: 40px !important;
    }
    :host ::ng-deep  .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper{
        display: none !important;
    }
    :host ::ng-deep .mat-mdc-form-field .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
        padding-bottom: 8px !important;
        padding-top: 8px !important;
    }
    .top-ui{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
</style>
<div style="width: calc(100% - 5rem); height: (100% - 82px); margin-left: 5rem;">
    <div class="top-ui">
        <div *ngIf="commonDataService.selectedRoom != null" class="dataLeft">
            <span><b>Room No. </b>{{commonDataService.selectedRoom.roomNumber}}</span>&nbsp;
            <span>{{commonDataService.selectedRoom.name}}&nbsp;{{commonDataService.selectedRoom.width}}&times;{{commonDataService.selectedRoom.length}}</span>
        </div>
        <div class="pt-3 pl-3 pr-3 dataRight">
            <mat-form-field class="mr-3">
                <mat-select [formControl]="deviceForm" placeholder="Devices" multiple>
                    <mat-option *ngFor="let device of alldevices" [value]="device.value">{{device.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mr-2">
                <input matInput [(ngModel)]="startDate" [matDatepicker]="picker" placeholder="Start date" [max]="maxDate" [min]="minDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"><mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="endDate" [matDatepicker]="picker2" placeholder="End date" [max]="maxDate" [min]="minDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"><mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <!-- <mat-form-field>
                <mat-date-range-input [formGroup]="range" [max]="maxDate"> 
                    <input matStartDate [(ngModel)]="startDate" formControlName="start" placeholder="Start date">
                    <input matEndDate [(ngModel)]="endDate" formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rangepicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangepicker></mat-date-range-picker>
            </mat-form-field> -->
            <button mat-raised-button [class.spinner]="loading" [disabled]="loading" class="ui-button ui-state-default ui-state-active ml-3" (click)="getChartData()">Load Chart</button>
        </div>
    </div>
    <div class="chart-layout">
        <div class="chart-main">
            <div class="chart-left">
                <canvas height="400px" width="1200px" baseChart [data]="lineChartData1" type="line"></canvas>
                <canvas height="400px" width="1200px" baseChart [data]="barChartData" type="bar"></canvas>
            </div>
            <div class="chart-right-container">
                <canvas width="400px" height="300px" baseChart [data]="pieChartData" type="pie"></canvas>
                <div class="guages">
                    <div class="guage-01">
                        <ngx-charts-gauge 
                            [view]="view"
                            [min]="0"
                            [max]="1000000"
                            [scheme]="'ocean'"
                            [customColors]="customColors"
                            [results]="Total" 
                            [legend]="legendTotal" 
                            [legendPosition]="legendPosition"
                            [units]="'Total'" >
                        </ngx-charts-gauge>
                    </div>
                </div>
                <div class="bar-chart-01">
                    <ngx-charts-gauge 
                        [view]="view"
                        [results]="Usage"
                        [scheme]="'nightLights'"
                        [min]="0"
                        [max]="1" 
                        [legend]="legendUsage" 
                        [legendPosition]="legendPosition"
                        [units]="'Usage'" >
                    </ngx-charts-gauge>
                </div>
            </div>
        </div>    
    </div>
</div>