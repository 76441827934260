//this directive serves as a declaration of dependency;
/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { CommonDataService } from '../../service/common-data.service';
import { APICallService } from '../../service/apicall.service';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../utils/LocalStroageService';

declare var SiteJS: any;
declare var MainJS: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, AfterViewInit {
  constructor(private router: Router,
    private apicallService: APICallService,
    public commonDataService: CommonDataService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public _ViewerPermissionCheckerService:ViewerPermissionCheckerService,
    private localStorageService:LocalStorageService
  ) {
  }

  maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
  @ViewChild('googlemap') googlemapdiv: any;
  //marker: google.maps.Marker;
  bounds = new google.maps.LatLngBounds();
  lat: any;
  lng: any;
  mapProp: any;
  markersLocation: any = [];
  marker: any;
  markerIconPath = "../../../assets/images/map-marker-icon.png";
  selectedMarker = {};
  myStyle = [
    {elementType: 'geometry', stylers: [{color: '#314151'}]},
    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.fill', stylers: [{color: '#b5c5d5'}]},
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{color: '#263c3f'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{color: '#38414e'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{color: '#212a37'}]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#627282'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{color: '#1f2835'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{color: '#2f3948'}]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{color: '#b5c5d5'}]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{color: '#0a1a2a'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{color: '#515c6d'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{color: '#17263c'}]
    }
  ];

  ngOnInit() {
    this.commonDataService.loadForge = null;
    this.commonDataService.fileData = null;
    this.commonDataService.downloadLink = null;
  }

  setTopMargin(){
    setTimeout(() => {
      var gmStyleElement = (document.querySelector('.gm-style') as HTMLElement);
      gmStyleElement.style.top = '-1px';
    }, 200);
  }

  ngAfterViewInit() {
    //for selected building marker
    this.activatedRoute.params.subscribe(routeParams => {
      //console.log('routeParams-dashboard-',routeParams)
      //this.building_id_FromUrl = routeParams['building_id'];
      this.commonDataService.selectedBuildingId = routeParams['building_id'];
      
    if (this.activatedRoute.snapshot.paramMap.get('building_id')) {
      this.commonDataService.selectedBuildingId = this.activatedRoute.snapshot.paramMap.get('building_id');
      this.getInfoOfBuilding(this.activatedRoute.snapshot.paramMap.get('building_id'));
      //console.log("dashboard-ngafterviewinit-if")
    }
    else
    {
      this.commonDataService.selectedBuildingInfo = null;//resetting building info to show no information on information popup
      this.commonDataService.selectedFileInfo = null;
      if (this.localStorageService.getItem('projectUsersPermission')) {
        this.localStorageService.removeItem('projectUsersPermission')
      }
      this.getAllBuildingInfo();
      //console.log("ngafterviewinit-else")
      //arguments are being passed to set location in case user
      //do not have any building
      this.setMapProperties(59.325, 18.070, true);
      this.commonDataService.map = new google.maps.Map(this.googlemapdiv.nativeElement, this.mapProp);
      //create markers based on building's location
      // if (this.markersLocation.length != 0) {
      //   this.setMarkers();
      //   this.commonDataService.map.fitBounds(this.bounds);
      // }
    }});
    
  }

  getInfoOfBuilding(buildingId : any){
    //to show building info in right side bar
    this.apicallService.getParticularBuilding(buildingId).subscribe((response) => {
      response = response['body'];
      this.commonDataService.selectedBuildingInfo = response['result'];
      this.commonDataService.selectedProject = response['result'];
      // console.log(this.commonDataService.selectedBuildingInfo);
      //MainJS.openDrawer();
      this._ViewerPermissionCheckerService.updateBuildingStatus(buildingId);
      //set map properties for selected building-starts
      this.setMapProperties(this.commonDataService.selectedBuildingInfo!['lat'], this.commonDataService.selectedBuildingInfo!['long'], false);
      this.commonDataService.map = new google.maps.Map(this.googlemapdiv.nativeElement,this.mapProp);
      this.commonDataService.map.setZoom(10);
      var position = new google.maps.LatLng(this.commonDataService.selectedBuildingInfo!['lat'], this.commonDataService.selectedBuildingInfo!['long']);
      let marker: any = new google.maps.Marker({
        position: position,
        map: this.commonDataService.map,
        icon: this.markerIconPath
      });
      //set map properties for selected building-starts
      //console.log("this.localStorageService.get('buildings')-",this.localStorageService.getItem('buildings'))
      if(!this.localStorageService.getItem('buildings'))
      {
        this.getAllBuildingInfo();
      }
      else
      {
        this.commonDataService.availableBuildings = this.localStorageService.getItem('buildings');
        this.commonDataService.allBuildingInfo = this.localStorageService.getItem('buildings');
      }

    }, (error) => {
      this.toastrService.error(error.message);
      this.router.navigate(["/"])
      throw error;
    });
  }

  getAllBuildingInfo(){
    this.apicallService.getBuildingInfo().subscribe((response) => {
      response = response['body'];
      //collect lat and long of all buildings
      this.commonDataService.allBuildingInfo = response['result']['items'];
      this.commonDataService.availableBuildings = response['result']['items'];
      this.localStorageService.setItem('buildings',response['result']['items']);
     
      // assigning first building as selected building-starts if there is only one building
      if(this.commonDataService.allBuildingInfo && this.commonDataService.allBuildingInfo.length == 1 ){
        // this.commonDataService.selectedBuildingId = this.commonDataService.allBuildingInfo[0].id;
        this.router.navigate(["/home/" + this.commonDataService.allBuildingInfo[0].id]);
      }

      if(!this.activatedRoute.snapshot.paramMap.get('building_id')){
        this.markersLocation = this.commonDataService.allBuildingInfo.map((building : any) => {
          return {
            lat: building.lat,
            long: building.long,
            building_id: building.id,
            name: building.name,
            building_info: building
          }
        });
        //create markers based on building's location
        if (this.markersLocation.length != 0) {
          this.setMarkers();
          this.commonDataService.map.fitBounds(this.bounds);
          //set zoom out level
          this.setZoomLevelAccording();
        }
      }
    });
  }

  setMarkers() {
    let that = this;
    for (let i = 0; i < this.markersLocation.length; i++) {
      var position = new google.maps.LatLng(this.markersLocation[i].lat, this.markersLocation[i].long);
      this.bounds.extend(position);
      let marker: any = new google.maps.Marker({
        position: position,
        map: this.commonDataService.map,
        title: this.markersLocation[i].building_id.toString() + "-" + this.markersLocation[i].name,
        icon: this.markerIconPath
      });
      marker.addListener('click', function () {
        let id = parseInt(marker.getTitle().split("-")[0]);
        // that.commonDataService.getBuildingId(id);
          that.router.navigate(['/home/' + id]);
        // that.commonDataService.selectedBuilding(that.markersLocation[i].building_info)
      });
    }
  }

  setMapProperties(lat : any, long : any, _default : boolean) {
    this.mapProp = {
      zoomControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite'],
        position: google.maps.ControlPosition.RIGHT_TOP,
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
      },
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      rotateControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: this.myStyle,
      tilt: 60,
      rotateControl: true
    };
    if ((this.markersLocation.length == 0) && _default) {
      this.mapProp.zoom = 3;
      this.mapProp.center = new google.maps.LatLng(lat,long);
    } else {
      this.mapProp.zoom = 25;
      this.mapProp.center = new google.maps.LatLng(lat, long); 
    }
    var maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
    this.mapProp.restriction = {
      latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
      strictBounds: true
    }
    this.setTopMargin();
  }

  setZoomLevelAccording(){
    let width = window.screen.width;
    // let zoomOut = {minZoom : 2.1,maxZoom: 15};
    if(width <= 780){
      // let zoomOut = {minZoom : 1.4,maxZoom: 15};
      // this.commonDataService.map.setZoom(1.6);
      let zoomOut = {minZoom : 2.1,maxZoom: 15};
      this.commonDataService.map.setZoom(2.1);
      this.commonDataService.map.setOptions(zoomOut);
    } else if(width <= 1280){
      let zoomOut = {minZoom : 2,maxZoom: 15};
      this.commonDataService.map.setZoom(2);
      this.commonDataService.map.setOptions(zoomOut);
    } else if(width <= 2048){
      let zoomOut = {minZoom : 2.6,maxZoom: 15};
      this.commonDataService.map.setZoom(2.8);
      this.commonDataService.map.setOptions(zoomOut);
    }
    
  }
}
