import { Component, OnInit } from '@angular/core';
import { CommonDataService } from "../../service/common-data.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FloorPlanProxyService } from '../../proxies/floorplan-proxy.service';
import { BuildingSingleFileWebListableDto } from '../../../shared/models/base/building-single-file-web-listable-dto';
import { BuildingForgeFileViewerDto } from '../../../shared/models/base/building-forge-file-viewer-dto';
import { BuildingSingleFileViewerDto } from '../../../shared/models/base/singlefile/building-singlefile-viewer-dto';

@Component({
  selector: 'app-view2d',
  templateUrl: './view2d.component.html',
  styleUrls: ['./view2d.component.scss']
})

export class View2dComponent implements OnInit {
  public floorPlans: BuildingSingleFileWebListableDto[];
  public buildingFloorPlanId: number;
  public loading: boolean = true
  public inProgress: boolean = true;
  public forgeUri: any;
  public rawViewerUri: any;
  public imageViewerUri: any;
  public googleViewerUrl: any;

  onLoad() {
      this.loading = false;
  }

  
  constructor(
    private router: Router,
    public commonDataService: CommonDataService, 
    private domSanitizer: DomSanitizer, 
    private activateRoute: ActivatedRoute,
    private floorPlanProxyService: FloorPlanProxyService,
    private toastrService: ToastrService
    ) { }

  public ngOnInit() {
    this.inProgress = true;
    this.activateRoute.params.subscribe(routeParams => {
        this.floorPlanProxyService.listBuildingFloorPlan(Number.parseInt(this.commonDataService.selectedBuildingId!)).subscribe((response) => {
          response = response['body']['result'];
          this.floorPlans = response['items'];
          //console.log(this.floorPlans)
          this.loading = true

          if(routeParams['file_id'])
          {
            this.loadViewer(routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0)
            {
              const first: BuildingSingleFileWebListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/FloorPlan/" + first.id]);
            }

            this.inProgress = false;
          }
          
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/home/" + this.commonDataService.selectedBuildingId]);
        })
      // }
    });

    
  }

  private hideViewers() {
    this.forgeUri = null;
    this.rawViewerUri = null;
    this.imageViewerUri = null;
    this.googleViewerUrl = null;
  }

  private getViewerUri(fileViewer: BuildingSingleFileViewerDto): SafeResourceUrl {
    //return this.domSanitizer.bypassSecurityTrustResourceUrl(`${fileViewer.viewerUri}&time=${new Date().toISOString()}`);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${fileViewer.viewerUri}`);
  }

  private showForgeViewer(forgeViewer: BuildingForgeFileViewerDto) {
    this.hideViewers();

    const paramaters: string = btoa(JSON.stringify(forgeViewer));

    const uri: string = `./forge-viewer/${paramaters}`;
    console.log(uri);
    this.forgeUri = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
  }

  private showGoogleViewer(file: BuildingSingleFileViewerDto) {
    this.hideViewers();
    var uri = file.viewerUri;
    //const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true&ver=${new Date().toISOString()}`;
    const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true`;
    //console.log(googleUri);
    this.googleViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(googleUri);

  }

  private viewManager(fileViewer: BuildingSingleFileViewerDto){
      if(fileViewer.fileExtension.toLocaleLowerCase() === 'jpg' || fileViewer.fileExtension.toLocaleLowerCase() === 'jpeg'){
        this.hideViewers();
        this.showImageViewer(fileViewer);
        return;
      }
      if(fileViewer.fileExtension.toLocaleLowerCase() === 'pdf'){
        this.showGoogleViewer(fileViewer);
        return;
      }
  }

  private showRawViewer(fileViewer: BuildingSingleFileViewerDto) {
    this.hideViewers();
    this.rawViewerUri = this.getViewerUri(fileViewer);
  }

  private showImageViewer(fileViewer: BuildingSingleFileViewerDto) {
    this.hideViewers();
    this.rawViewerUri = fileViewer.viewerUri;
  }

  private loadViewer(buildingFloorPlanId: number){

    this.buildingFloorPlanId = buildingFloorPlanId;

    this.floorPlanProxyService.getBuildingFloorPlanViewer(buildingFloorPlanId).subscribe((result) => {
        result = result['body'];
        if(result['result']['fileViewer']) {
          result = result['result'];
          const fileViewer: BuildingSingleFileViewerDto = result['fileViewer'];
          //console.log(fileViewer.fileExtension.toLowerCase());
          switch(fileViewer.fileExtension.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
              this.viewManager(fileViewer);
            default:
              this.viewManager(fileViewer);
              break;
          }
        } else {
          this.hideViewers();
          var toSend = new BuildingForgeFileViewerDto();
          toSend = toSend.fromJS(result);
          this.showForgeViewer(toSend);
        }

        this.inProgress = false;
      },
      error => {
        this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/FloorPlan'
          ]);
        }
      }
    );
  }
}
