import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../service/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { DocumentProxyService } from '../../proxies/document-proxy.service';
import { BuildingSingleFileWebListableDto } from '../../../shared/models/base/building-single-file-web-listable-dto';
import { DomSanitizer } from '@angular/platform-browser';

declare var MainJS: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent implements OnInit {

  public documents: BuildingSingleFileWebListableDto[];
  public buildingDocumentId: number;
  public rawViewerUrl: any;
  public googleViewerUrl: any;

  public inProgress: boolean = false;

  constructor(private documentProxyService: DocumentProxyService, 
    private activateRoute: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer,
    public commonDataService: CommonDataService,
    private toastrService: ToastrService) { }

    public ngOnInit() {
      this.inProgress = true;
      this.activateRoute.params.subscribe(routeParams => {
          this.documentProxyService.listBuildingDocumentVideo(Number.parseInt(this.commonDataService.selectedBuildingId!))
          .subscribe((response) => {
            response = response['body']['result'];
            this.documents = response['items'];
  
            //this.loading = true

          if(routeParams['file_id'])
          {
            this.loadDocument(+routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0)
            {
              const first: BuildingSingleFileWebListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/documents/" + first.id]);
            }
          }

          this.inProgress = false;
          },
          (error)=>{
            this.inProgress = false;
            this.toastrService.error(error.error.error.message);
            // redirecting to default page in case any exception like (permission denied or invalid access) occurred
            this.router.navigate(["/home/" + this.commonDataService.selectedBuildingId]);
          })
      });
    }

    private hideViewers() {
      this.googleViewerUrl = null;
      this.rawViewerUrl = null;
    }
  
    private showRawViewer(uri: string) {
      this.hideViewers();
      //var isURIOK : boolean = false;
      // this.documentProxyService.testDocumentURL(uri).subscribe(response => {
      //   isURIOK = true;
      // }, error => {
      //   isURIOK = false;
      // });
      //if(isURIOK){
        this.rawViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
      //}
    }
  
    private showGoogleViewer(uri: string) {
      this.hideViewers();
  
      //const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true&ver=${new Date().toISOString()}`;
      const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true`;

      this.googleViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(googleUri);
    }

    private loadDocument(buildingDocumentId: number) {
        this.buildingDocumentId = buildingDocumentId;

        this.documentProxyService.getBuildingDocumentViewer(buildingDocumentId).subscribe((response) => { 
          response = response['body']['result'];
          switch(response['fileExtension'].toLowerCase()) {
            case 'pdf':
            case 'xml':
            case 'txt':
              //this.showRawViewer(response['viewerUri']);
              this.showGoogleViewer(response['viewerUri']);
              break;
            default:
              this.showGoogleViewer(response['viewerUri']);
              break;
          }
    
        },
        error => {
          this.inProgress = false;
          //if file id mentioned in url not found
          {
            this.router.navigate([
              this.commonDataService.selectedBuildingId + '/documents'
            ]);
          }
        }
      );
    }
}
