import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { ValidationService } from '../../service/validation.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-mailnotify',
  templateUrl: './mailnotify.component.html',
  styleUrls: ['./mailnotify.component.scss'],
  providers: [ValidationService]
})

export class MailnotifyComponent implements OnInit {

  passwordForm : UntypedFormGroup;
  username : any;
  token : any;
  showMessage : any;
  message : any;

  constructor(
    private validationService: ValidationService,
    private activatedRoute: ActivatedRoute, 
    private loginService: LoginService,
    private fb:UntypedFormBuilder,
    private router: Router
    ) {
      this.passwordForm = this.fb.group({
        'password':[null, [<any>Validators.required],null],
        'confirmPassword':[null, [<any>Validators.required],null]
      });
     }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = encodeURIComponent(params['token']);
      this.username = encodeURIComponent(params['username']);
    });
  }

  submit(){
    this.showMessage = false;
    if(!this.passwordForm.valid){
      
      this.validationService.validateAllFormFields(this.passwordForm);
    } else {
    // if(this.passwordForm.value.password != this.passwordForm.value.confirmPassword){
      //   this.showMessage = true;
      //   this.message = "Password and Confirm Password Should be Same";
    // } else {
        const value =  {
          username : this.username,
          token: this.token,
          password : this.passwordForm.value.password
        }
        this.loginService.checkEmailConfirm(value).subscribe((response) =>{
          response = response['body'];
          if(response['result']['result'] == true){
            this.showMessage = true;
            this.message = response['result']['message'];
            this.router.navigate(['/login']);
          } else if(response['result']['result'] == false){
            this.showMessage = true;
            this.message = response['result']['message'];
          }
        })
      };
    // }
  }
}
