// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CommonDataService } from '../../service/common-data.service';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import * as moment from 'moment';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';
import { SensorIntegrationsGraphProxyService } from '../../proxies/sensor-integrations-graph-proxy.service';
import { LocalSensorDeviceDto } from '../../../shared/models/local-sensor-device-dto';
import { UntypedFormControl, FormGroup, FormControl } from '@angular/forms';
import { Chart, ChartData, ChartDataset, ChartOptions, LegendOptions} from 'chart.js';
import { DomSanitizer } from '@angular/platform-browser';
import { BuildingStatusProxy } from '../../proxies/building-status-proxy.service';
import { LineChartModule, NgxChartsModule } from '@swimlane/ngx-charts';
import deviceData01 from '../../../assets/js/data-Device-01.json';
import deviceData02 from '../../../assets/js/data-Device-02.json';
import { Conditional } from '@angular/compiler';
import { RoomDto } from 'src/shared/models/RoomDto';

const GraphFontFamily: string = 'proxima-nova';

const GridColor: string = '#555555';
const AxisLabelColor: string = '#f8f9fa';
const ZeroAxisColor: string = '#555555';

const PropertyDefaultColor: string = '#fefefe';
const PropertyColors: string[] = [
    '#00a8a8',
    '#e4b7a0',
    '#e09200',
    '#b6e2d3',
    '#e0e000',
    '#00FFFF',
    '#b9b7bd',
    '#a16ae8',
    '#ffc65c',
    '#2eb5e0',
    '#f8ccdc',
    '#778a35'
];

const SelectionPeriods: SelectionPeriod[] = [
    { name: 'Day', ticks: 12, axisFormat: 'HH:ss', chartUnit: 'hour', periodUnit: 'day' },
    { name: 'Week', ticks: 7, axisFormat: 'dddd', chartUnit: 'day', periodUnit: 'week' },
    { name: 'Month', ticks: 5, axisFormat: '[Week] w', chartUnit: 'week', periodUnit: 'month' },
    { name: 'Year', ticks: 12, axisFormat: 'MMMM', chartUnit: 'month', periodUnit: 'year' },
];

@Component({
    selector: 'app-new-indoor-air-analysis',
    templateUrl: './sensor-integration-graph.component.html',
    providers: [SubHeaderComponent],
})

export class SensorIntegrationGraphComponent implements OnInit {

    getRandomFloat(min: Number, max : Number, decimals: Number) {
        const str = (Math.random() * (max - min) + min).toFixed(decimals,);
        return parseFloat(str);
    }

    getRandomFloatArray(size: Number){
        var arr = [];
        var min = 0.0;
        var max = 1.6;
        var decimals = 1;

        for(let i=0; i<size; i++){
            arr.push(this.getRandomFloat(min, max, decimals));
        }

        return arr;
    }

    getRandomIntArray(size: Number){
        var arr = [];
        var max = 559700;
        var min = 559100;

        for(let i=0; i<size; i++){
            arr.push(Math.floor(Math.random() * (max - min) + min));
        }

        return arr;
    }

    private requiredChartData = {
        "0": {
            "DeviceName" : "Device 1",
            "total" : this.getRandomIntArray(10),
            "usage" : this.getRandomFloatArray(10),
            "labels" : ['2023-12-28','2023-12-27','2023-12-26','2023-12-25','2023-12-24','2023-12-23','2023-12-22','2023-12-21','2023-12-20','2023-12-19']
        },
        "1": {
            "DeviceName" : "Device 2",
            "total" : this.getRandomIntArray(10),
            "usage" : this.getRandomFloatArray(10),
            "labels" : ['2023-12-28','2023-12-27','2023-12-26','2023-12-25','2023-12-24','2023-12-23','2023-12-22','2023-12-21','2023-12-20','2023-12-19']
        },
        "2": {
            "DeviceName" : "Device 3",
            "total" : this.getRandomIntArray(10),
            "usage" : this.getRandomFloatArray(10),
            "labels" : ['2023-12-28','2023-12-27','2023-12-26','2023-12-25','2023-12-24','2023-12-23','2023-12-22','2023-12-21','2023-12-20','2023-12-19']
        }
    };

    private device01Averages = {};
    private device02Averages = {};

    private readonly defaultColor: string = PropertyDefaultColor;
    private readonly colors: string[] = PropertyColors;
    public uri: any;

    public deviceControl   = new UntypedFormControl();
    public periodControl   = new UntypedFormControl();
    public propertyControl = new UntypedFormControl();
    alldevices: Device[] = [
        {value: '1', viewValue: "Temperature"},
        {value: '2', viewValue: "CO₂"}
        // {value: '3', viewValue: 'Device 3'},
        // {value: '4', viewValue: 'Device 4'},
        // {value: '5', viewValue: 'Device 5'},
        // {value: '6', viewValue: 'Device 6'}
    ];
    myselectedDevices = [];
    deviceForm: FormControl; 
    private buildingId: number;
    public inProgress: boolean = true;
    public readings: ReadingContainer[];

    public selectedLocalDevice: LocalSensorDeviceDto;
    public localDevices: LocalSensorDeviceDto[] = [];
    public sensorIntegrationDeviceId: number;

    public period: Period;
    public selectionPeriod: SelectionPeriod;
    public selectionPeriods: SelectionPeriod[] = SelectionPeriods;

    public usageChartType = {type: 'line'};
    public totalChartType = {type: 'line'};

    public usageChart : any[];
    public totalChart : any[];

    public lineChartData: ChartDataset[] = [];

    view: [number, number] = [400, 300];
    legendTotal: boolean = false;
    legendUsage: boolean = false;
    legendPosition: any = 'top';

    colorScheme = {
        colorDomain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
        ScaleType:["Linear"]
    };
    selectedGroup: any;
    startDate: Date;
    endDate: Date;
    maxDate: Date;
    minDate: Date;
    loading = false;

    customColors = [
        {
            name: "451200",
            value: '#0000ff'
        },
        {
            name: "0.82",
            value: '#00ff00'
        }
    ];

    Total = [
        // {
        //   "name": "Total",
        //   "value": 451200
        // }
    ];
    
    Usage = [
        // {
        //   "name": "Usage",
        //   "value": 0.82
        // },
    ];

    public lineChartData1 = {
        labels: ["Sat",	"Sun", "Mon", "Tue", "Wed",	"Thu", "Fri"],
        datasets: [{
            label: 'Total', // Name the series
            data: [372482, 408135, 261007, 379976, 447648, 429124, 326627], // Specify the data values array
            // fill: true,
            borderColor: '#1D68FB', // Add custom color border (Line)
            pointBackgroundColor: '#1D68FB', // Add custom color background (Points and Fill)
            backgroundColor: '#1D68FB',
            borderWidth: 1.5, // Specify bar border width
            tension: 0.3,
        }]
    };

    lineChartOptions = {}

    public barChartData = {
        labels: ["Sat",	"Sun", "Mon", "Tue", "Wed",	"Thu", "Fri"],
        datasets: [
            {
            label: 'Usage',
            data: [0.44, 0.54, 0.76, 0.33, 0.46, 0.82, 0.42],
            borderColor: '#4e31a5', // Add custom color border (Line)
            pointBackgroundColor: '#4e31a5', // Add custom color background (Points and Fill)
            backgroundColor: '#4e31a5',
            borderWidth: 2, // Specify bar border width
            tension: 0.3,
        }]
    };

    public pieChartData = {
        labels: ["Total", "Usage"],
        datasets: [{
            label: 'Usage',
            data: [5, 2],
            backgroundColor: ['#36a2eb', '#ff9f40'],
        }]
    };

    public guageChartData = {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"]
        }],
        options: {
        rotation: 270, // start angle in degrees
        circumference: 180, // sweep angle in degrees
        }
    };

    public previousPeriod() {
        const period = this.period;
        period.start = period.start.clone().subtract(1, period.offset);
        period.end   = period.end.clone().subtract(1, period.offset);
        this.refreshChart(true);
    }
      
    public nextPeriod() {
        const period = this.period;
        period.start = period.start.clone().add(1, period.offset);
        period.end   = period.end.clone().add(1, period.offset);
        this.refreshChart(true);
    }

    constructor(private activatedRoute: ActivatedRoute,
                public commonDataService: CommonDataService,
                public subheaderService: SubHeaderComponent,
                public _ViewerPermissionCheckerService:ViewerPermissionCheckerService,
                private sensorIntegrationsGraphProxyService: SensorIntegrationsGraphProxyService,
                private domSanitizer: DomSanitizer,
                private buildingStatusProxy: BuildingStatusProxy) { 
                    this.maxDate = new Date(deviceData01['labels'][deviceData01['labels'].length - 1]);
                    this.minDate = new Date(deviceData01['labels'][9]);
                    this.startDate = this.minDate;
                    this.endDate = this.maxDate;
                    //this.guageChartData['options'] = this.guageChartDataOptions;
                    //To-do
                    //1) Get Data from Service
                    //2) Parse Data 
                    //3) Show on the Graph
                    //4) Make Controls for Time / Property Selection 
                    //5) Make the Controls Functional
                    //6) If Possible change the Chart Type Dynamically and on-demand
    }

    public getAverageValuePerDay(data){
        var averagePerDay = {};
        data['labels'] = data['labels'].map(element => {
            return element.split('T')[0];
        });
        var startLabel = data['labels'][0];
        var count = 0;
        var sum = 0;
        var labels = [];
        var avgs = [];
        for(let i=0; i<data['values'].length; i++){    
            if(startLabel === data['labels'][i]){
                sum += data['values'][i];
                count++;
            }else{
                avgs.push(sum / count);
                labels.push(startLabel);
                startLabel = data['labels'][i];
                sum = 0;
                count = 0;
            }
        }
        //Pushing Last Values
        avgs.push(sum / count);
        labels.push(data['labels'][data['values'].length - 1]);

        averagePerDay = { "Device Name": data['Device Name'],"values" : avgs, "labels": labels};
        return averagePerDay;
    }

    public getAverageValuePerDayByDateRange(dataInput, startDate, endDate){
        var ed = endDate.getTime();
        var sd = startDate.getTime();
        var indexes = [];
        var data = {};
        data = dataInput;
        var resultLabels = data['labels'].filter(d => {
            var time = new Date(d).getTime();
            var condition = (sd < time && time < ed);
            if(condition) {
                indexes.push(true);
            }else{
                indexes.push(false);
            }
            return condition;
            });
        
        var resultValues = data['values'].filter((d, ind) => indexes[ind])
        data['labels'] = resultLabels;
        data['values'] = resultValues;
        var averagePerDay = {};
        data['labels'] = data['labels'].map(element => {
            return element.split('T')[0];
        });
        var startLabel = data['labels'][0];
        var count = 0;
        var sum = 0;
        var labels = [];
        var avgs = [];
        for(let i=0; i<data['values'].length; i++){    
            if(startLabel === data['labels'][i]){
                sum += data['values'][i];
                count++;
            }else{
                avgs.push(sum / count);
                labels.push(startLabel);
                startLabel = data['labels'][i];
                sum = 0;
                count = 0;
            }
        }
        //Pushing Last Values
        avgs.push(sum / count);
        labels.push(data['labels'][data['values'].length - 1]);

        averagePerDay = { "Device Name": data['Device Name'],"values" : avgs, "labels": labels};
        return averagePerDay;
    }
    
    public ngOnInit() {
        // console.log(this.commonDataService.selectedRoom, this.commonDataService.selectedSensor);
        // console.log(deviceData01, deviceData02);

        if(this.commonDataService.selectedSensor === "CO₂"){
            this.myselectedDevices = ['2'];
        }

        if(this.commonDataService.selectedSensor === "Temperature"){
            this.myselectedDevices = ['1'];
        }
        this.deviceForm = new FormControl(this.myselectedDevices);


        // this.device01Averages = this.getAverageValuePerDay(deviceData01);
        // this.device02Averages = this.getAverageValuePerDay(deviceData02);
        
        // console.log(this.device01Averages);
        // console.log(this.device02Averages);
        
        // this.device01Averages = this.getAverageValuePerDayByDateRange(deviceData01, '2023-12-07T02:00:00.000Z', '2023-12-20T02:00:00.000Z');
        // this.device02Averages = this.getAverageValuePerDayByDateRange(deviceData02, '2023-12-07T02:00:00.000Z', '2023-12-20T02:00:00.000Z');

        // console.log(this.device01Averages);
        // console.log(this.device02Averages);

        const routeParent = this.activatedRoute.parent;
        this.buildingId = +routeParent!.snapshot.paramMap.get('building_id')!;
        routeParent!.paramMap.subscribe((paramMap: ParamMap) => {
            this.buildingId = +paramMap.get('building_id')!;
            //this.refreshLocalDevices();
        });
        this.sensorIntegrationsGraphProxyService.listLocalDevices(this.buildingId).subscribe(response => {
            this.localDevices = response['body']['result'];
            var d = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
            var chartStartDate = moment(d, "YYYY-MM-DD").format("YYYY-MM-DD");
            var chartEndDate = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
            // console.log(this.myStartDate);
            if(this.localDevices.totalCount > 0){
                this.selectedLocalDevice = this.localDevices.items[0];
                this.sensorIntegrationsGraphProxyService.deviceReadings(this.selectedLocalDevice.id, 500, new Date(chartStartDate), new Date(chartEndDate)).subscribe(response => {
                    var total = response['body']['result']['total'];
                    var labelsTotal = total.map(a => a.timestamp);
                    var valuesTotal = total.map(a => a.value);
                    labelsTotal = labelsTotal.slice(0, 99);
                    valuesTotal = valuesTotal.slice(0, 99);
                    let value2: string[] = valuesTotal;
                    let reversedValue = [...value2].reverse();
                    this.lineChartData1 = {
                        labels: labelsTotal,
                        datasets: [
                            {
                                label: 'Total Device 1', // Name the series
                                data: valuesTotal, // Specify the data values array
                                borderColor: '#1D68FB', // Add custom color border (Line)
                                pointBackgroundColor: '#1D68FB', // Add custom color background (Points and Fill)
                                backgroundColor: '#1D68FB'
                            }
                        ]
                    };
        
                    var usage = response['body']['result']['usage'];
                    // console.log(response['body']['result']);
                    var labelsUsage = usage.map(a => a.timestamp);
                    var valuesUsage = usage.map(a => a.value);
        
                    labelsUsage = labelsUsage.slice(labelsUsage.length - 100, labelsUsage.length - 1);
                    valuesUsage = valuesUsage.slice(valuesUsage.length - 100, valuesUsage.length - 1);
                    let device2: string[] = valuesUsage;
                    let reversedDevice = [...device2].reverse();
                    // console.log(valuesUsage);
                    // console.log(reversedDevice);
                    this.barChartData = {
                        labels: labelsUsage,
                        datasets: [
                            {
                                label: 'Device 1', // Name the series
                                data: valuesUsage, // Specify the data values array
                                borderColor: '#4e31a5', // Add custom color border (Line)
                                pointBackgroundColor: '#4e31a5', // Add custom color background (Points and Fill)
                                backgroundColor: '#4e31a5',
                                borderWidth: 2, // Specify bar border width
                                tension: 0.3,
                            }
                        ]
                    };
                    let myTotalValue = Math.max(...valuesTotal);
                    let myTotalUsage = Math.max(...valuesUsage);
                    // console.log(Math.max(...valuesTotal));
                    // console.log(Math.max(...valuesUsage));
                    this.pieChartData = {
                        labels: ['Total', 'Usage'],
                        datasets: [
                            {
                                // label: ['Total', 'Usage'], // Name the series
                                data: [5, 1], // Specify the data values array
                                backgroundColor: ['#36a2eb', '#ff9f40'],
                            }
                        ]
                    };
        
                    this.Total = [{
                        "name": "Total",
                        "value": Math.max(...valuesTotal)
                    }];
                
                    this.Usage = [{
                        "name": "Usage",
                        "value": Math.max(...valuesUsage)
                    }];
        
                })
            }
        });
    }

    getChartData(){
        this.loading = true;
        // console.log("function called"+matStartDate.value);let dateStart = new Date(this.startDate);
        
        // console.log(dateStart);
        // console.log(this.requiredChartData);
        // console.log(dateEnd);
        // console.log(this.deviceForm.value);
        // let myArray = this.requiredChartData;
        // console.log(myArray.length);
        // for (int i = 0; i < myArray.GetLength(0); i++)
        // {
        //     Console.log(myArray[i, 0] + " " + myArray[i, 1]);
        // }
        this.sensorIntegrationsGraphProxyService.listLocalDevices(this.buildingId).subscribe(response => {
            let dateStart = moment(this.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
            let dateEnd = moment(this.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
            this.localDevices = response['body']['result'];
            if(this.localDevices.totalCount > 0){
                this.selectedLocalDevice = this.localDevices.items[0];
                this.sensorIntegrationsGraphProxyService.deviceReadings(this.selectedLocalDevice.id, 500, new Date(dateStart), new Date(dateEnd)).subscribe(response => {
                    var total = response['body']['result']['total'];
                    var labelsTotal = total.map(a => a.timestamp);
                    var valuesTotal = total.map(a => a.value);
                    labelsTotal = labelsTotal.slice(0, 99);
                    valuesTotal = valuesTotal.slice(0, 99);
                    // console.log(this.getRandomIntArray(10));
                    // console.log(valuesTotal);
                    //console.log(this.deviceForm.value);
                    var currentSensor = this.alldevices[Number.parseInt(this.deviceForm.value[0]) - 1].viewValue;
                    
                    // console.log(currentSensor);
                    // console.log(this.startDate, this.endDate, this.startDate.toISOString(), this.endDate.toISOString());
                    if(currentSensor === "Temperature"){
                        this.device01Averages = {};
                        // console.log(this.device01Averages);
                        
                        this.device01Averages = this.getAverageValuePerDayByDateRange(deviceData01, new Date(dateStart), new Date(dateEnd));
                        console.log(this.device01Averages);
                        this.lineChartData1 = {
                            labels : this.device01Averages['labels'],
                            datasets:[
                                {
                                    label: currentSensor,
                                    data: this.device01Averages['values'],
                                    borderColor: '#1D68FB', // Add custom color border (Line)
                                    pointBackgroundColor: '#1D68FB', // Add custom color background (Points and Fill)
                                    backgroundColor: '#1D68FB'
                                }
                            ]
                        };
                        this.barChartData = {
                            labels : this.device01Averages['labels'],
                            datasets:[
                                {
                                    label: currentSensor,
                                    data: this.device01Averages['values'],
                                    borderColor: '#4e31a5', // Add custom color border (Line)
                                    pointBackgroundColor: '#4e31a5', // Add custom color background (Points and Fill)
                                    backgroundColor: '#4e31a5'
                                }
                            ]
                        };
                    }
                    if(currentSensor === "CO₂"){
                        this.device02Averages = {};
                        // console.log(this.device02Averages);
                        this.device02Averages = this.getAverageValuePerDayByDateRange(deviceData02, new Date(dateStart), new Date(dateEnd));
                        console.log(this.device02Averages);
                        this.lineChartData1 = {
                            labels : this.device02Averages['labels'],
                            datasets:[
                                {
                                    label: currentSensor,
                                    data: this.device02Averages['values'],
                                    borderColor: '#00a8a8', // Add custom color border (Line)
                                    pointBackgroundColor: '#00a8a8', // Add custom color background (Points and Fill)
                                    backgroundColor: '#00a8a8'
                                }
                            ]
                        };
                        this.barChartData = {
                            labels : this.device02Averages['labels'],
                            datasets:[
                                {
                                    label: currentSensor,
                                    data: this.device02Averages['values'],
                                    borderColor: '#00a8a8', // Add custom color border (Line)
                                    pointBackgroundColor: '#00a8a8', // Add custom color background (Points and Fill)
                                    backgroundColor: '#00a8a8'
                                }
                            ]
                        };
                    }
                    // this.lineChartData1 = {
                    //     labels: deviceData01['labels'],
                    //     datasets: [
                    //         {
                    //             label: currentSensor, // Name the series
                    //             data: deviceData01['values'], // Specify the data values array
                    //             borderColor: '#1D68FB', // Add custom color border (Line)
                    //             pointBackgroundColor: '#1D68FB', // Add custom color background (Points and Fill)
                    //             backgroundColor: '#1D68FB',
                    //         },
                    //         // {
                    //         //     label: 'Device 2', // Name the series
                    //         //     data: this.getRandomIntArray(10), // Specify the data values array
                    //         //     borderColor: '#00a8a8', // Add custom color border (Line)
                    //         //     pointBackgroundColor: '#00a8a8', // Add custom color background (Points and Fill)
                    //         //     backgroundColor: '#00a8a8',
                    //         // },
                    //         // {
                    //         //     label: 'Device 3', // Name the series
                    //         //     data: this.getRandomIntArray(10), // Specify the data values array
                    //         //     borderColor: '#e09200', // Add custom color border (Line)
                    //         //     pointBackgroundColor: '#e09200', // Add custom color background (Points and Fill)
                    //         //     backgroundColor: '#e09200',
                    //         // }
                    //     ]
                    // };
    
                    var usage = response['body']['result']['usage'];
                    // console.log(response['body']['result']);
                    var labelsUsage = usage.map(a => a.timestamp);
                    var valuesUsage = usage.map(a => a.value);
    
                    labelsUsage = labelsUsage.slice(labelsUsage.length - 100, labelsUsage.length - 1);
                    valuesUsage = valuesUsage.slice(valuesUsage.length - 100, valuesUsage.length - 1);
                    // console.log(valuesUsage);
                    // this.barChartData = {
                    //     labels: ['2023-12-19','2023-12-20','2023-12-21','2023-12-22','2023-12-23','2023-12-24','2023-12-25','2023-12-26','2023-12-27','2023-12-28'],
                    //     datasets: [
                    //         {
                    //             label: 'Usage Device 1', // Name the series
                    //             data: this.getRandomFloatArray(10), // Specify the data values array
                    //             borderColor: '#4e31a5', // Add custom color border (Line)
                    //             pointBackgroundColor: '#4e31a5', // Add custom color background (Points and Fill)
                    //             backgroundColor: '#4e31a5',
                    //         },
                    //         {
                    //             label: 'Usage Device 2', // Name the series
                    //             data: this.getRandomFloatArray(10), // Specify the data values array
                    //             borderColor: '#00a8a8', // Add custom color border (Line)
                    //             pointBackgroundColor: '#00a8a8', // Add custom color background (Points and Fill)
                    //             backgroundColor: '#00a8a8',
                    //         },
                    //         {
                    //             label: 'Usage Device 3', // Name the series
                    //             data: this.getRandomFloatArray(10), // Specify the data values array
                    //             borderColor: '#e09200', // Add custom color border (Line)
                    //             pointBackgroundColor: '#e09200', // Add custom color background (Points and Fill)
                    //             backgroundColor: '#e09200',
                    //         }
                    //     ]
                    // };

                    let myTotalValue = Math.max(...valuesTotal);
                    let myTotalUsage = Math.max(...valuesUsage);
                    this.pieChartData = {
                        labels: ['Total', 'Usage'],
                        datasets: [
                            {
                                label: 'Total', // Name the series
                                data: [5, 1], // Specify the data values array
                                backgroundColor: ['#36a2eb', '#ff9f40'],
                            }
                        ]
                    };

                    this.Total = [{
                        "name": "Total",
                        "value": Math.max(...valuesTotal)
                    }];
            
                    this.Usage = [{
                        "name": "Usage",
                        "value": Math.max(...valuesUsage)
                    }];

                    this.loading = false;
                })
            }
        });
    }
    
    private createPeriod(period: moment.unitOfTime.DurationConstructor): Period {
        const now   = moment();
        const start = now.clone().startOf(period).utc();
        const end   = start.clone().add(1, period).subtract(1, 'seconds');
        return {
            start: start,
            end: end,
            offset: period
        };
    }
    
    private setPeriod(periodId: number) {
        const period: SelectionPeriod = this.selectionPeriods[periodId];
        this.selectionPeriod = period;
        this.period = this.createPeriod(period.periodUnit);
        this.periodControl.setValue(periodId);
    }

    public periodSelect(event: any) {
        this.setPeriod(event.value);
        this.refreshChart(true);
    }
  
    public deviceSelect(event: any) {
        this.sensorIntegrationDeviceId = event.value;
        this.refreshChart(true);
    }
  
    public propertySelect(event: any) {
        const enabledReadings: ReadingContainer[] = [];
        const values: string[] = event.value;
        for(const name of values) {
            const reading = this.readings.find(p => p.name === name);
            enabledReadings.push(reading!);
        }

        for(const reading of this.readings) {
            reading.enabled = false;
        }

        for(const reading of enabledReadings) {
            reading.enabled = true;
        }
        this.updatePropertySelection();
    }

    private updatePropertySelection() {
        const datasets: ChartDataset[] = [];
        for(const reading of this.readings) {
            if(reading.enabled) {
                datasets.push(reading.dataset);
            }
        }
        this.lineChartData = datasets;
    }
    
    public refreshChart(keepProperties?: boolean) {
        const period = this.period;
        const selectionPeriod = this.selectionPeriod;
        const oldReadings = this.readings;
        if(!this.sensorIntegrationDeviceId || !period || !selectionPeriod) return;
        this.sensorIntegrationsGraphProxyService.deviceReadings(this.sensorIntegrationDeviceId, 300, period.start.toDate(), period.end.toDate()).subscribe((result) => {
            result = result['body']['result'];
            const keys: string[] = Object.keys(result);
            keys.sort();
            const readingsContainer: ReadingContainer[] = [];
            for(const index in keys) {
                const name = keys[index];
                const readings = result[name];
                const chartPoints: Chart.Point[] = readings?.map((r : any) => <Chart.Point>{ 
                    x: moment.utc(r.timestamp).local().toDate().getUTCMilliseconds(), 
                    y: r.value.toFixed(2) 
                }) || [];
        
                const color = this.colors[index] || this.defaultColor;
        
                const dataset: ChartDataset = {
                    data: chartPoints,
                    label: name,
                    fill: false,
                    pointRadius: 0,
                    pointHitRadius: 18,
                    pointHoverRadius: 2,
                    borderColor: color,
                    pointBackgroundColor: color
                };
        
                const reading: ReadingContainer = {
                    dataset: dataset,
                    name: name,
                    enabled: true,
                    color: color
                };
        
                if(keepProperties && oldReadings) {
                    const oldReading = oldReadings.find(p => p.name == name);
                    if(oldReading) {
                        reading.enabled = oldReading.enabled;
                    }
                }
                readingsContainer.push(reading);
            }
            this.readings = readingsContainer;
            this.propertyControl.setValue(readingsContainer.filter(p => p.enabled).map(p => p.name));
            this.updatePropertySelection();
            this.lineChartOptions = this.constructChartOptions(selectionPeriod, period);
        });
    }

    private constructChartOptions(selectionPeriod: SelectionPeriod, period: Period): any {
    }
}

class Period {
    public start: moment.Moment;
    public end: moment.Moment;
    public offset: moment.unitOfTime.DurationConstructor;
}
  
class SelectionPeriod {
    public name: string;
    public periodUnit: moment.unitOfTime.DurationConstructor;
    public ticks: number;
    public axisFormat: string;
    public chartUnit: Chart.TimeUnit;
}
  
class ReadingContainer {
    public dataset: ChartDataset;
    public enabled: boolean;
    public name: string;
    public color: string;
}

const formatNumber = (value: number, count: number = 3, separator: string = ' '): string => {
    if(!value)
        return value?.toString();

    const valueString: string   = value.toString();
    const integer: number       = Math.floor(value);
    const integerString         = integer.toString();
    const decimalString: string = valueString.substring(integerString.length, valueString.length);
  
    if(integerString.length <= count) {
        return value.toString();
    } else {
        const formatted: string[] = [];
        const skip: number = integerString.length % count;
        const iterationCount: number = (integerString.length - skip) / count;
        if(skip > 0)
            formatted.push(integerString.substring(0, skip));

        for (let i = 0; i < iterationCount; i++) {
            const start: number = skip + (count * i);
            formatted.push(integerString.substring(start, start + count));
        }

        if(decimalString.length > 0) {
            return formatted.join(separator) + decimalString;
        } else {
            return formatted.join(separator);
        }
    }
}