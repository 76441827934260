export class AppConst {
     public static readonly floorplan = 'FloorPlan';
     public static readonly bimviewer = 'bimviewer';
     public static readonly buildingview = 'building-view';
     public static readonly outdoorview = 'outdoor-view';
     public static readonly drone = 'drone';
     public static readonly sensorIntegrationGraph = 'sensor-integrations';
     public static readonly externallink = 'externallink';
     public static readonly document = 'documents';
     public static readonly admin = 'Admin';
     public static readonly tracking = 'tracking';
}

export enum ImageFileExtensions {
     png = 'png',
     jpeg = 'jpeg',
     jpg = 'jpg',
     imagejpeg = 'image/jpeg',
     applicationpdf = 'application/pdf'
}

export enum DocumentExtension {
     msword = 'application/msword',
     wordprocessingmldocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
     wordprocessingmltemplate  = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
     textPlain = 'text/plain',
     applicationxml = 'application/xml',
     applicationpdf = 'application/pdf',
     textxml = 'text/xml'
}
