import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { CommonDataService } from '../../service/common-data.service';
import { APICallService } from '../../service/apicall.service';
import { ViewerPermissionCheckerService } from '../../service/viewer-permission-checker.service';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs';
import { BuildingStatusDto } from 'src/shared/models/building-status-dto';
import { ReportService } from 'src/app/service/report.service';
import { SensorIntegrationsGraphProxyService } from 'src/app/proxies/sensor-integrations-graph-proxy.service';
import { isNullOrUndef } from 'chart.js/dist/helpers/helpers.core';

declare var MainJS: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [LoginService]
})


export class HeaderComponent implements OnInit{
  //selectedBuildingId: string;
  fullName: string;
  buildVersion:string | null;
  admin_url: string | null;
  usermailAddress = null;
  //status: BuildingStatusDto;
  subMenuVisibility: boolean[] = [false,false,false,false];
  analyticsActive = false;
  // calendarActive = false;
  //subMenuLinks: string[] = ["FloorPlan","bimviewer","building-view","matterport","outdoor-view","drone","documents","externallink"];

  constructor(  
    private authService:AuthService,
    private router:Router,
    private loginService:LoginService,
    public commonDataService: CommonDataService,
    private apicallservice: APICallService,
    private activateRoute: ActivatedRoute,
    public _ViewerPermissionCheckerService:ViewerPermissionCheckerService,
    public sensorIntegrationsGraphProxyService: SensorIntegrationsGraphProxyService
    ){ 
      this.admin_url = environment.admin_url;
      // this._ViewerPermissionCheckerService.viewerPermissionCheckerUpdated.subscribe((response : ViewerPermissionCheckerService)=>{
      //     this.status = response.status;
      // });
    }
  
  clearAllSubMenus(){
    for(let i=0; i<4; i++){
      var subMenu = document.getElementById('subMenu-'+(i+1)) as HTMLElement | null;
      subMenu!.style.display = 'none';
    }
  }

  setVisibilityStates(id : number){
    for(let i=0; i<4; i++){
      if(id - 1 != i){
        this.subMenuVisibility[i] = false;
      }
    }
  }
  
  toggleSubMenu(id : number){
    this.clearAllSubMenus();
    var selectedSubMenu = document.getElementById('subMenu-'+id) as HTMLElement | null;
    if(!this.subMenuVisibility[id - 1]){
      selectedSubMenu!.style.display = 'flex';
      this.subMenuVisibility[id - 1] = true;
      this.setVisibilityStates(id);
    }else{
      selectedSubMenu!.style.display = 'none';
      this.subMenuVisibility[id - 1] = false;
    }
  }

  processSelectedBuilding() {
    return this.commonDataService.selectedBuildingId ? 'block' : 'd-none';
  }

  processAvailability(index : number, status: any, selectedBuildingId: any): string{
    //console.log(this.commonDataService.selectedBuildingId,this._ViewerPermissionCheckerService.status);
    //this.processAnalyticsAvaliablility(selectedBuildingId);
    if(index == 1){
      return selectedBuildingId && status.hasFloorPlan ? 'block' : 'disabled';
    }
    if(index == 2){
      return selectedBuildingId && status.hasBim ? 'block' : 'disabled';
    }
    if(index == 3){
      return selectedBuildingId && status.hasGeneralViewer ? 'block' : 'disabled';
    }
    if(index == 4){
      return selectedBuildingId && status.hasMatterport ? 'block' : 'disabled';
    }
    if(index == 5){
      return selectedBuildingId && status.hasPointCloud ? 'block' : 'disabled';
    }
    if(index == 6){
      return selectedBuildingId && status.hasVideo ? 'block' : 'disabled';
    }
    if(index == 7){
      return selectedBuildingId && status.hasDocument ? 'block' : 'disabled';
    }
    return selectedBuildingId && status.hasExternalLink ? 'block' : 'disabled';
  }

  processAnalyticsAvaliablility(selectedBuildingId : any){
    // console.log(selectedBuildingId);
    this.sensorIntegrationsGraphProxyService.listLocalDevices(selectedBuildingId).subscribe(response => {
      var localDevices = response['body']['result'];
      //This is correct implementation, however for demo purporses, we have to comment this line.
      this.analyticsActive = localDevices.totalCount > 0;
      //this.analyticsActive = true;
    });
  }

  processCalendarView(){
    return typeof this.commonDataService.selectedBuildingId !== 'undefined';
  }

  ngOnInit() {
    this.loggedInUserInfo();
    this.getBuildVersion();
    // this._ViewerPermissionCheckerService.statusChange.subscribe(() =>{
    //   this.status = this._ViewerPermissionCheckerService.status;
    // });
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)//Everytime there is a Refresh method called...
      )
      .subscribe(event => {
        this.clearAllSubMenus();
      });
  }

  ngAfterViewInit(){
    this.processAnalyticsAvaliablility(this.commonDataService.selectedBuildingId);
  }

  // forceViewerStatus(status : any, selectedBuildingId : any){
  //   var subMenuViewers = document.getElementById('subMenu-2') as HTMLElement | null;
  //   if(!subMenuViewers){
  //     return;
  //   }
  //   var docs = document.getElementById('docs') as HTMLElement | null;
  //   var tools = document.getElementById('tools') as HTMLElement | null;
  //   for(let i=0; i<subMenuViewers!.children.length; i++){
  //     subMenuViewers!.children[i].children[0].classList.remove('disabled');
  //     subMenuViewers!.children[i].children[0].classList.add(this.processAvailability((i+1), status, selectedBuildingId));
  //     subMenuViewers!.children[i].children[0].setAttribute("href",`${selectedBuildingId}/viewer/${this.subMenuLinks[i]}`); 
  //   }
  //   docs!.classList.remove('disabled');
  //   docs!.classList.add(this.processAvailability(7,status,selectedBuildingId));
  //   docs!.setAttribute("href",`${selectedBuildingId}/${this.subMenuLinks[6]}`);

  //   tools!.classList.remove('disabled');
  //   tools!.classList.add(this.processAvailability(8, status, selectedBuildingId));
  //   tools!.setAttribute("href",`${selectedBuildingId}/${this.subMenuLinks[7]}`);
  // }

  logout() {
    this.authService.removeToken();
    this.router.navigate(['login']);
    this.commonDataService.selectedProject = null;
  }

  loggedInUserInfo() {
      this.loginService.loggedInUserInfo().subscribe( {
        next: (response) => {
          response = response['body'];
          this.fullName = response['result']['fullName'];
          this.usermailAddress = response['result']['emailAddress'];
        },
        error: (error) => {
          this.router.navigate(['login']);
        },
        complete: () => { }
      });
  }

  closeSubmenu(){
    MainJS.closeSubmenu();
  }

  getBuildVersion(){
    this.apicallservice.getVersion().subscribe({
      next: (response) => {
        if(response['result']){
          this.buildVersion = response['result'];
        }
      },
      error: (error) => { },
      complete: () => { }
    });
  }

  refreshPage(categoryName: string){
    //Re-write for TS
    window.location.href = this.commonDataService.selectedBuildingId + '/viewer/' + categoryName;
  }

  gotoadmin(){
    let url = environment.admin_url + '/account/login?email=' + this.usermailAddress;
    window.open(url);
  }
}
