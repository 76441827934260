import { Injectable } from "@angular/core";
import {  HttpClient,  HttpHeaders } from "@angular/common/http";
import * as moment from 'moment';
import { ProxyClientService } from "../proxies/proxy-client.service";

@Injectable({
  providedIn: 'root'
})

export class APICallService {
  private baseURL : string = '';
  private getOptions_ : any;
  private postOptions_ : any;

  constructor(private httpClient: HttpClient, private proxyClientService: ProxyClientService) {
    this.baseURL = this.proxyClientService.baseURL;
    this.getOptions_ = this.proxyClientService.getOptions_;
    this.postOptions_ = this.proxyClientService.postOptions_;
  }

  getData() {
    return this.httpClient.get(this.baseURL+"/api/sensorReading", this.getOptions_);
  }
  sensorReadingsbySensorId(sensorId : any) {
    this.getOptions_['withCredentials'] = true;
    // return this.httpClient.get(this.baseURL+"/api/sensorReadingsbySensorId/" + sensorId, {
    //   withCredentials: true
    // });
    return this.httpClient.get(this.baseURL+"/api/sensorReadingsbySensorId/" + sensorId, this.getOptions_);
  }
  getAvailableSensors(id : any) {
    this.getOptions_['withCredentials'] = true;
    //return this.httpClient.get(this.baseURL+"/api/sensor/getavailablesensors/instanceFile/" + id, { withCredentials: true });
    return this.httpClient.get(this.baseURL+"/api/sensor/getavailablesensors/instanceFile/" + id, this.getOptions_);
  }

  getSensorPOIMappings() {
    this.getOptions_['withCredentials'] = true;
    // return this.httpClient.get(this.baseURL+"/api/poisensormapping", {
    //   withCredentials: true
    // });
    return this.httpClient.get(this.baseURL+"/api/poisensormapping", this.getOptions_);
  }
  createPoi(createPoiData : any) {
    this.postOptions_['withCredentials'] = true;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json"
    //   }),
    //   withCredentials: true
    // };
    //return this.httpClient.post(this.baseURL+"/api/poi", createPoiData, httpOptions);
    return this.httpClient.post(this.baseURL+"/api/poi", createPoiData, this.postOptions_);
  }

  getPOIbyId(id : any) {
    this.getOptions_['withCredentials'] = true;
    //return this.httpClient.get(this.baseURL+"/api/poi/" + id, { withCredentials: true });
    return this.httpClient.get(this.baseURL+"/api/poi/" + id, this.getOptions_);
  }

  getSensorsByPOIId(poiId : any) {
    this.getOptions_['withCredentials'] = true;
    // return this.httpClient.get(this.baseURL+"/api/sensor/getSensorByPOIId/" + poiId, {
    //   withCredentials: true
    // });
    return this.httpClient.get(this.baseURL+"/api/sensor/getSensorByPOIId/" + poiId, this.getOptions_);
  }

  getAllPois() {
    this.getOptions_['withCredentials'] = true;
    let MaxResultCount = 1000;
    // return this.httpClient.get(this.baseURL+"/api/poi?MaxResultCount=" + MaxResultCount, {
    //   withCredentials: true
    // });
    return this.httpClient.get(this.baseURL+"/api/poi?MaxResultCount=" + MaxResultCount, this.getOptions_);
  }

  getVersion() {
    return this.httpClient.get(this.baseURL+"/api/version/");
  }

  getSideBarAllPois(value : any) {
    this.getOptions_['withCredentials'] = true;
    // return this.httpClient.get(this.baseURL+
    //   "/api/poi/Instancefile/" + value.InstanceFileId,
    //   { withCredentials: true }
    // );
    return this.httpClient.get(this.baseURL+"/api/poi/Instancefile/" + value.InstanceFileId,this.getOptions_);
  }

  getPoiData(value : any) {
    return this.httpClient.get(this.baseURL+"/api/poi/" + value, this.getOptions_);
  }

  getSensorInfo(value : any) {
    return this.httpClient.get(this.baseURL+"/api/sensor/getSensorByPOIId/" + value, this.getOptions_);
  }

  getSensorlatestReading(value : any) {
    return this.httpClient.get(this.baseURL+"/api/getLatestReading/" + value, this.getOptions_);
  }

  getavaiableSensorsonPoi(poiId : any, fileId : any){
    return this.httpClient.get(this.baseURL+"/api/sensor/getSensorByMirisPOIId/" +poiId +"/" + fileId, this.getOptions_);
  }

  AddSensorToPOI(value : any){
    return this.httpClient.post(this.baseURL+"/api/sensor/AddSensorToPOI",value, this.getOptions_);
  }

  getFileInfo(id : any){
    return this.httpClient.get(this.baseURL+'/api/viewer/' + id, this.getOptions_);
  }

  deleteFileById(id : any){
    return this.httpClient.delete(this.baseURL+'/api/viewer/' + id, this.postOptions_);
  }

  getTokenForBim360(){
    return this.httpClient.get(this.baseURL+'/api/getbim360token', this.getOptions_);
  }

  getAllByInstanceFileId(id : any) {
    return this.httpClient.get(this.baseURL+'/api/poi/Instancefile/' + id, this.getOptions_);
  }

  getAllAvailablePoisByInstanceFileId(id : any) {
    return this.httpClient.get(this.baseURL+'/api/poi/Instancefile/availablepoi/' + id, this.getOptions_);
  }

  mapPoiAndUnit(poiId : any, unitId : any, buildingId : any){
    var POI_AreaMapping={"FkPOI_Id":poiId,"FkAreaId":unitId, "BuildingId": buildingId}
    return this.httpClient.post(this.baseURL+"/api/poiUnitMapping",POI_AreaMapping, this.postOptions_);
  }

  unmapPoiAndUnit(poiId : any, unitId : any, buildingId : any){
    var POI_AreaMapping={"FkPOI_Id":poiId,"FkAreaId":unitId, "BuildingId": buildingId}
    return this.httpClient.post(this.baseURL+"/api/poiarea/unmap",POI_AreaMapping, this.postOptions_);
  }

  getAllHavingSensorByInstanceFileId(id : any) {
    return this.httpClient.get(this.baseURL+'/api/poihavingsensors/Instancefile/' + id, this.getOptions_);
  }

  getAllSensors(poiId : any){
    return this.httpClient.get(this.baseURL+'/api/sensor/getSensorByPOIId/'+poiId, this.getOptions_);
  }
  getLatestReadingBySensorId(poiId : any){
    return this.httpClient.get(this.baseURL+'/api/getLatestReading/'+poiId, this.getOptions_);
  }

  getSensorReadings(period: string, sensorId: number, date: any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/' + period + '/' + sensorId + '/' + date, this.getOptions_);
  }

    getSensorReadingDay(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/day/' + value.id + '/' + value.startDate, this.getOptions_);
  }

  getSensorReadingForMonthAllDays(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/month/alldays/' + value.id + '/' + value.date, this.getOptions_);
  }

  getSensorReadingForMonthWeekends(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/month/weekends/' + value.id + '/' + value.date, this.getOptions_);
  }

  getSensorReadingForMonthWorkDays(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/month/weekdays/' + value.id + '/' + value.date, this.getOptions_);
  }

  getSensorReadingForYear(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/year/overview/'+ value.id + '/' + value.year, this.getOptions_);
  }

  getSensorReadingForMonthOverview(value : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/month/overview/' + value.id + '/' + value.date, this.getOptions_);
  }

  getWeeklySensorData(sensor_id : any, date : any){
    return this.httpClient.get(this.baseURL+'/api/sensorreading/Weekly/' + sensor_id + '/' + date, this.getOptions_);
  }

  getSensorRangeOverview(poiId: Number, fileId: Number) {
    return this.httpClient.get(this.baseURL+'/api/sensorrange/overview/' + poiId + '/' + fileId, this.getOptions_);
  }

  getSelectedSensorInfo(sensor_id : any,file_id : any){
    return this.httpClient.get(this.baseURL+'/api/sensor/getAttachedSensorsByMirisPOIId/' +  sensor_id + '/' + file_id, this.getOptions_);
  }

  getShowMoreSensorInfo(id : any){
    return this.httpClient.get(this.baseURL+'/api/sensor/' + id, this.getOptions_);
  }

  getBuildings(){
    return this.httpClient.get(this.baseURL+'',this.getOptions_);
  }

  getParticularBuilding(id : any){
    return this.httpClient.get(this.baseURL+'/api/building/'+ id, this.getOptions_);
  }

  getFilesFrom(id : any){
    return this.httpClient.get(this.baseURL+'' + id, this.getOptions_);
  }

  _getBuildingInfo(){
    return this.httpClient.get(this.baseURL+'/api/building/user', this.getOptions_);
  }

  getBuildingInfo(){
    return this.httpClient.get(this.baseURL+'/api/assignedbuildings/', this.getOptions_);
  }

  getFileCategories(){
    return this.httpClient.get(this.baseURL+'/api/viewCategory', this.getOptions_);
  }

  getFilesById(id: any) {
    return this.httpClient.get(this.baseURL+'/api/viewer/'+ id, this.getOptions_);
  }
 
  getfilesByCategory(building_id : any,fileCategory : any) {
    return this.httpClient.get(this.baseURL+'/api/viewer/'+building_id+'/'+fileCategory, this.getOptions_);
  }

  getIOTGatewaysByBuildingId(building_id : any) {
    return this.httpClient.get(this.baseURL+'/api/iotgateway/GetByBuilding/' + building_id, this.getOptions_);
  }

  getIOTBuildingsByMirisBuildingId(building_id : any) {
    return this.httpClient.get(this.baseURL+'/api/iotgateway/GetIotBuildingsByBuildingId/' + building_id, this.getOptions_);
  }

  getIOTGatewayInfoById(IOTGatewayId : any) {
    return this.httpClient.get(this.baseURL+'/api/iotgateway/' + IOTGatewayId, this.getOptions_);
  }

  getIOTGatewaysBuildingByGatewayId(value : any) {
    return this.httpClient.post(this.baseURL+'/api/iotgateway/building',value, this.postOptions_);
  }

  getUnitsByIotBuildingIdAndGatewayId(iotBuildingId : any,gatewayId : any, buildingId : any){
    // /api/iotgateway/{gatewayId}/UnitsByIotBuildingId/{iotBuildingId}
    return this.httpClient.get(this.baseURL+'/api/iotgateway/' + gatewayId + '/UnitsByIotBuildingId/' + iotBuildingId + '/BuildingId/' + buildingId, this.getOptions_);
  }
  getLatestIndoorMeasurementsBy(iotGatewayId : any, unitId : any){
    //api/sensorreading/iotgateway/{iotGatewayId}/unit/{unitId}
    return this.httpClient.get(this.baseURL+'/api/sensorreading/iotgateway/' + iotGatewayId + '/unit/' + unitId, this.getOptions_);
  }

  getfilesByBuilding(building_id : any) {
    return this.httpClient.get(this.baseURL+'/api/instancefile/building/'+building_id, this.getOptions_);
  }

  uploadFile(formData : any){
    this.postOptions_['headers']['Content-Type'] = 'multipart/form-data';
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'multipart/form-data');
    //return this.httpClient.post(this.baseURL+'/api/viewer/fileupload' ,formData,{ headers: headers });
    return this.httpClient.post(this.baseURL+'/api/viewer/fileupload' ,formData, this.postOptions_);
  }

  // getUploadSignedUrl(file: any):Observable<any> {
  getUploadSignedUrl(file: any){
    this.postOptions_['headers']['Content-Type'] = 'multipart/form-data';
    // let url_ = this.baseUrl + "/api/fileuploadurl"
    // url_ = url_.replace(/[?&]$/, "");
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'multipart/form-data');
    // return this.http.post(url_,file,{headers : headers});
    //return this.httpClient.post(this.baseURL+'/api/fileuploadurl',file,{headers : headers});
    return this.httpClient.post(this.baseURL+'/api/fileuploadurl',file, this.postOptions_);
  } 

  updateUploadStatus(status:any){
    this.postOptions_['headers']['Content-Type'] = 'application/json';
    // let url_ = this.baseUrl + "/api/updateuploadedfiledata"
    // url_ = url_.replace(/[?&]$/, "");
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');
    // return this.http.post(url_,status,{headers : headers});
    //return this.httpClient.post(this.baseURL+'/api/updateuploadedfiledata',status,{headers : headers});
    return this.httpClient.post(this.baseURL+'/api/updateuploadedfiledata',status, this.postOptions_);
  } 

  getFileData(buildingId : any){
    return this.httpClient.get(this.baseURL+'/api/buildingfiles/' + buildingId, this.getOptions_);
  }

  getFile(id : any){
    return this.httpClient.get(this.baseURL+'/api/buildingfiledetails/' + id, this.getOptions_);
  }
  getdronebase64(id : any){
    return this.httpClient.get(this.baseURL+'/api/buildingImagefilebase64/' + id, this.getOptions_);
  }

  getById(id : any) {
    return this.httpClient.get(this.baseURL+'/api/poi/' + id, this.getOptions_);
  }

  deletePoiByMirisPoiId(poiId : any){
    return this.httpClient.delete(this.baseURL+'/api/poi/mirispoiId/' + poiId, this.postOptions_);
  }

  getPoiMeasurements(poiId : any, fileId : any, startDate : any, endDate : any, resolution : any) {
    const dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
    const dateEnd = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

    return this.httpClient.get(this.baseURL+'/api/sensorreading/poi/' + poiId + "/file/" + fileId + "/" +dateStart + "/" + dateEnd + "/" + resolution, this.getOptions_);
    
  }

  _getPoiMeasurements(gatewayId : any, unitId : any, startDate : any, endDate : any, resolution : any) {
    const dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
    const dateEnd = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
///api/sensorreading/iotgateway/{iotGatewayId}/unit/{unitId}/{startDate}/{endDate}/{resolution}
    return this.httpClient.get(this.baseURL+'/api/sensorreading/iotgateway/' + gatewayId + "/unit/" + unitId + "/" +dateStart + "/" + dateEnd + "/" + resolution, 
    this.getOptions_);
    
  }

  getLatestIndoorMeasurement(poiId : any, fileId : any) {
    return this.httpClient.get(this.baseURL+'/api/sensorreading/poi/latest/' + poiId + "/file/" + fileId, this.getOptions_);
  }

  getSensorConfigs() {
    return this.httpClient.get(this.baseURL+'/api/sensorconfig', this.getOptions_);
  }

  setStatusforCustomPopup(buildingId : any, poiId : any,fileId : any, status : any){
    return this.httpClient.put(this.baseURL+'/api/poi/updatepoistatus/' + buildingId + '/' + poiId + '/' + fileId + '/' + status, this.postOptions_);
  }

}
