<div class="navbar-horizontal-master-container">
        <header>
                <a class="home-link" href="home">
                <img class="logo-navbar" src="../../../assets/images/cc310x.png"/>
                </a>
                <nav class="navbar-project">
                        <div class="navbar-item">
                                <a class="navbar-anchor" (click)="toggleSubMenu(1)">
                                <span>{{commonDataService.selectedProject ? commonDataService.selectedProject.name : "Project List"}}</span>&nbsp;
                                <i class="fa-solid fa-angle-down down-arrow"
                                [ngClass]="this.commonDataService.availableBuildings.length > 0 ? '' : 'display: none'"></i>
                                </a>
                                <div class="navbar-submenu" id="subMenu-1">
                                        <div class="navbar-submenu-item"  *ngFor="let building of commonDataService.availableBuildings">
                                                <a class="navbar-anchor" [routerLink]="['','home', building.id]" [ngClass]="{'active' : (commonDataService.selectedBuildingId == building.id)}"title="{{building.name}}">
                                                        {{building.name}}
                                                </a>
                                        </div>
                                </div>
                        </div>
                </nav>
                <nav class="navbar-horizontal-links">
                        <div class="navbar-item">
                                <a class="navbar-anchor" 
                                [ngClass]="processSelectedBuilding()"
                                (click)="toggleSubMenu(2)">
                                <span>Project Viewer</span>&nbsp;<i class="fa-solid fa-angle-down down-arrow"></i>
                                </a>
                                <div class="navbar-submenu" id="subMenu-2">
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(1,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'FloorPlan']"
                                                >
                                                        2D DRAWINGS
                                                </a>
                                        </div>
                                        <!-- <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(1,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'RoomSpecifications']"
                                                >
                                                        ROOM SPECIFICATIONS
                                                </a>
                                        </div> -->
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(2,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'bimviewer']"
                                                >
                                                        3D BIM Model
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(3,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'building-view']"
                                                >
                                                        GENERAL VIEWER
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(4,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'matterport']"
                                                >
                                                        MATTERPORT
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(5,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'outdoor-view']"
                                                >
                                                        POINT CLOUD
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(6,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'drone']">
                                                        VIDEOS
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                routerLinkActive="active"
                                                [ngClass]="processAvailability(1,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'alarms']"
                                                >
                                                        ALARMS
                                                </a>
                                        </div>
                                </div>
                        </div>
                        <div class="navbar-item">
                                <a class="navbar-anchor"
                                routerLinkActive="active"
                                [ngClass]="processAvailability(1,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'viewer', 'RoomSpecifications']"
                                >
                                        <span>Building Analytics</span>
                                </a>
                        </div>
                        <div class="navbar-item">
                                <a class="navbar-anchor"
                                [ngClass]="processCalendarView() ? '' : 'disabled'"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'calendar']"
                                routerLinkActive="active">
                                        <span>Calendar</span>
                                </a>
                        </div>
                        <div class="navbar-item">
                                <a class="navbar-anchor"
                                [ngClass]="processCalendarView() ? '' : 'disabled'"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'video-call']"
                                routerLinkActive="active">
                                        <span>Video Call</span>
                                </a>
                        </div>
                        <!-- <div class="navbar-item">
                                <a class="navbar-anchor"
                                [ngClass]="analyticsActive ? '' : 'disabled'"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'sensor-integration']"
                                routerLinkActive="active">
                                        <span>Analytics</span>
                                </a>
                        </div> -->
                        <div class="navbar-item">
                                <a class="navbar-anchor" (click)="toggleSubMenu(3)"
                                [ngClass]="commonDataService.selectedBuildingId ? 'd-block nav-link p-nav dropdown-toggle' : 'd-none'"
                                routerLinkActive="active">
                                        <span>IoT</span>&nbsp;<i class="fa-solid fa-angle-down down-arrow"></i>
                                </a>
                                <div class="navbar-submenu iot" id="subMenu-3">
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'indoor-climate']"
                                                routerLinkActive="active">
                                                        INDOOR CLIMATE
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'people']"
                                                routerLinkActive="active">
                                                        PEOPLE
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'energy']"
                                                routerLinkActive="active">
                                                        ENERGY
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'building-access']"
                                                routerLinkActive="active">
                                                        BUILDING ACCESS
                                                </a>
                                        </div>
                                        <div class="navbar-submenu-item">
                                                <a class="navbar-anchor"
                                                [routerLink]="['', commonDataService.selectedBuildingId, 'point-management']"
                                                routerLinkActive="active">
                                                        POINT MANAGEMENT
                                                </a>
                                        </div>
                                </div>
                        </div>
                        <div class="navbar-item">
                                <a class="navbar-anchor"
                                routerLinkActive="active"
                                id="docs"
                                [ngClass]="processAvailability(7,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'documents']"
                                >
                                        <span>Documents</span>
                                </a>
                        </div>
                        <div class="navbar-item">
                                <a class="navbar-anchor"
                                routerLinkActive="active"
                                id="tools"
                                [ngClass]="processAvailability(8,_ViewerPermissionCheckerService.status,commonDataService.selectedBuildingId)"
                                [routerLink]="['', commonDataService.selectedBuildingId, 'externallink']"
                                >
                                        <span>Tools</span>
                                </a>
                        </div>
                </nav>
                <nav class="navbar-user-profile">
                        <img class="profile-picture" src="../../../assets/images/user.png"/>
                        <div class="navbar-item">
                                <a class="navbar-anchor" (click)="toggleSubMenu(4)">
                                        <span>{{fullName}}</span>&nbsp;<i class="fa-solid fa-angle-down down-arrow"></i>
                                </a>
                                <div class="navbar-submenu submenu-last" id="subMenu-4">
                                        <div class="navbar-submenu-item"><a class="navbar-anchor" (click)="gotoadmin()" href="javascript:void(0);">GO TO ADMIN PORTAL</a></div>
                                        <div class="navbar-submenu-item"><a class="navbar-anchor" (click)="logout()" href="javascript:void(0);">LOGOUT</a></div>
                                        <p class="navbar-submenu-item grayed-out-text">
                                                <small><b>Version</b> - {{buildVersion}}</small>
                                        </p>
                                </div>
                        </div>
                
                </nav>
        </header>
</div>