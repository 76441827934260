import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class GuardService {
}
@Injectable()
export class LogInAuthenticiate {
  constructor(private http: HttpClient) { }
  async isLoggedIn(): Promise<boolean> {
    let isexpire: boolean = true;
    const helper  = new JwtHelperService();
    let user: any = window.localStorage.getItem('token');
    if (user !== undefined) {
      isexpire = await Promise.resolve(helper.isTokenExpired(user));
    }
    if (isexpire) {
      return false;
    }
    else {
      return true;
    }
  }
}

@Injectable()
export class AlwaysAuthGuard  {
  constructor(private logInAuthenticiate: LogInAuthenticiate, private router: Router, ) { };
  async canActivate() {
    var result = await Promise.resolve(this.logInAuthenticiate.isLoggedIn());

    if (result) {
      return true;
    }

    this.router.navigate(['login']);
    return;
  }
}