import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralViewerProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listBuildingGeneralViewer(buildingId: number) {
      const path = `${this.proxyClientService.baseURL}/api/web/generalviewer/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingGeneralViewerViewer(buildingGeneralViewerId: number) {
    const path = `${this.proxyClientService.baseURL}/api/web/generalviewer/viewer/${buildingGeneralViewerId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
  
}