import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonDataService } from '../../service/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { VideoProxyService } from '../../proxies/video-proxy.service';
import { BuildingSingleFileWebListableDto } from '../../../shared/models/base/building-single-file-web-listable-dto';

declare var MainJS : any;

@Component({
  selector: 'app-drone',
  templateUrl: './drone.component.html',
  styleUrls: ['./drone.component.scss']
})

export class DroneComponent implements OnInit {

  public videos: BuildingSingleFileWebListableDto[];
  public buildingVideoId: number;
  public imageViewerUrl: any;
  public googleViewerUrl: any;
  public videoViewerUrl: any;
  public inProgress: boolean = false;
  public loading: boolean = true

  constructor(
    private router:Router, 
    private domSanitizer: DomSanitizer,
    private activateRoute:ActivatedRoute, 
    public commonDataService:CommonDataService,
    private videoProxyService: VideoProxyService,
    private toastrService: ToastrService
    ) { }


  onLoad() {
      this.loading = false;
  }

  ngOnInit() {
    this.commonDataService.downloadLink = null;

    this.inProgress = true;

    this.activateRoute.params.subscribe(routeParams => {

      this.loading = true;

        this.videoProxyService.listBuildingVideo(Number.parseInt(this.commonDataService.selectedBuildingId!))
        .subscribe((response) => {
          response = response['body']['result'];
          this.videos = response['items'];

          if(routeParams['file_id'])
          {
            this.loadDroneFile(+routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0) {
              const first: BuildingSingleFileWebListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/drone/" + first.id]);
            }
          }
          this.inProgress = false;
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId]);
        
        })
    });
  }

  private hideViewers() {
    this.googleViewerUrl = null;
    this.imageViewerUrl = null;
    this.videoViewerUrl = null;
  }

  private showVideoViewer(uri: string) {
    this.hideViewers();

    //this.videoViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
    this.videoViewerUrl = uri;
   // console.log(this.videoViewerUrl);
  }

  private showImageViewer(uri: string) {
    this.hideViewers();

    //this.imageViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
    this.imageViewerUrl = uri;
    console.log(this.imageViewerUrl);
  }

  private showGoogleViewer(uri: string) {
    this.hideViewers();

    const googleUri: string = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(uri)}&embedded=true`;

    this.googleViewerUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(googleUri);
    console.log(this.googleViewerUrl);
  }
  
  private loadDroneFile(buildingVideoId: number){

    this.buildingVideoId = buildingVideoId;

    this.videoProxyService.getBuildingVideoViewer(buildingVideoId).subscribe((response) => { 
      response = response['body']['result'];
      switch(response['fileExtension'].toLowerCase()) {
        case 'mov':
          this.showVideoViewer(response['viewerUri']);
          break;
        case 'jpg':
        case 'jpeg':
          this.showImageViewer(response['viewerUri']);
          break;
        default:
          this.showImageViewer(response['viewerUri']);
          break;
      }

    });
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }

}
