<!-- <div [id]="localCallId" class="local"></div>
<div *ngFor="let callId of remoteCalls" [id]="callId" class="remote"></div> -->
<!-- <body>
    <div class="container">
        <video autoplay  id="video" width="480" height="480" autoplay></video>
        <video width="480" height="480" autoplay></video>
        <video width="480" height="480" autoplay></video>
    </div>
    <script type="text/javascript">
        
      </script>
</body> -->
<!-- <div class="video-call-wrapper">
    <div class="outgoing-video-position-relative">
        <div class="incoming-video position-absolute"></div>
        <div class="d-flex justify-content-center align-items-center gap-1 position-absolute" id="control-buttons">

            <button type="button" (click)="joinCall()" class="control-button-item" *ngIf="!callJoined" id="call-join"> -->
                <!-- <i class="fa-solid fa-phone"></i> -->
                <!-- <span class="join-call-text">Join</span>
            </button>

            <button type="button" (click)="mute()" class="control-button-item" *ngIf="callJoined">
                <i class="fa-solid fa-microphone" *ngIf="micIcon"></i>
                <i class="fa-solid fa-microphone-slash" *ngIf="muteIcon"></i>
            </button>

            <button type="button" (click)="videoOnOff()" class="control-button-item" *ngIf="callJoined">
                <i class="fa-solid fa-video" *ngIf="videoIcon"></i>
                <i class="fa-solid fa-video-slash" *ngIf="videoOffIcon"></i>
            </button>

            <button type="button" (click)="leaveCall()" class="control-button-item" *ngIf="callJoined" id="call-leave">
                <i class="fa-solid fa-phone"></i>
            </button>

        </div>
    </div>
</div> -->
<iframe [src]="meetURL" class="customVideoFrame" scrolling="yes" allow="autoplay; camera; microphone"></iframe>