import { Component, OnInit,ElementRef } from '@angular/core';
import { CommonDataService } from '../../service/common-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConst } from '../AppConst';

declare var MainJS: any;

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})

export class SubHeaderComponent implements OnInit {
  viewerUrl = '';
  fileIdfromUrl :any;
  constructor(public commonDataService: CommonDataService, private activateRoute: ActivatedRoute,
    private router: Router,private cdr: ElementRef) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(routeParams => {
    this.fileIdfromUrl = this.activateRoute.snapshot.params['file_id'];
    });
    const url =  window.location.pathname.split('/');
    let viewerIndex =  url.findIndex( p => p === 'viewer');
    if(viewerIndex > 0){
      this.viewerUrl = url[3];
    }else {
      this.viewerUrl = url[2];
    }
  }

  setSliceForFiles() {
      let innerwidth = window.innerWidth * 3 / 4;
      let remaingcount = innerwidth;
      for(let  i = 0; i < this.commonDataService.selectedViewerFiles.length;i++){
        let count = this.commonDataService.selectedViewerFiles[i]['name'].length;
        remaingcount = remaingcount  - count * 10;
        if(remaingcount <= 0){
            return i;
        }
      }
      return remaingcount;
    }
    openDropdown(event : any){
      event.stopPropagation();
      MainJS.extraMenu();
    }

    refreshPage(fileId : any){
      this.fileIdfromUrl = fileId;
      if(this.viewerUrl === AppConst.outdoorview){
        const url: any = window.location.href + this.commonDataService.selectedBuildingId + '/viewer/outdoor-view/' + fileId;
        this.router.navigate(url);
      } else if(this.viewerUrl === AppConst.externallink){
        // this.router.navigateByUrl(this.commonDataService.selectedBuildingId + '/externallink/' + fileId);
        this.router.navigate([this.commonDataService.selectedBuildingId + '/externallink/' + fileId]);
      } else if(this.viewerUrl === AppConst.sensorIntegrationGraph){
        // this.router.navigate( '/IndoorAirAnalysis/file/' + fileId);
        this.router.navigate([this.commonDataService.selectedBuildingId + "/sensor-integrations/file/" + fileId])
      } else if(this.viewerUrl === AppConst.tracking){
        // this.router.navigateByUrl('/tracking/' + fileId);
        this.router.navigate([this.commonDataService.selectedBuildingId + "/tracking/" + fileId])
      }
    }

    closeSubmenu(){
      MainJS.closeSubmenu();
    }
}
