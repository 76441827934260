import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkProxyService {

  constructor(private client: HttpClient,private proxyClientService: ProxyClientService) { }

  public listBuildingExternalLink(buildingId: number){
      const path = `${this.proxyClientService.baseURL}/api/web/externallink/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingExternalLinkViewer(buildingExternalLinkId: number){
    const path = `${this.proxyClientService.baseURL}/api/web/externallink/viewer/${buildingExternalLinkId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
}