import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProxyClientService } from '../proxies/proxy-client.service';
import { LocalStorageService } from '../utils/LocalStroageService';
import { environment } from 'src/environments/environment';
// import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
// import { LoggedInUserDto } from 'src/shared/models/login/LoggedInUserDto';
// import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
// import { BlobHelper } from 'src/shared/helpers/BlobHelper';
// import { ExceptionHandler } from 'src/shared/exception/ExceptionHandler';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private baseURL : string = '';
  //protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  private getOptions_ : any = {};
  private postOptions_ : any = {};

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { 
    this.baseURL = environment.appBaseUrl;
    var token = this.localStorageService.getItem('token');
    if(token){
     token = token.replace(/['"]+/g, '');
     this.getOptions_ = {
      observe: "response",
      responseType: "json",
      headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain",
                "Authorization": `Bearer ${token}`
            })
      };
    }
    }

  loginUser(user : any) {
    return this.http.post(this.baseURL+'/api/TokenAuth/Authenticate', user);
  }

  registerUser(user : any) {
    return this.http.post(this.baseURL+'/api/account', user);
  }

  socialLogin(app : any, redirectUri : any) {
    return this.http.get(this.baseURL+'/api/TokenAuth/ExternalAuthenticateProvider?provider=' + app
    + '&returnUrl=' + redirectUri);
  }

  checkEmailConfirm(value : any) {
    return this.http.post(this.baseURL+'/api/verifyforgotpasswordlink',value);
  }

  sendEmailConfirm(value : any){
    return this.http.post(this.baseURL+'/api/forgotpassword',value);
  }

  loggedInUserInfo(){
    return this.http.get(this.baseURL+'/api/services/app/User/LoggedInUser', this.getOptions_);
  }
  // loggedInUserInfo(){    
  //   return this.http.request("get", `${this.baseURL}/api/services/app/User/LoggedInUser`, this.getOptions_).pipe(_observableMergeMap((response_: any) => {
  //       return this.processLoggedInUser(response_);
  //   })).pipe(_observableCatch((response_: any) => {
  //       if (response_ instanceof HttpResponseBase) {
  //           try {
  //               return this.processLoggedInUser(response_ as any);
  //           } catch (e) {
  //               return _observableThrow(e) as any as Observable<LoggedInUserDto>;
  //           }
  //       } else
  //           return _observableThrow(response_) as any as Observable<LoggedInUserDto>;
  //   }));
  //   //return this.http.get(this.baseURL + '/api/services/app/User/LoggedInUser', options);
  // }

  // protected processLoggedInUser(response: HttpResponseBase): Observable<LoggedInUserDto> {
  //   const status = response.status;
  //   const responseBlob =
  //       response instanceof HttpResponse ? response.body :
  //           (response as any).error instanceof Blob ? (response as any).error : undefined;

  //   let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
  //   if (status === 200) {
  //       return BlobHelper.blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
  //           let result200: any = null;
  //           let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
  //           result200 = LoggedInUserDto.fromJS(resultData200);
  //           return _observableOf(result200);
  //       }));
  //   } else if (status !== 200 && status !== 204) {
  //       return BlobHelper.blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
  //           return ExceptionHandler.throwException("An unexpected server error occurred.", status, _responseText, _headers);
  //       }));
  //   }
  //   return _observableOf(null as any);
  // }

}
