import { Component, OnInit } from '@angular/core';
import { CommonDataService } from "../../service/common-data.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BimProxyService } from '../../proxies/bim-proxy.service';
import { BuildingSingleFileWebListableDto } from '../../../shared/models/base/building-single-file-web-listable-dto';
import { PagedResultDto } from '../../../shared/models/paged-result-dto';
import { BuildingBimViewerDto } from '../../../shared/models/bim/building-bim-viewer-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './bim360-view.component.html'
})
export class Bim360ViewComponent implements OnInit {
  public bims: BuildingSingleFileWebListableDto[];
  public selectedBuildingBimId: number;
  public forgeUri: any;

  loading: boolean = true
  onLoad() {
      this.loading = false;
  }

  file_id : any;
  inProgress = true;
  viewerLink = null;
  fileContentType = null;

  constructor(  
    private router: Router,
    public commonDataService: CommonDataService, 
    private activateRoute: ActivatedRoute,
    private bimProxyService: BimProxyService,
    private domSanitizer: DomSanitizer,
    private toastrService: ToastrService
    ) {}

  public ngOnInit() {
    this.inProgress = true;
    this.activateRoute.params.subscribe(routeParams => {
      this.file_id = this.activateRoute.snapshot.paramMap.get('file_id');
        this.bimProxyService.listBuildingBim(Number.parseInt(this.commonDataService.selectedBuildingId!)).subscribe((response) => {
          response = response['body']['result'];
          this.bims = response['items'];

          this.loading = true

          if(routeParams['file_id'])
          {
            this.loadViewer(+routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0)
            {
              const first: BuildingSingleFileWebListableDto = response['items'][0];
              this.router.navigate([this.commonDataService.selectedBuildingId + "/viewer/bimviewer/" + first.id]); 
            }
              this.inProgress = false;
          }
          
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/home/" + this.commonDataService.selectedBuildingId]);
        })
      // }
    });
  }

  private loadViewer(buildingBimId: number){
    this.bimProxyService.getBuildingBimViewer(buildingBimId).subscribe((response) => {
        this.selectedBuildingBimId = buildingBimId;
        response = response['body']['result'];
        const paramaters: string = btoa(JSON.stringify(response));

        const uri: string = `./forge-viewer/${paramaters}`;

        this.forgeUri = null;
        this.forgeUri = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);

        this.inProgress = false;
      },
      error => {
        this.inProgress = false;
        //if file id mentioned in url not found
        {
          this.router.navigate([
            this.commonDataService.selectedBuildingId + '/viewer/bimviewer'
          ]);
        }
      }
    );
  }
}
