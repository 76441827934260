<!--header-->
<app-header></app-header>

<!--left sidebar-->
<app-sidebar></app-sidebar>

<!-- right  menu-->
<app-left-side-menu></app-left-side-menu>

<!--header end bar-->
<router-outlet></router-outlet>