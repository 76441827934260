import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorDto } from '../../shared/models/ErrorDto';
import { ResultDto } from '../../shared/models/ResultDto';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../utils/LocalStroageService';

// Helper service to wrap all api calls in ResultDto<T> and then unwrap them.
// Also adds the environment.base_url to app api calls.
@Injectable({
  providedIn: 'root'
})

export class ProxyClientService {
  public baseURL : string = '';
  public getOptions_ : any;
  public postOptions_ : any;

  constructor(private client: HttpClient, private localStorageService: LocalStorageService) { 
    this.baseURL = environment.appBaseUrl;
    var token = this.localStorageService.getItem('token');
     token = token.replace(/['"]+/g, '');
     this.getOptions_ = {
      observe: "response",
      responseType: "json",
      headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                "Accept": "text/plain",
                "Authorization": `Bearer ${token}`
            })
      };
      this.postOptions_ = this.getOptions_;
  }

  private handleResult(result: ResultDto<any>): any {

    if(result && result.error) {
      const errorDto: ErrorDto = result.error;
      //abp.message.error(errorDto.details, errorDto.message);
    }

    return result.result;
  }

  private handleError(result : any): any {

    let message = "Something went wrong :(";

    if(result) {
      const error = result.error;

      if(error) {
        if(error instanceof ErrorEvent) {
          // client side error
          message = error.message;

          //abp.message.error(error.message, "Error");
        } else {
          // server side error
          const errorDto: ErrorDto = error.error;

          //abp.message.error(errorDto.details, errorDto.message);
        }
      }
    }

    return throwError(message);
  }

  private getUri(path: string): string {
    return path;
  }

  public get<T = any>(path: string) : Observable<T> {
    const uri = this.getUri(path);
    var result = this.client.get<ResultDto<T>>(uri).pipe(map(this.handleResult), catchError(this.handleError));
    return result;
  }

  public delete<T = any>(path: string) : Observable<T> {
    const uri = this.getUri(path);
    var result = this.client.delete<ResultDto<T>>(uri).pipe(map(this.handleResult), catchError(this.handleError));
    return result;
  }

  public post<T = any>(path: string, body: any) : Observable<T> {
    const uri = this.getUri(path);
    var result = this.client.post<ResultDto<T>>(uri, body).pipe(map(this.handleResult), catchError(this.handleError));
    return result;
  }

  public put<T = any>(path: string, body: any) : Observable<T> {
    const uri = this.getUri(path);
    var result = this.client.put<ResultDto<T>>(uri, body).pipe(map(this.handleResult), catchError(this.handleError));
    return result;
  }
}
