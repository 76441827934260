<div class="gray-container">
    <div class="container">
      <div class="row" style="cursor: default">
       <div class="col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 bg-white mt-110 p-4 pt-5">
         <div>
            <span style="position: absolute; margin-top:-29px; color: #36b8b9; font-size: 20px; ">Forgot Password</span>
         </div>
         <form style="padding-top: 40px;" [formGroup]="emailForm" (ngSubmit)="submit()">
             <div class="form-group label-floated">
                 <input type="text" id='l1' formControlName="username" class="form-control app-form-control rounded-0" [trim]="emailForm.controls['username'].value">
                 <label>Email Id/UserName</label>
                 <small class="d-block mt-1 text-danger text-left" *ngIf="emailForm.controls['username'].hasError('required') && emailForm.controls['username'].touched">
                  Email Id/UserName is required
                 </small>
                 <!-- <small class="text-danger" *ngIf="lForm.controls['username'].hasError('emailValid') && lForm.controls['username'].touched">
                                         Invalid Credentials
                                 </small> -->
             </div>
               <button class="btn btn-primary btn-block btn-lg"  [disabled]="disableSend" type="submit">Send</button>
         </form>
        
         <div class="alert alert-info d-block text-small w-100 mt-4" *ngIf="showMessage">
          
             {{this.message}}
           
         </div>
         <div class="pt-16"><a [routerLink]="['/login']"
                                  class="pull-right text-decoration-underline primary-link font-weight-700">Back</a></div>
       </div>
      </div>
   </div>
   
  
  </div>
  
  