import { Injectable } from "@angular/core";
import {  HttpClient,  HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AgoraAPICallService {
    
  public baseURL : string = '';
  public getOptions_ : any;
  public postOptions_ : any;

  constructor(private httpClient: HttpClient ) { }


    public CreateJWTToken(): Observable<any> {
       const path = environment.agora_base_url + "/v1/token/generate";
       
       this.postOptions_ = {
        observe: "response",
        responseType: "json",
        headers: new HttpHeaders({
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "X-API-KEY": environment.agora_api_key,
                  "X-Project-ID": environment.agora_project_id
              })
        };

        return this.httpClient.post<any>(path, {"user_id": "string"}, this.postOptions_);
    }

    public CreateMeetingChannel(title: string | undefined, token: string | undefined): Observable<any> {
      const path = environment.agora_base_url + "/v1/channel";
      
      this.postOptions_ = {
       observe: "response",
       responseType: "json",
       headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${token}`,
              "X-API-KEY": environment.agora_api_key,
              "X-Project-ID": environment.agora_project_id
          })
       };

       return this.httpClient.post<any>(path, {"title": title,"enable_pstn": false}, this.postOptions_);
   }

   public JoinMeetingChannel(passphrase: string | undefined, token: string | undefined): Observable<any> {
    const path = environment.agora_base_url + "/v1/channel/join/request";
    
    this.postOptions_ = {
     observe: "response",
     responseType: "json",
     headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "X-API-KEY": environment.agora_api_key,
            "X-Project-ID": environment.agora_project_id
        })
     };

     return this.httpClient.post<any>(path, {"passphrase":passphrase,"send_event":false}, this.postOptions_);
 }

   
}