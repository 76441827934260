import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})

export class MatterportProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listBuildingMatterport(buildingId: number) {
      const path = `${this.proxyClientService.baseURL}/api/web/matterport/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingMatterportViewer(buildingMatterportId: number) {
    const path = `${this.proxyClientService.baseURL}/api/web/matterport/viewer/${buildingMatterportId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
}