<div [hidden]="inProgress">
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="videos && videos.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let file of videos" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'drone', file.id]" [ngClass]="file.id == buildingVideoId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader"  title="{{file.fileName}}">{{file.fileName}}</a>
                </li>
              </ul>           
            </div>
              <div
                  *ngIf="videos && videos.length === 0"
                  class="potree_container">
                  <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                    'Videos' is available.</p>
                </div>
          </div>
        </div>
      </div>
    </div>
    <!-- subheader ends  -->
  
  
  
  <div class="viewer-inner" *ngIf="!inProgress">
    <div *ngIf="googleViewerUrl">
      <iframe [src]="googleViewerUrl" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
    </div>
  
    <div class="container" *ngIf="imageViewerUrl">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-lg-7 text-center img-responsive">
          <div class="mt-10">
            <div class="loader-spinner" *ngIf="loading" alt="loading" ></div>
            <img [hidden]="loading" (load)="onLoad()" [src]="imageViewerUrl" />
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="videoViewerUrl">
      <video controls disablepictureinpicture controlsList="nodownload"   class="fullwidth-video" name="Video Name"
        [src]="videoViewerUrl"></video>
    </div>
  </div>
  </div>