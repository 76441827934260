<div [hidden]="inProgress">
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
              <div class="subheader-instance" [hidden]="!(commonDataService.selectedViewerFiles && commonDataService.selectedViewerFiles.length > 0)">
                <app-sub-header></app-sub-header>                   
              </div>
              <div
                  *ngIf="!(commonDataService.selectedViewerFiles && commonDataService.selectedViewerFiles.length > 0)"
                  class="potree_container">
                  <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                    'Tracking' is available.</p>
                </div> 
          </div>
        </div>
        </div>
    </div>
    <!-- subheader ends  -->
    <div class="viewer-inner ml-0" *ngIf="!inProgress && (commonDataService.selectedViewerFiles && commonDataService.selectedViewerFiles.length > 0)">
      <div *ngIf="trackingSrc">
        <iframe id="trackingIframe" [src]="trackingSrc" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
      </div>
      <p id="showTrackingError" class="showTrackingError mt-20">No instance is available</p>
    </div>
  </div>