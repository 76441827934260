<div class="master-container"></div>
<div class="form-container">
    <img src="../../../assets/images/cc250.png" class="logo"/>
    <h1 class="register-heading">Login with Carbon<span>change</span></h1>
    <h2 class="register-sub-heading">Enter your Credentials.</h2>
    <form class="signup-form" [formGroup]="lForm" (ngSubmit)="login()">
        <input class="signup-field" type="text" name="username" placeholder="User Name" id="username" formControlName="username" [trim]="lForm.controls['username'].value"/>
        <small class="d-block mt-1 text-danger text-left"
            *ngIf="lForm.controls['username'].hasError('required') && lForm.controls['username'].touched">
            Username is required
        </small>
        <input class="signup-field" type="password" name="password" id="password" placeholder="Password" formControlName="password"/>
        <div class="eye-container">
            <div class="eye slash" id="eyePassword" (click)="togglePassword('eyePassword','password')">
                <div></div>
                <div></div>
            </div>
        </div>
        <small class="d-block mt-1 text-danger text-left"
            *ngIf="lForm.controls['password'].hasError('required') && lForm.controls['password'].touched">
            Password is required
        </small>
        <alert *ngIf="showMsg" type="danger">
            <strong>{{errMsg}}</strong>
        </alert>

        <alert *ngIf="showMsgBlock" type="danger">
            <strong class="error_label">Sorry, for security reasons you have to wait 1 hour before
                attempting to log in to your account again. Have you forgotten your password?
            </strong><a target="_self" class="link-forgot" href={{forgotpasswordurl}}>[click
                here]</a>
        </alert>

        <div class="school-name-container">
            <span class="school-name-heading">&nbsp;</span>
            <span class="school-name" id="schoolName"><a target="_blank" href={{forgotpasswordurl}}>Forgot Password?</a></span>
        </div>
        <button class="signup-register-button" type="submit" name="login" id="login">
            <span *ngIf="!loginInProgress">Login</span> 
            <span *ngIf="loginInProgress">Loading...</span>
        </button>
    </form>
   <!--  <h2 class="register-bottom-heading">DON'T HAVE AN ACCOUNT YET?</h2>
    <span class="school-bottom-name" id="schoolName"><a target="_blank" href={{registerLink}}>Register&nbsp;<i class="fa-solid fa-play"></i></a></span> -->
</div>