import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonDataService } from '../../service/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { ExternalLinkProxyService } from '../../proxies/externallink-proxy.service';
import { BuildingExternalUriListableDto } from '../../../shared/models/base/externalurl/building-external-url-listable-dto';

declare var MainJS : any;

@Component({
  selector: 'app-externallinks',
  templateUrl: './externallinks.component.html',
  styleUrls: ['./externallinks.component.scss']
})

export class ExternallinksComponent implements OnInit {

  public externalLinks: BuildingExternalUriListableDto[];
  public buildingExternalLinkId: number;
  public viewerUri: SafeResourceUrl;
  public rawUri: string;
  public inProgress: boolean = false;
  public shouldOpenInNewTab: boolean = false;

  constructor(
    private router:Router, 
    private domSanitizer: DomSanitizer,
    private activateRoute:ActivatedRoute, 
    public commonDataService:CommonDataService,
    private externalLinkProxyService: ExternalLinkProxyService,
    private toastrService: ToastrService
    ) { }

  public ngOnInit() {

    this.inProgress = true;

    this.activateRoute.params.subscribe(routeParams => {

        this.externalLinkProxyService.listBuildingExternalLink(Number.parseInt(this.commonDataService.selectedBuildingId!))
        .subscribe((response) => {
          response = response['body']['result'];
          this.externalLinks = response['items'];

          if(routeParams['file_id'])
          {
            this.loadExternallink(+routeParams['file_id']);
          }
          else
          {   
            if(response['items'].length > 0) {
              const first: BuildingExternalUriListableDto = response['items'][0];

              this.router.navigate([this.commonDataService.selectedBuildingId + "/externallink/" + first.id]);
            }
          }
          this.inProgress = false;
        },
        (error)=>{
          this.inProgress = false;
          this.toastrService.error(error.error.error.message);
          // redirecting to default page in case any exception like (permission denied or invalid access) occurred
          this.router.navigate(["/assets/" + this.commonDataService.selectedBuildingId]);
        })
    });
  }

  private loadExternallink(buildingExternalLinkId: number) {

    this.buildingExternalLinkId = buildingExternalLinkId;

    this.inProgress = true;
    this.externalLinkProxyService.getBuildingExternalLinkViewer(buildingExternalLinkId).subscribe((response) => { 
        response = response['body']['result'];
        this.shouldOpenInNewTab = response['isExternalTab'];
        this.viewerUri = this.domSanitizer.bypassSecurityTrustResourceUrl(response['externalUri']);
        this.rawUri = response['externalUri'];

        if(response['isExternalTab'])
        {
          window.open(response['externalUri'], "_blank");
        }

        this.inProgress = false;
    },(error)=>{
      this.inProgress = false;
    });
  }

  openDropdown(event : any){
    event.stopPropagation();
    MainJS.extraMenu();
  }

}
