import { Injectable } from '@angular/core';
import { CommonDataService } from './common-data.service';
import { BuildingStatusProxy } from '../proxies/building-status-proxy.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { BuildingStatusDto } from '../../shared/models/building-status-dto';

@Injectable({
  providedIn: 'root'
})

export class ViewerPermissionCheckerService {
  public status: BuildingStatusDto;
  public readonly updateCallback: BehaviorSubject<ViewerPermissionCheckerService>;
  public viewerPermissionCheckerUpdated : any; 
  public statusChange: Subject<BuildingStatusDto> = new Subject<BuildingStatusDto>();

  constructor(
    public _CommonDataService: CommonDataService,
    private buildingStatusProxy: BuildingStatusProxy
  ) { 
    this.status = new BuildingStatusDto();
    this.updateCallback = new BehaviorSubject<ViewerPermissionCheckerService>(this);
    this.viewerPermissionCheckerUpdated = this.updateCallback.asObservable();
    this.viewerPermissionCheckerUpdated.subscribe((value : ViewerPermissionCheckerService)=>{
      this.status = value.status;
    });
    this.statusChange.subscribe((value) => {
        this.status = value;
    });
  }

  public updateBuildingStatus(buildingId: number) {
    this.buildingStatusProxy.getBuildingStatus(buildingId).subscribe((result: any) => {
      //console.log(result);
      result = result['body'];
      const status: BuildingStatusDto = result['result'];
      this.statusChange.next(status);
      this.status = status;
      //console.log(this.status);
      //This callback is not functioning properly
      //After status has been updated it needs to propagate back to header component...
      this.updateCallback.next(this);
    });
  }

  public getBuildingStatus(): BuildingStatusDto{
    return this.status;
  }

  public hasViewerAssets(): boolean {
    const status: BuildingStatusDto = this.status;

    return status.hasBim
      || status.hasVideo 
      || status.hasPointCloud
      || status.hasMatterport 
      || status.hasGeneralViewer 
      || status.hasFloorPlan;
  }
}
