<div class="viewer-layout" style="background: #425060;">
    <div class="row">
        <div class="col-12">
        <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="floorPlans && floorPlans.length > 0">
            <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let file of floorPlans" class="mr-3">
                    <a *ngIf="file.fileExtension !== 'pdf'"
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'alarms', file.id]" 
                    [ngClass]="file.id == buildingFloorPlanId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{file.fileName}}">
                        {{file.fileName}}
                    </a>
                </li>
            </ul>           
            </div>
            <div
                *ngIf="floorPlans && floorPlans.length === 0"
                class="potree_container">
                <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                '2D Drawings' is available.</p>
            </div> 
        </div>
        </div>
    </div>
</div>
<style>
    #image-processing{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #title-span{
        margin-left: 5rem;
        color: #36b8b9;
        font-size: 25px;
    }
    .list-title{
        color: #36b8b9;
        font-size: 1.25rem;
    }
    .list-option{
        font-family: 'proxima-nova';
    }
    .selections{
        width: 300px;
    }
    .top-menu{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .disabled{
        background: gray !important;
        color: rgb(196, 196, 196);
        pointer-events: none;
    }
</style>
<!-- <span id="title-span">
    Currently Selected Sensor:&nbsp;{{currentlySelectedSensor}}
</span> -->
<div class="top-menu">
    <div class="selections">
        <span class="list-title">Select Floor Number:</span>
        <select (click) = "processFloorNumber()" 
                class="form-control"
                name="floorNumber"
                id="floorNumber"
                [(ngModel)]="currentFloorNumber">
                <option *ngFor="let floor of floorsAvailable" 
                    [value]="floor">
                    <span class="list-option">Floor Number {{floor}}</span>
                </option>
        </select>
    </div>
    <div class="selections">
        <span class="list-title">Select Sensor:</span>
        <select (click) = "processSensor()" 
                class="form-control"
                [ngClass]="currentFloorNumber == -1 || currentFloorNumber != 0 ? 'disabled' : '' "
                name="sensor"
                id="sensor"
                [(ngModel)]="currentlySelectedSensor">
                <option *ngFor="let sensor of availableSensors" 
                    [value]="sensor">
                    <span>{{sensor}}</span>
                </option>
        </select>
    </div>
</div>
<div id="image-processing" style="margin-top:10px; margin-bottom: 10px;"></div>
<img id="inputImage" [src]="imageFilePath" style="display: none;"/>
