<div class="sidebar collapsed">
    <div class="sidebarInnerContent">
      <div class="sidebar-inner">
        <div class="navigation border-bottom-light">
          <nav>
            <ul class="mb-0">
              <li  class=""
              [ngClass]="{'active-category': commonDataService.selectedCategory==='FloorPlan'}">
                <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','FloorPlan']" 
                  [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasFloorPlan ? '' : 'disabled'"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="2D Drawings">
                  <img src="../../../assets/images/btn_floorplan.png" alt="2D Drawings">
                </a>
              </li>

              <!-- <li  class=""
              [ngClass]="'disabled'">
                <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','RoomSpecifications']" class="room-spec-link"
                  [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasFloorPlan ? '' : 'disabled'"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="Room Specifications">
                  <i class="fa-solid fa-door-open"></i>
                </a>
              </li> -->
  
              <li class=""
              [ngClass]="{'active-category': commonDataService.selectedCategory==='BIM'}">
                <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','bimviewer']" 
                [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasBim ? '' : 'disabled'"  
                routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="3D BIM model">
                    <img src="../../../assets/images/btn_BIM.png" alt="3D BIM model">
                  </a>
                </li>
  
                <li class="" 
                [ngClass]="{'active-category': commonDataService.selectedCategory==='Navvis'}">
                    <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','building-view']" 
                    [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasGeneralViewer ? '' : 'disabled'"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="General Viewer">
                      <img src="../../../assets/images/btn_Navvis.png" alt="General Viewer">
                    </a>
                  </li>
  
                  <li class="">
                    <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','matterport']" 
                    [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasMatterport ? '' : 'disabled'"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="Matterport">
                      <img src="../../../assets/images/matterport.png" alt="Videos">
                    </a>
                </li>
  
                  <li class=""
                  [ngClass]="{'active-category': commonDataService.selectedCategory==='PointCloud'}">
                    <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','outdoor-view']" 
                    [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasPointCloud ? '' : 'disabled'"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="Point Cloud">
                      <img src="../../../assets/images/btn_pointcloud.png" alt="Point Cloud">
                    </a>
                  </li>
  
                  <li class="">
                    <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','drone']" 
                    [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasVideo ? '' : 'disabled'"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="Videos">
                      <img src="../../../assets/images/btn_drone.png" alt="Videos">
                    </a>
                  </li>  

                  <li  class=""
                  [ngClass]="'disabled'">
                    <a [routerLink]="['', commonDataService.selectedBuildingId, 'viewer','alarms']" class="room-spec-link"
                      [ngClass]="commonDataService.selectedBuildingId && _ViewerPermissionCheckerService.status.hasFloorPlan ? '' : 'disabled'"
                      routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" data-toggle="tooltip" title="Alarms">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </a>
                  </li>
            </ul>
          </nav>
        </div>
      
        <!--Get Version-->
        <p class="version active-category"> V - {{getVersion}}-commonDataService.selectedCategory-{{commonDataService.selectedCategory}}</p>
      </div>
  
    </div>