export const environment = {
  production: false,
  base_url: 'https://dev.api.bantu.no',
  appBaseUrl: "https://dev.api.bantu.no",
  mirisAppilcationDomain: 'https://dev.app.bantu.no/',
  mainApplicationDomain: 'https://dev.app.bantu.no',
  admin_url: 'https://dev.admin.bantu.no',
  web_portal: "http://dev.app.carbonchange.cloud",
  integrationUrl: "http://localhost",
  development: true,
  qa: false,
  staging: false,
  hmr: false,
  agora_app_id: '2d5bfec315f047b487f8bcdcf6c483c9',
  agora_channel: 'carbon-change',
  agora_api_key: 'ACo3e4df61b46f1415c98e146969640af39',
  agora_project_id: '20c7c57ee27a7b36407c',
  agora_app_certificate: 'e72cc49b5e894a77942c6dda7e84a9ab',
  agora_base_url: 'https://managedservices-prod.rteappbuilder.com',
  meet_url: 'https://dev.meet.bantu.no'
};
