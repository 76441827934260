import { Injectable } from '@angular/core';
import { ProxyClientService } from './proxy-client.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RoomProxyService{
    constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

    public listRooms(buildingId: number) {
        const path = `${this.proxyClientService.baseURL}/api/assignedrooms/${buildingId}`;

        return this.client.get(path, this.proxyClientService.getOptions_);
    }
}