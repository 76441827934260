import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APICallService } from './apicall.service';

//this directive serves as a declaration of dependency;
/// <reference types="@types/googlemaps" />

@Injectable({
  providedIn: 'root'
})

export class CommonDataService {

  selectedRoom : any = null;
  selectedSensor : any = null;

  loadForge: any = null;
  fileData : any = null;
  selectedFileInfo : any = null;
  selectedCategory: any;
  map: google.maps.Map;
  allBuildingInfo: any;
  selectedBuildingInfo : any | null;
  selectedProject : any | null;
  selectedBuildingId: string | null;
  selectedFileId = null;
  building_id: any;
  filesByCategory: any;
  viewOptionsFiles: any;
  viewOptionsCatgory: any;
  buildingInformation = [];
  showPoi = false;
  poiDataInfo = null;
  poiId = null;
  poiIdName = null;
  poiIdDescription = null;
  sensorList = [];
  sensorName = null;
  sensorType = null;
  sensorReading = null;
  poiCompleteInfo = [];
  categoryName: string | null;
  
  availableBuildings : any = 0;
  // toogleInstance = true;
  SelectedPOIId = null;
  sensorLatestInfo = {
    'sensorName': null,
    'sensorType': null,
    'sensorReading': null,
    'sensorId': null,
  }
  showSensorData = false;
  noshowSensorData = false;
  sensorListInfo = [];
  sensorInfo = {
    sensorName: null,
    sennsorId: null,
  }
  showMorePopup = false;
  showPOIData = null;
  BuildingInfoUrl = {
    'InfoUrl': null,
    'Url': null,
  }

  fileList = <any>[];

  showDocumentFile = false;
  showErrMsg = false;
  showSuccessMsg = false;
  showCategoryFile = false;
  filename = null;
  filemsg = true;
  file = false;
  showPoiList: any;
  d2viewlink: any;//for binding 2 view link
  d2viewtrigger = true;
  selectedViewerFiles : any[] = [];
  viewerPermission = {};
  defaultAssestViewer = null;
  totalSplicelength : any;
  downloadLink = null;

 
  constructor(
    private apicallService: APICallService, 
    private router: Router
    ) { }

  getBuildingId(buildingid : any) {
    this.building_id = buildingid;
    this.router.navigate(['home/' + this.building_id]);
    this.selectedBuildingId = this.building_id;
    this.selectedCategory = null;
    this.selectedFileInfo = null;
    this.getfilesByCategory('');
    this.selectedBuildingInfo = true;
    if (this.selectedBuildingInfo) {
      this.apicallService.getParticularBuilding(this.building_id).subscribe((response) => {
        this.selectedBuildingInfo = response['result'];
        this.selectedProject = response['result'];
        //MainJS.openDrawer();
        //set map properties for selected building
        this.selectedBuildingInfo!['lat'] = response['result']['lat'];
        this.selectedBuildingInfo!['long'] = response['result']['long'];
        if (response['result'].data) {
          this.BuildingInfoUrl.InfoUrl = JSON.parse(response['result'].data).UrlInfo;
          this.BuildingInfoUrl.Url = JSON.parse(response['result'].data).Url;
        }
      });
      // this.dashboardComponent.setOuterMap(this.building_id);
    }
  }

  getfilesForSelectedBuilding(buildingid : any) {
    //By default get all files related to the building
    this.apicallService.getfilesByBuilding(buildingid).subscribe((response) => {
      response = response['body'];
      this.filesByCategory = response['result'];
      if (this.filesByCategory.length > 0) {
        // this.getSelectedFileInfo(this.filesByCategory[0].id)
        this.viewOptionsFiles = true
        this.showCategoryFile = true;
        this.filemsg = false;
      } else {
        this.showCategoryFile = false;
        this.filemsg = true;
      }

    }, (error) => {
      throw error;
    });
    this.viewOptionsCatgory = true;
    this.viewOptionsFiles = true;
  }

  getfilesByCategory(fileCategory: any) {
    // this.file = null;
    // this.filemsg = null;
    this.showPoi = false;
    if (fileCategory != "") {
      this.selectedCategory = fileCategory;
      this.apicallService.getfilesByCategory(this.selectedBuildingId, fileCategory).subscribe((response) => {
        response = response['body'];
        if (response['result'].length > 0) {
          this.filemsg = false;
          this.file = true;
          this.filesByCategory = response['result'];
        } else {
          this.filemsg = true;
          this.file = false;
        }
      }, (error) => {
        throw error;
        // redirecting to default page in case any exception like (permission denied or invalid access) occurred
        this.router.navigate(["/"])
      });
    }
    else {
      this.selectedCategory = "";
      //this.filemsg = null;
      this.apicallService.getfilesByBuilding(this.selectedBuildingId).subscribe((response) => {
        response = response['body'];
        if (response['result'].length > 0) {
          this.filemsg = false;
          this.file = true;
          this.filesByCategory = response['result'];
          this.showCategoryFile = false;
        } else {
          this.showCategoryFile = true;
          this.filemsg = true;
          this.file = false;
        }
      }, (error) => {
        throw error;
      });
    }
  }

  dynamicSort(property : any) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a : any, b : any) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  showDocumentFiles() {
    this.filemsg = false;
    this.showDocumentFile = false;
    //this.file = null;
    this.selectedCategory = 'Document';
    this.showErrMsg = false;
    this.showSuccessMsg = false;
    this.showCategoryFile = false;
    this.showDocumentFile = false;
    if (this.selectedBuildingId != null) {
      this.fileList.length = 0;
      this.apicallService.getFileData(this.selectedBuildingId).subscribe((res) => {
        if (res) {
          res = res['body'];
          if (res['result'] != null) {
            this.showDocumentFile = true;
            this.filemsg = false;
            this.file = true;
            res['result'].forEach((element : any) => {
              this.fileList.push(element);
            });
          } else {
            this.showDocumentFile = false;
            this.filemsg = true;
            this.file = false;
          }
        }
      });
    }
  }

  getFileId(value : any) {
    let fileId = value.id;
    this.apicallService.getFile(fileId).subscribe((res) => {
      res = res['body'];
      if (res['result'].provider == "GoogleCloud") {
        this.filename = JSON.parse(res['result'].fileContent)['MediaLink'];
        if(this.filename){
          window.open(this.filename);
        }
      }
    });
  }



  openInViewer(value : any) {
    let category = JSON.parse(value['data']).Category;
    this.showPoiList = false;
    switch (category) {
      case "Potree": {
        this.d2viewtrigger = true;
        // this.router.navigate([
        //   this.selectedBuildingId + "/outdoor-view/" + value.id
        // ]);
        const newPath = this.selectedBuildingId + "/outdoor-view/" + value.id;
        window.location.href = newPath;
        // this.commonDataService.showPoi = false;
        break;
      }
      case "BIM": {
        this.d2viewtrigger = true;
        // let authorization_code = JSON.parse(value['data']).authorization_code;
        // this.router.navigate(
        //   [
        //     this.selectedBuildingId +
        //       "/bimviewer/" +
        //       value.id
        //   ]
        // );

        const newPath = this.selectedBuildingId + "/bimviewer/" + value.id;
        window.location.href = newPath;
        // this.commonDataService.showPoi = false;
        break;
      }
      case "Navvis": {
        // this.router.navigate([
        //   this.commonDataService.selectedBuildingId + "/building-view/" + this.commonDataService.selectedFileInfo.id
        // ]);
        const newPath = this.selectedBuildingId + "/building-view/" + value.id;
        window.location.href = newPath;
        // window.location.reload();
        // this.commonDataService.showPoi = true;
        break;
      }
      case "FloorPlan": {
        // let path  = this.selectedBuildingId + "/2dview/" + value.id;
        // window.location.href = path;
        this.d2viewtrigger = false;
        this.d2viewlink = JSON.parse(value['data']).ViewerUrl;
        this.router.navigate([this.selectedBuildingId + "/FloorPlan/" + value.id]);
        break;
      }
      case "InsideClimate": {
        // let path  = this.selectedBuildingId + "/2dview/" + value.id;
        // window.location.href = path;
        this.d2viewtrigger = false;
        this.d2viewlink = JSON.parse(value['data']).ViewerUrl;
        this.router.navigate([this.selectedBuildingId + "/insideclimate/" + value.id]);
        break;
      }
      case "EnergyOptimization": {
        // let path  = this.selectedBuildingId + "/2dview/" + value.id;
        // window.location.href = path;
        this.d2viewtrigger = false;
        this.d2viewlink = JSON.parse(value['data']).ViewerUrl;
        this.router.navigate([this.selectedBuildingId + "/energyoptimization/" + value.id]);
        break;
      }
      case "Drone": {
        // let path  = this.selectedBuildingId + "/2dview/" + value.id;
        // window.location.href = path;
        this.d2viewtrigger = false;
        this.d2viewlink = JSON.parse(value['data']).ViewerUrl;
        this.router.navigate([this.selectedBuildingId + "/drone/" + value.id]);
        break;
      }
    }
  }

  getFileFor2Dview(building_id : any, file_id : any) {
    this.router.navigate([building_id + "/FloorPlan/" + file_id]);
  }
}
