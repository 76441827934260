import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})

export class DocumentProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listBuildingDocumentVideo(buildingId: number) {
      const path = `${this.proxyClientService.baseURL}/api/web/document/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingDocumentViewer(buildingDocumentId: number) {
    const path = `${this.proxyClientService.baseURL}/api/web/document/viewer/${buildingDocumentId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public testDocumentURL(URL: any){
    return this.client.get(URL);
  }
}