<div>
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="documents && documents.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let file of documents" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId, 'documents', file.id]" [ngClass]="file.id == buildingDocumentId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{file.fileName}}">{{file.fileName}}</a>
                </li>
              </ul>           
            </div>
              <div
                *ngIf="documents && documents.length === 0"
                class="potree_container">
                <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                  'Documents' is available.</p>
              </div> 
          </div>
        </div>
      </div>
    </div>
    <!-- subheader ends  -->
    <div *ngIf="!inProgress">
        <div *ngIf="googleViewerUrl">
          <iframe [src]="googleViewerUrl" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
        </div>
      
        <div *ngIf="rawViewerUrl" class="document-viewer">
          <iframe [src]="rawViewerUrl" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
        </div>
  </div>