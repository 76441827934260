<div>
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="floorPlans && floorPlans.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let file of floorPlans" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'FloorPlan', file.id]" [ngClass]="file.id == buildingFloorPlanId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{file.fileName}}">{{file.fileName}}</a>
                </li>
              </ul>           
            </div>
              <div
                *ngIf="floorPlans && floorPlans.length === 0"
                class="potree_container">
                <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                  '2D Drawings' is available.</p>
              </div> 
          </div>
        </div>
      </div>
    </div>
    <!-- subheader ends  -->
    <div class="viewer-inner expended">
      <div *ngIf="googleViewerUrl">
        <iframe [src]="googleViewerUrl" class="pdfViewerHeight" width="100%" scrolling="yes"></iframe>
      </div>
      <!-- <div *ngIf="rawViewerUri">
        <iframe class="pdfViewerHeight" width="100%" scrolling="yes" [src]="rawViewerUri"></iframe>
      </div> -->
      <div class="container" *ngIf="rawViewerUri">
        <div class="row justify-content-center">
          <div class="text-center img-responsive">
            <div class="mt-10">
              <div class="loader-spinner" *ngIf="rawViewerUri" alt="loading" ></div>
              <img [hidden]="loading" (load)="onLoad()" [src]="rawViewerUri" />
            </div>
          </div>
        </div>
      </div>
      <iframe [src]="forgeUri" *ngIf="forgeUri" class="w-100 h-100">
      </iframe>

    </div>
  </div>