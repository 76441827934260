<div >
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
        <div class="row">
        <div class="col-12">
            <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="bims && bims.length > 0">
                <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let file of bims" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'bimviewer', file.id]" [ngClass]="file.id === selectedBuildingBimId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{file.fileName}}">{{file.fileName}}</a>
                </li>
                </ul>           
            </div>
                <div
                *ngIf="bims && bims.length === 0"
                class="potree_container">
                <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                    '3D BIM model' is available.</p>
                </div> 
            </div>
        </div>
        </div>
    </div>
    <!-- subheader ends  -->
    <div class="viewer-inner expended">
        <iframe [src]="forgeUri" *ngIf="forgeUri" class="w-100 h-100">

        </iframe>
    </div>
</div>