import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})

export class VideoCallComponent{
  public meetURL: SafeUrl;
  // micIcon: boolean = true;
  // muteIcon: boolean = false;
  // micBtn: boolean = true;
  // videoBtn: boolean = true;
  // videoIcon: boolean = true;
  // videoOffIcon: boolean = false;
  // callJoined: boolean = false;

  // rtc: any = {
  //   localAudioTrack: null,
  //   localVideoTrack: null,
  //   client: null
  // };

  // options: any = {
  //   appId: environment.agora_app_id,
  //   channel: environment.agora_channel,
  //   token: environment.token,
  //   uid: null
  // };

  // async joinCall(){
  //   await this.rtc.client.join(
  //     this.options.appId,
  //     this.options.channel,
  //     this.options.token
  //   );
  //   this.micBtn = false;
  //   this.videoBtn = false;

  //   this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  //   this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
  //     encoderConfig: '480p_1'
  //   });

  //   await this.rtc.client.publish([
  //     this.rtc.localAudioTrack,
  //     this.rtc.localVideoTrack
  //   ]);

  //   const videoPlayerContainer = document.querySelector('.incoming-video');
  //   this.rtc.localVideoTrack.play(videoPlayerContainer);
  //   this.callJoined = true;
  // }

  // async mute(){
  //   this.micIcon = !this.micIcon;
  //   this.muteIcon = !this.muteIcon;
  //   this.rtc.localAudioTrack.setEnabled(!this.muteIcon);
  // }

  // async videoOnOff(){
  //   this.videoIcon = !this.videoIcon;
  //   this.videoOffIcon = !this.videoOffIcon;
  //   this.rtc.localVideoTrack.setEnabled(!this.videoOffIcon);
  //   if(!this.videoOffIcon == false){
  //     this.rtc.localVideoTrack.stop();
  //   }else{
  //     const videoPlayerContainer = document.querySelector('.incoming-video');
  //     this.rtc.localVideoTrack.play(videoPlayerContainer);
  //   }
  // }

  // async leaveCall(){
  //   await this.rtc.client.leave();
  //   this.micBtn = true;
  //   this.videoBtn = true;
  //   this.rtc.localAudioTrack.close();
  //   this.rtc.localVideoTrack.close();
  //   this.rtc.client.remoteUsers.forEach((user: any) => {
  //     const playerContainers = document.querySelectorAll('.remote-video-container');
  //     playerContainers.forEach((container: Element) => {
  //       const containerAsHtmlElement = container as HTMLElement;
  //       if(containerAsHtmlElement.id === user.uid.toString()){
  //         containerAsHtmlElement.remove();
  //       }
  //     });
      
  //   });
  //   this.callJoined = false;
  // }
  
  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {   
    this.meetURL = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.meet_url);
    // this.rtc.client = AgoraRTC.createClient({
    //   mode: 'rtc',
    //   codec: 'vp8',
    //   websocketRetryConfig: {
    //     timeout: 10,
    //     timeoutFactor: 0,
    //     maxRetryCount: 1,
    //     maxRetryTimeout: 2000
    //   },
    // });
    // this.rtc.client.on('user-published', async (user: any, mediaType: any) => {
    //   await this.rtc.client.subscribe(user, mediaType);
    //   if(mediaType === 'video'){
    //     const remoteVideoTrack = user.videoTrack;
    //     const incomingVideoContainer = document.querySelector('.outgoing-video');
    //     if(incomingVideoContainer){
    //       remoteVideoTrack.play(incomingVideoContainer);
    //     }
    //   }
    //   if(mediaType === 'audio'){
    //     const remoteAudioTrack = user.audioTrack;
    //     remoteAudioTrack.play();
    //   }
    //   this.rtc.client.on('user-unpublished', (user: any) => {
    //     if(user){
    //       const remotePlayerContainer: any = document.getElementById(user.uid);
    //       if(remotePlayerContainer){
    //         remotePlayerContainer.remove();
    //       }
    //     }
    //   });
    // });
  }

  
}
