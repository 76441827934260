<div [hidden]="inProgress">
    <!-- subheader starts -->
    <div class="viewer-layout" style="background: #425060;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="subheader-instance" *ngIf="matterports && matterports.length > 0">
              <ul class="sub-drops list-style-none m-0 p-0">
                <li *ngFor="let externalUri of matterports" class="mr-3"><a
                    [routerLink]="['',commonDataService.selectedBuildingId,'viewer', 'matterport', externalUri.id]" [ngClass]="externalUri.id == buildingMatterportId ? 'viewer-active' : '' "
                    class="font-weight-500 slider-link text-ellipsis-subheader" title="{{externalUri.name}}">{{externalUri.name}}</a>
                </li>
              </ul>           
            </div>
              <div
                  *ngIf="matterports && matterports.length === 0"
                  class="potree_container">
                  <p class="text-white mb-0"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;No instance of
                    'Matterport' is available.</p>
                </div> 
          </div> 
        </div>
        </div>
    </div>
    <!-- subheader ends  -->
    <div style="position: relative;">
        <iframe class="matterport_frame" id="matterportiframe" title="test" frameBorder="0" allowFullScreen allow="vr"></iframe>
    </div>
  </div>