import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './layout/login/login.component';
import { AlwaysAuthGuard, LogInAuthenticiate } from './service/guard.service';
import { MainLayoutComponent } from './main-layout.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { ErrorPageComponent } from './layout/error-page/error-page.component';
import { SendingmailtoverifyComponent } from './layout/sendingmailtoverify/sendingmailtoverify.component';
import { MailnotifyComponent } from './layout/mailnotify/mailnotify.component';
import { PointcloudViewerComponent } from './layout/pointcloud-viewer/pointcloud-viewer.component';
import { ForgeViewerComponent } from './layout/forge-viewer/forge-viewer.component';
import { ViewerComponent } from './layout/viewer/viewer.component';
import { AssetViewerComponent } from './layout/asset-viewer/asset-viewer.component';
import { View2dComponent } from './layout/view2d/view2d.component';
import { Bim360ViewComponent } from './layout/bim360-view/bim360-view.component';
import { BuildingViewComponent } from './layout/building-view/building-view.component';
import { OutdoorViewComponent } from './layout/outdoor-view/outdoor-view.component';
import { DroneComponent } from './layout/drone/drone.component';
import { MatterportComponent } from './layout/matterport/matterport.component';
import { SensorIntegrationGraphComponent } from './layout/sensor-integration-graph/sensor-integration-graph.component';
import { DocumentsComponent } from './layout/documents/documents.component';
import { TrackingComponent } from './layout/tracking/tracking.component';
import { GenericIframeComponent } from './layout/generic-iframe/generic-iframe.component';
import { ExternallinksComponent } from './layout/externallinks/externallinks.component';
import { RoomSpecificationsComponent } from './layout/room-specifications/room-specifications.component';
import { AlarmsComponent } from './layout/alarms/alarms..component';
import { CalendarComponent } from './layout/calendar/calendar.component';
import { VideoCallComponent } from './layout/video-call/video-call.component';

const routes: Routes = [
  { path: "errorPage", component: ErrorPageComponent },
  { path: "login", component: LoginComponent },
  { path: "forgotPassword", component: SendingmailtoverifyComponent },
  { path: "resetlink", component: MailnotifyComponent },
  { path: "setpassword", component: MailnotifyComponent },
  {
    path: "pointcloud-viewer/:parameters",
    component: PointcloudViewerComponent,
  },
  { path: "forge-viewer/:parameters", component: ForgeViewerComponent },
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      { path: "home", component: DashboardComponent },
      { path: "home/:building_id", component: DashboardComponent },
      {
        path: ":building_id",
        component: ViewerComponent,
        children: [
          {
            path: "viewer",
            component: AssetViewerComponent,
            children: [
              { path: "FloorPlan", component: View2dComponent },
              { path: "FloorPlan/:file_id", component: View2dComponent },
              { path: "RoomSpecifications", component: RoomSpecificationsComponent},
              { path: "RoomSpecifications/:file_id", component: RoomSpecificationsComponent},
              { path: "alarms", component: AlarmsComponent},
              { path: "alarms/:file_id", component: AlarmsComponent},
              { path: "bimviewer", component: Bim360ViewComponent },
              { path: "bimviewer/:file_id", component: Bim360ViewComponent },
              { path: "building-view", component: BuildingViewComponent },
              {
                path: "building-view/:file_id",
                component: BuildingViewComponent,
              },
              { path: "outdoor-view", component: OutdoorViewComponent },
              {
                path: "outdoor-view/:file_id",
                component: OutdoorViewComponent,
              },
              { path: "drone", component: DroneComponent },
              { path: "drone/:file_id", component: DroneComponent },
              { path: "matterport", component: MatterportComponent },
              { path: "matterport/:file_id", component: MatterportComponent },
            ],
          },
          { path: "sensor-integration", component: SensorIntegrationGraphComponent },
          { path: "calendar", component: CalendarComponent },
          { path: "video-call", component: VideoCallComponent },
          { path: "documents", component: DocumentsComponent },
          { path: "documents/:file_id", component: DocumentsComponent },
          { path: "tracking", component: TrackingComponent },
          { path: "tracking/:file_id", component: TrackingComponent },
          { path: "externallink", component: ExternallinksComponent },
          { path: "externallink/:file_id", component: ExternallinksComponent },

          {
            path: "indoor-climate",
            component: GenericIframeComponent,
            data: { categoryName: "IoT:IndoorClimate" },
          },
          {
            path: "people",
            component: GenericIframeComponent,
            data: { categoryName: "IoT:People" },
          },
          {
            path: "energy",
            component: GenericIframeComponent,
            data: { categoryName: "IoT:Energy" },
          },
          {
            path: "building-access",
            component: GenericIframeComponent,
            data: { categoryName: "IoT:BuildingAccess" },
          },
          {
            path: "point-management",
            component: GenericIframeComponent,
            data: { categoryName: "IoT:PointManagement" },
          },
        ],
      },

      { path: "**", redirectTo: "home", pathMatch: "full" },
    ],
    canActivate: [AlwaysAuthGuard],
  },
  { path: "**", redirectTo: "home", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AlwaysAuthGuard, LogInAuthenticiate]
})
export class AppRoutingModule { }
