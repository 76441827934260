
export class CreateCalendarEventDto {
    title: string | undefined;
    description: string | undefined;
    createdBy: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    isRecurring: boolean | undefined;
    recurringInterval: string | undefined;
    meetLink: string | undefined;
    type: number | undefined;
    reminderTime: number | undefined;
    guestNames: string[] | undefined;
}