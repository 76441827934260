import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service'

@Injectable({
  providedIn: 'root'
})

export class SensorIntegrationsGraphProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listLocalDevices(buildingId: number) {
    const path = `${this.proxyClientService.baseURL}/api/sensorintegrationgraph/localdevices/${buildingId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public deviceReadings(sensorIntegrationDeviceId: number, returnCount: number, start: Date, end: Date){
    const path = `${this.proxyClientService.baseURL}/api/sensorintegrationgraph/devicereadings/${sensorIntegrationDeviceId}?start=${start.toISOString()}&end=${end.toISOString()}&returnCount=${returnCount}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
  
}