<div class="right-side-menu">
    <div class="result-header">
        <div class="rightSideBarOpener"></div>

    </div>
    <!-- <div class="left-details-tab">
        <ul class="tablist">
            <li class="tablist-item open-menu">
                <img src="../../../assets/images/info.png" alt="">
            </li>

            <li (click)="getCurrentUrl()" class="tablist-item" data-toggle="modal" data-target="#showModalPop">
                <img src="../../../assets/images/share.png" alt="">
            </li>
        </ul>
    </div> -->

    <!-- <div class="pos-relative pr-2 pt-2 text-right z-index-3 shadow-none">
        <a href="javascript:void(0)" class="shareIcon" (click)="getCurrentUrl()" data-toggle="modal"
            data-target="#showModalPop"><img src="../../../assets/images/share-colored.png" alt="" width="28"></a>

    </div> -->
    <style>
        .right-sidebar-content{
            background: #17212d;
            color: white;
        }
        .result-header{
            background: url('../../../assets/images/Dashboard.png') no-repeat !important;
            background-size: cover !important;
        }
    </style>
    <div class="right-sidebar-content">

            <!-- commonDataService.selectedFileInfo-{{commonDataService.selectedFileInfo|json}} -->

        <!-- right side information test field -starts-->
        <div
            *ngIf="!((commonDataService.selectedBuildingInfo != null) || (commonDataService.selectedFileInfo != null)  || (this.commonDataService.showPOIData != null))">
            <div class="file-details-row" *ngIf="!commonDataService.selectedBuildingInfo">

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none"></h5>
                    <div class="btn-group mt-10">
                        <p>No information is available. Please select any project.</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="((commonDataService.selectedBuildingInfo != null) || (commonDataService.selectedFileInfo != null))">
            <!--for selected file/building inforation header-->
            <h3 *ngIf="!commonDataService.selectedBuildingInfo" class="line-height-24 mt-0 font-19px word-break">
                <span>{{commonDataService.selectedFileInfo?.name}}</span>
                <!-- <span
                    *ngIf="commonDataService.selectedFileInfo?.extension">.{{commonDataService.selectedFileInfo?.extension}}</span> -->
            </h3>
            <button class="btn btn-primary font-weight-300 text-white p-0"
                *ngIf="!commonDataService.selectedBuildingInfo && commonDataService.downloadLink">
                <a class="left-bar-button d-block" target="_blank"
                    href="{{commonDataService.downloadLink}}">
                    <i class="fa fa-download" aria-hidden="true"></i> &nbsp;Download</a>
            </button>
            
            <h3 *ngIf="commonDataService.selectedBuildingInfo" class="line-height-24 mt-0 font-19px word-break">
                <span>{{commonDataService.selectedBuildingInfo?.name}}</span>
            </h3>

            <!--for selected file inforation body-->
            <div class="file-details-row mt-0" *ngIf="!commonDataService.selectedBuildingInfo">
                <div class="">
                    <h5 class="font-weight-600 mb-1 text-transform-none"></h5>
                    <button class="btn btn-primary font-weight-300 text-white p-0"
                        *ngIf="commonDataService.selectedFileInfo?.MediaLink">
                        <a class="left-bar-button d-block" target="_blank"
                            href="{{commonDataService.selectedFileInfo?.MediaLink}}">
                            <i class="fa fa-download" aria-hidden="true"></i> Download</a>
                    </button>
                    <!-- <div class="btn-group" *ngIf="commonDataService.d2viewtrigger">
                        <button [disabled]="disablepoi" class="btn btn-primary mr-5 font-weight-300 p-0"
                            *ngIf="disablepoi && showPoiOption && commonDataService.selectedFileId == commonDataService.selectedFileInfo?.id">
                            <a class="left-bar-button  d-block">
                                <i class="fa fa-thermometer-half" aria-hidden="true"></i> POIs</a>
                        </button>
                        <button (click)=getListOfAvailablePopup() class="btn btn-primary mr-5 font-weight-300 p-0"
                            *ngIf="!disablepoi && showPoiOption && commonDataService.selectedFileId == commonDataService.selectedFileInfo?.id">
                            <a class="left-bar-button  d-block" data-toggle="modal" data-target="#sensorModal">
                                <i class="fa fa-thermometer-half" aria-hidden="true"></i> POIs</a>
                        </button>
                        <button class="btn btn-primary font-weight-300 text-white p-0"
                            *ngIf="commonDataService.selectedFileInfo?.data?.Url">
                            <a class="left-bar-button d-block" target="_blank"
                                href="{{commonDataService.selectedFileInfo.data.Url}}">
                                <i class="fa fa-download" aria-hidden="true"></i> Download</a>
                        </button>
                    </div> -->
                </div>

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">File Name</h5>
                    <p class="word-break">{{commonDataService.selectedFileInfo?.name}}</p>
                </div>

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">File Type</h5>
                    <p *ngIf="commonDataService.selectedFileInfo?.category!='ExternalLink'">{{commonDataService.selectedFileInfo?.contentType}}</p>
                    <p *ngIf="commonDataService.selectedFileInfo?.category=='ExternalLink'">External link</p>
                </div>

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">File Size</h5>
                    <!-- <p>/data/gb-repository-01/BIM Work/Lietorvet/Lietorvet/results/Point_cloud/PointGeo-00022-Lietorvet-Drone-Fotogrammertri.las</p> -->
                    <p *ngIf="!commonDataService.selectedFileInfo.category || commonDataService.selectedFileInfo.category != 'Navvis'" class="word-break">{{commonDataService.selectedFileInfo?.formattedFileSize}}</p>
                    <p *ngIf="commonDataService.selectedFileInfo.category && commonDataService.selectedFileInfo.category === 'Navvis'" class="word-break">N/A</p>
                </div>

                <!-- <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">File path</h5>
                    <p class="word-break" *ngIf="commonDataService.selectedFileInfo?.data?.Path">
                        {{commonDataService.selectedFileInfo?.data?.Path}}</p>
                    <p class="word-break" *ngIf="!commonDataService.selectedFileInfo?.data?.Path">None</p>
                </div> -->

                <!-- <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">File Date</h5>
                    <p>{{commonDataService.selectedFileInfo?.creationTime |date}}</p>
                </div> -->
            </div>

            <!--for selected building inforation body -->
            <div class="file-details-row" *ngIf="commonDataService.selectedBuildingInfo">
                <button (click)="viewInGoogleMap()" class="btn btn-primary mr-5 font-weight-300 mt-3" style="border-radius: 2vmin !important;"> <i
                        class="fa fa-eye"></i> View in Google Map</button>
                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Project Name</h5>
                    <p class="word-break">{{commonDataService.selectedBuildingInfo?.name}}</p>
                </div>

                <!-- <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Project Info&nbsp;:</h5>
                    <a *ngIf="commonDataService.BuildingInfoUrl?.InfoUrl"
                        href="{{commonDataService.BuildingInfoUrl?.InfoUrl}}" target="_blank"
                        class="word-break left-side-url">http://www.norgeskart.no</a>
                    <span *ngIf="!commonDataService.BuildingInfoUrl?.InfoUrl">N/A</span><br>
                </div> -->
                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Additional building tool 1&nbsp;:</h5>
                    <a *ngIf="commonDataService.selectedBuildingInfo?.seeiendomUrl"
                        href="{{commonDataService.selectedBuildingInfo?.seeiendomUrl}}" target="_blank"
                        class="word-break left-side-url">{{commonDataService.selectedBuildingInfo?.seeiendomUrl}}</a>
                    <span *ngIf="!commonDataService.selectedBuildingInfo?.seeiendomUrl">N/A</span><br>
                </div>
                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Additional building tool 2&nbsp;:</h5>
                    <a *ngIf="commonDataService.selectedBuildingInfo?.norgeskartUrl"
                        href="{{commonDataService.selectedBuildingInfo?.norgeskartUrl}}" target="_blank"
                        class="word-break left-side-url">{{commonDataService.selectedBuildingInfo?.norgeskartUrl}}</a>
                    <span *ngIf="!commonDataService.selectedBuildingInfo?.norgeskartUrl">N/A</span><br>
                </div> 

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Project Address</h5>
                    <p class="word-break">{{commonDataService.selectedBuildingInfo?.addressLine1}}</p>
                </div>

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Latitude&nbsp;:</h5>
                    <p class="word-break">{{commonDataService.selectedBuildingInfo?.lat}}</p>
                </div>

                <div class="content-row">
                    <h5 class="font-weight-600 mb-1 text-transform-none fx-15">Longitude&nbsp;:</h5>
                    <p class="word-break">{{commonDataService.selectedBuildingInfo?.long}}</p>
                </div>
            </div>
        </div>

        <!--For poi information showing-->
        <div
            *ngIf="((commonDataService.selectedFileInfo == null) && (this.commonDataService.poiCompleteInfo.length > 0))">
            <div class="content-row">
                <h5 class="font-weight-600 mb-1 text-transform-none fx-15">POI Name</h5>
                <p class="word-break">{{commonDataService.poiIdName}}</p>
            </div>
            <div class="content-row">
                <ul class="border border' list-style-none m-0 p-0 p-3 mt-3"
                    *ngFor="let val of commonDataService.poiCompleteInfo">
                    <li class="mb-1"><span class="font-weight-600 mr-3">Sensor Name :</span>
                        <span>{{val['sensorName']}}</span></li>
                    <li class="mb-1"><span class="font-weight-600 mr-3">Sensor Type :</span>
                        <span>{{val['sensorType']}}</span></li>
                    <li class="mb-1"><span class="font-weight-600 mr-3">Sensor Latest Reading :</span>
                        <span>{{val['sensorReading'] ? val['sensorReading'] : 'n/a'}}</span></li>
                </ul>
            </div>
        </div>


    </div>
</div>

<div class="modal fade" id="showModalPop" data-backdrop="false" role="dialog">
    <div class="app-modal-dialog modal-dialog">
        <!-- Modal content-->
        <div class="app-modal-content modal-content">
            <div class="app-modal-header modal-header border-bottom">
                <h4 class="modal-title pull-left m-0 p-0 font-weight-500">Del survey</h4>
                <button type="button" class="close pr-0   " data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <p>Copy the URL below to share the survey</p>
                <input type="text" class="form-control app-form-control" [(ngModel)]='url'>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="sensorModal" data-backdrop="false" role="dialog">
    <div class="app-modal-dialog modal-dialog">
        <!-- Modal content-->
        <div class="app-modal-content modal-content">
            <div class="app-modal-header modal-header">
                <h4 class="modal-title pull-left m-0 p-0 font-weight-500">Point Of Interest</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div *ngIf="poiList.length > 0 && showPoiName" class="listing_wrapper">
                    <ul class="m-0 p-0  list-unstyled bg-styled-list inline-list">
                        <li (click)="movetopoi(item)" data-dismiss="modal" class="cursor-pointer mb-1"
                            *ngFor="let item of poiList; let i = index">
                            <a *ngIf="item['title'] !==''">{{item['title']}}
                            </a>
                            <a *ngIf="item['title'] ===''">NA
                            </a>
                            <!-- <a> {{item.id}} {{item.titles.en}}                        </a> -->
                        </li>
                    </ul>
                </div>
                <alert class="alert-danger d-block" *ngIf="!poiList.length" type="warning">
                    <span>POI is not available</span>
                </alert>
            </div>
        </div>
    </div>