import { Component, ElementRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BuildingService } from '../../../service/building-service';
import { CreateCalendarEventDto } from 'src/app/model/create-calendar-event-dto';
import { AgoraAPICallService } from 'src/app/service/agora.apicall.service';
import * as moment from 'moment';
import { LoginService } from 'src/app/service/login.service';
import { CalendarEventService } from 'src/app/service/calendarevent-service';
import { CalendarEventGuest } from 'src/app/model/calendar-event-guest';
import { CreateCalendarEventGuestDto } from 'src/app/model/create-calendar-event-guest-dto';

export interface DialogData {
  buildingId: number;
}

@Component({
  selector: 'app-dialog-create-event-dialog',
  templateUrl: './dialog-create-event-dialog.component.html',
  styleUrls: ['./dialog-create-event-dialog.component.scss']
})
export class DialogCreateEventDialogComponent implements OnInit {


  separatorKeysCodes: number[] = [ENTER, COMMA];
  guestCtrl = new FormControl('');
  filteredGuests: Observable<string[]>;
  guests: string[] = [];
  allGuests: string[] = [];
  createEventDto: CreateCalendarEventDto = new CreateCalendarEventDto();
  isLogin: boolean = false;
  invitedGuest: CalendarEventGuest = new CalendarEventGuest();
  invitedGuests: CreateCalendarEventGuestDto = new CreateCalendarEventGuestDto();

  end_time: string | undefined;
  start_time: string | undefined;
  end_date: string | undefined;
  start_date: string | undefined;

  @ViewChild('guestInput') guestInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);
  
  constructor(
    public dialogRef: MatDialogRef<DialogCreateEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private buildingService: BuildingService,
    private agoraService: AgoraAPICallService, 
    private loginService: LoginService,
    private calendarEventService: CalendarEventService
  ) {

    this.filteredGuests = this.guestCtrl.valueChanges.pipe(
      startWith(null),
      map((guest: string | null) => (guest ? this._filter(guest) : this.allGuests.slice())),
    );
  /*  const rtmToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoZW50aWNhdGlvbl90eXBlIjoxLCJhcHBfaWQiOiIyYmVlY2Q4YTgzNTM0OTNhODU1YjdiNGIxNjZjYTUzNSIsInVzZXJfaWQiOiJzdHJpbmciLCJwcm9qZWN0X2lkIjoiMjBjN2M1N2VlMjdhN2IzNjQwN2MiLCJleHAiOjE3MTYyMzg1ODd9.Y2AmFDbX64EUDrc7jrsHajXMS22THRd2uexAWK8zqho";

    

    
    const JoinButtonClickHandler = async () => {
      const join = await AgoraAppBuilder.joinRoom(
         "d5ecab5c-14f0-406a-ba8d-28f983fd27fc",
        "User A",
       );
      console.info(join);
      this.isLogin = true;
     }*/
  //   JoinButtonClickHandler(); 
  }

  ngOnInit(): void {

    this.loggedInUserInfo();
    this.buildingService.getBuildingUsers(this.data.buildingId).subscribe(response => {
      response['body'].result.items.forEach((item:any, index:any) => {
         this.allGuests.push(item.emailAddress);
      });
      
    });
  /*  const JoinButtonClickHandler = async () => {
      const join = await AgoraAppBuilder.joinRoom(
         "d5ecab5c-14f0-406a-ba8d-28f983fd27fc",
        "User A",
       );
      // console.info(join.rtmToken);
     }
     */
   
  /*  this.agoraService.JoinMeetingChannel("d5ecab5c-14f0-406a-ba8d-28f983fd27fc", rtmToken)
    .subscribe(result =>{
      console.info(result);
       
    });*/
  }

  createEvent(): void {
    let token: string | undefined;
    this.setStartDateTime();
    this.setEndDateTime();
    this.createEventDto.isRecurring = false;
    this.createEventDto.guestNames = this.allGuests;
    this.createEventDto.recurringInterval = "NONE";
    this.createEventDto.type = 0;
    this.agoraService.CreateJWTToken()
    .subscribe(response => {
        if (response['status'] == 200) {
          token = response['body'].token;
          this.agoraService.CreateMeetingChannel(this.createEventDto.title, token)
          .subscribe(result => {
            if (result['status'] == 201) {
              this.createEventDto.meetLink = result['body'].viewer_pass_phrase;
              this.calendarEventService.addCalendarEvent(this.createEventDto).subscribe( {
                next: (response) => {
                  response = response['body'];
                  this.guests.forEach( (guest) => {
                    this.invitedGuest.calendarEventId = parseInt(response['result']['id']);
                    this.invitedGuest.email = guest;
                    this.invitedGuest.name = guest;
                    this.invitedGuests.guests.push(this.invitedGuest);
                  }); 
                  this.calendarEventService.addCalendarEventGuests(this.invitedGuests).subscribe( {
                    next: (response) => {
                      this.close();
                    },
                    error: (error) => {
                    },
                    complete: () => { }
                  });
                },
                error: (error) => {
                },
                complete: () => { }
              });
            }   
          });   
        }     
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our guest
    if (value) {
      this.guests.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.guestCtrl.setValue(null);
  }

  remove(guest: string): void {
    const index = this.guests.indexOf(guest);

    if (index >= 0) {
      this.guests.splice(index, 1);

      this.announcer.announce(`Removed ${guest}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.guests.push(event.option.viewValue);
    this.guestInput.nativeElement.value = '';
    this.guestCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allGuests.filter(guest => guest.toLowerCase().includes(filterValue));
  }

  private setStartDateTime(): void {
    var startDate = moment(this.start_date).format("YYYY-MM-DD");
    this.createEventDto.startDate = moment(startDate + "T" + this.start_time).format("YYYY-MM-DD HH:mm:ss");
  }

  private setEndDateTime(): void {
    var endDate = moment(this.end_date).format("YYYY-MM-DD");
    this.createEventDto.endDate = moment(endDate + "T" + this.end_time).format("YYYY-MM-DD HH:mm:ss");
  }

  loggedInUserInfo() {
    this.loginService.loggedInUserInfo().subscribe( {
      next: (response) => {
        response = response['body'];
        this.createEventDto.createdBy = response['result']['id'];        
      },
      error: (error) => {
      },
      complete: () => { }
    });
}
 /* private generateRtcToken() {
      // Rtc Examples
    const appId = '2d5bfec315f047b487f8bcdcf6c483c9';
    const appCertificate = 'e72cc49b5e894a77942c6dda7e84a9ab';
    const channelName = 'string';
    const uid = 290343226;
    const userAccount = "";
    const role = RtcRole.PUBLISHER;
  
    const expirationTimeInSeconds = 3600
  
    const currentTimestamp = Math.floor(Date.now() / 1000)
  
    const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds
  
    // IMPORTANT! Build token with either the uid or with the user account. Comment out the option you do not want to use below.
  
    // Build token with uid
    const tokenA = RtcTokenBuilder.buildTokenWithUid(
        appId,
        appCertificate,
        channelName,
        uid,
        role,
        expirationTimeInSeconds,
        privilegeExpiredTs
      );

    console.info("Token With Integer Number Uid: " + tokenA);
  
    return tokenA;
  }*/
  
}
