import { Injectable } from '@angular/core';
import { ProxyClientService } from './proxy-client.service'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BimProxyService {

  constructor(private proxyClientService: ProxyClientService, private client: HttpClient) { }

  public listBuildingBim(buildingId: number){
      const path = `${this.proxyClientService.baseURL}/api/web/bim/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingBimViewer(buildingBimId: number){
    const path = `${this.proxyClientService.baseURL}/api/web/bim/viewer/${buildingBimId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }

}