import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyClientService } from './proxy-client.service';

@Injectable({
  providedIn: 'root'
})

export class VideoProxyService {

  constructor(private client: HttpClient, private proxyClientService: ProxyClientService) { }

  public listBuildingVideo(buildingId: number){
      const path = `${this.proxyClientService.baseURL}/api/web/video/list/${buildingId}`;

      return this.client.get(path, this.proxyClientService.getOptions_);
  }

  public getBuildingVideoViewer(buildingVideoId: number) {
    const path = `${this.proxyClientService.baseURL}/api/web/video/viewer/${buildingVideoId}`;

    return this.client.get(path, this.proxyClientService.getOptions_);
  }
}